import { Dialog, TooltipProvider, Tooltip, TooltipTrigger, TooltipContent, DialogContent, DialogHeader } from "@qura/ui"
import { DialogTrigger } from "@qura/ui";
import { ChevronDownIcon, DatabaseIcon, FileIcon, XIcon } from "lucide-react";
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { FC } from "react";
import { useTranslations } from "@qura/translations";
import { DatabaseTreeNode, useDatabaseInfo } from "../services/firebase/actions/useDatabaseInfo";

interface IDatabaseInfoModal {
  onClose: () => void;
}

const DatabaseInfoModal: FC<IDatabaseInfoModal> = ({ onClose }) => {
  const { isFetching, data: dbInfo } = useDatabaseInfo();
  const t = useTranslations();

  return (
    <>
          <DialogHeader className=" text-lg font-medium">{t.databaseInformation.databaseInformation}</DialogHeader>
          <div className="flex flex-col flex-1 overflow-y-auto">
            {isFetching ? (
              <div className="flex flex-1 justify-center items-center">
                <p className="tracking-wide animate-pulse">{t.common.loading}</p>
              </div>
            ) : dbInfo ? (
              <NodeList nodes={dbInfo} />
            ) : (
              <p className="text-qura-red text-m">{t.errors.unknown}</p>
            )}
          </div>
    </>
  );
};

export default DatabaseInfoModal;

const NodeList: React.FC<{ nodes: DatabaseTreeNode[]; label?: string }> = ({
  nodes,
  label,
}) => {
  const sortedNodes = useMemo(() => nodes.sort((a, b) => a.order - b.order), [nodes]);

  return (
    <div className="flex flex-col items-stretch self-stretch gap-4 mt-10">
      {label && <h4 className="text-md font-medium">{label}</h4>}
      {sortedNodes.map((node, index: number) => (
        <NodeItem key={index} node={node} />
      ))}
    </div>
  );
};

const NodeItem: React.FC<{ node: DatabaseTreeNode }> = ({ node }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [subContainerHeight, setSubContainerHeight] = useState<number>(0);
  const subContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setSubContainerHeight(subContainerRef.current?.clientHeight ?? 0);
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (subContainerRef.current) {
      resizeObserver.observe(subContainerRef.current);
    }

    return () => {
      if (subContainerRef.current) {
        resizeObserver.unobserve(subContainerRef.current);
      }
    };
  }, [subContainerRef]);

  const sortedNodes = useMemo(
    () => node.children?.sort((a, b) => a.order - b.order) ?? null,
    [node.children],
  );

  const handleNodeItemStatus: MouseEventHandler<HTMLDivElement> = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`flex flex-col`}>
      <div
        onClick={sortedNodes ? handleNodeItemStatus : undefined}
        className={`${isOpen ? 'bg-qura-neutral-ghost' : ''} flex items-center justify-between hover:bg-backgroundColor-gray cursor-pointer py-1 px-2 mr-2 rounded`}>
        <div className="flex gap-2 items-center">
          <p className="text-qura-neutral-jet text-sm leading-[16px]">{Object.values(node.label)[0]}</p>
        </div>
        <div className="flex gap-1 items-center justify-between">
          <FileIcon className="" />
          <p className="text-qura-neutral-jet text-xs w-20">
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              useGrouping: true,
              minimumFractionDigits: 0,
            })
              .format(node.count)
              .replace(/,/g, ' ')}
          </p>
          <ChevronDownIcon
            className="transition-transform mx-2"
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              opacity: sortedNodes ? '1' : '0',
            }}
          />
        </div>
      </div>
      <div
        className="relative overflow-hidden transition-height"
        style={{ height: isOpen ? subContainerHeight : 0 }}>
        <div
          className={'absolute left-0 right-0 flex flex-col ml-4 gap-2 pt-3'}
          ref={subContainerRef}>
          {sortedNodes?.map((item) => <NodeItem key={item.id} node={item} />)}
        </div>
      </div>
    </div>
  );
};


const DatabaseInfoDialogContent: FC<{ setOpen: (open: boolean) => void }> = ({ setOpen }) => {
  return (
    <DialogContent>
      <DatabaseInfoModal onClose={() => setOpen(false)} />
    </DialogContent>
  )
};

const DatabaseInfoDialogTrigger = () => {
  return (
    <div className="group" >
      <DialogTrigger >
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center justify-center w-8 h-8 group-hover:bg-q-white-10 rounded-md bg-q-black-50">
                <DatabaseIcon className='w-5 h-5 text-white group-hover:text-black' />
              </div>
            </TooltipTrigger>
            <TooltipContent side="right">
              Database Info
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
    </div>
  )
}

export const DatabaseInfoDialog = () => {
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DatabaseInfoDialogTrigger />
      <DatabaseInfoDialogContent setOpen={setOpen} />
    </Dialog>
  )
}