import { getBrowserId, getSessionId } from './getSessionId';

export function getLogMetadata() {
  try {
    const localStorageData: Record<string, string | null> = {};
    const cookieData = document.cookie;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key) continue;
      localStorageData[key] = localStorage.getItem(key);
    }

    const metadata = {
      browserId: getBrowserId(),
      sessionId: getSessionId(),
      location: window.location.href,
    //   searchStore: useSearchStore.getState(),
    //   userDataStore: useUserDataStore.getState(),
      localStorage: localStorageData,
      cookies: cookieData,
    };

    return JSON.parse(JSON.stringify(metadata));
  } catch (error) {
    // alertError(error, { function: 'getLogMetadata' });
    return { details: 'error getting log metadata' };
  }
}
