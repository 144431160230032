import { Dialog, TooltipProvider, Tooltip, TooltipTrigger, TooltipContent, DialogContent, DialogTitle, selectTranslationFromArray } from "@qura/ui"
import { DialogTrigger } from "@qura/ui";
import { ClockIcon } from "lucide-react";
import { useMemo, useRef, useState } from "react";
import { FC, useEffect } from "react";
import { evaluateCountInTemplate, useTranslations } from "@qura/translations";
import { useCrossBundleSearchHistoryInfiniteScroll } from "../services/firebase/actions/useCrossBundleSearchHistory";
import { useAuthUser } from "@qura/auth";
import { Bundle, CrossBundleSearchDocument } from "@qura/shared-types";
import { useBundle } from "../services/firebase/actions/useBundle";
import { useNavigate } from "react-router-dom";
import { useQueryState } from "nuqs";
const BOTTOM_SCROLL_THRESHOLD = 1000;

const HistoryDialogContent: FC<{setOpen: (open: boolean) => void}> = ({setOpen}) => {
    const user = useAuthUser()

    const t = useTranslations()
    const navigate = useNavigate()
    const containerRef = useRef<HTMLDivElement>(null);
    const {
        data: historyData,
        error,
        isFetching,
        fetchNextPage,
        isFetchingNextPage,
    } = useCrossBundleSearchHistoryInfiniteScroll(user?.uid ?? null);

    const [_query, setQuery] = useQueryState('query')

    const isFetchingNextPageRef = useRef(false);

    const historySections = useMemo(() => {
        const flattened = historyData?.pages.map((item) => item.searches || []).flat() ?? [];
        const sections: { at: Date; searches: CrossBundleSearchDocument[] }[] = [];

        let lastDate = null as Date | null;

        flattened.forEach((search) => {
            if (
                lastDate === null ||
                search.created_at.toISOString().split('T')[0] !== lastDate.toISOString().split('T')[0]
            ) {
                sections.push({ at: search.created_at, searches: [] });
            }
            sections[sections.length - 1].searches.push(search);
            lastDate = search.created_at;
        });
        return sections;
    }, [historyData]);

    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => document.body.classList.remove('overflow-hidden');
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const container = containerRef.current;
            if (
                isFetchingNextPageRef.current === false &&
                container &&
                Math.ceil(container.scrollTop) + container.clientHeight >=
                container.scrollHeight - BOTTOM_SCROLL_THRESHOLD
            ) {
                isFetchingNextPageRef.current = true;
                fetchNextPage().finally(() => {
                    isFetchingNextPageRef.current = false;
                });
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const selectHistoryOption = (option: CrossBundleSearchDocument, bundle?: Bundle) => {
        console.log(option)
        // posthog.capture(POSTHOG_EVENT.SEARCH_HISTORY_SELECT, {
        //     searchParams: option.search.content,
        //     searchId: option.search.searchId,
        //     query: option.search.content.query,
        //     documentId: doc?.documentId,
        // });

        if(bundle) {
            navigate(`/bundle/${bundle.bundle_id}?sid=${option.cross_bundle_search_id}`)
        } else {
            navigate(`/search/${option.cross_bundle_search_id}`)
        }
        setQuery(option.query)
        setOpen(false)
    };

    return (
        <DialogContent className="w-[700px] min-w-[700px]" >
            <div className="flex flex-col h-[500px]">
                <DialogTitle>
                    {t.searchHistory.searchHistory}
                </DialogTitle>
                <div
                    ref={containerRef}
                    className="flex flex-col relative flex-1 overflow-y-auto gap-10 scroll-m-5 overflow-auto">
                    {isFetching && !isFetchingNextPage ? (
                        <div className="flex flex-1 justify-center items-center">
                            <p className="tracking-wide animate-pulse">{t.common.loading}</p>
                        </div>
                    ) : error === null ? (
                        <>
                            <div className="h-4" />
                            {historySections.map((section) => (
                                <div key={section.at.toISOString()} className={'flex flex-col gap-1'}>
                                    <HistoryDate date={section.at.getTime()} />
                                    <div className={'flex flex-col gap-4 px-9'}>
                                        {section.searches.map((search) => (
                                            <HistoryCard
                                                key={search.cross_bundle_search_id}
                                                queryOption={search}
                                                select={selectHistoryOption}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}
                            <div className="h-4" />
                        </>
                    ) : (
                        <p className="text-qura-red text-m">{t.errors.unknown}</p>
                    )}
                    {isFetchingNextPage && (
                        <div className="flex flex-1 justify-center items-center">
                            <p className="tracking-wide animate-pulse">{t.common.loading}</p>
                        </div>
                    )}
                </div>
            </div>
        </DialogContent>
    );
};

type SearchBarDropdownOptionProps = {
    title: string;
    tag?: string;
    selected?: boolean;
    onClick?: () => void;
    titleIcon?: React.ReactNode;
    tagIcon?: React.ReactNode;
};

const SearchBarDropdownOption = (props: SearchBarDropdownOptionProps) => {
    const { title, tag, titleIcon, tagIcon, selected, onClick } = props;


    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (onClick) onClick();
    };

    return (
        <div
            className={`pointer-events-auto group flex items-center h-7 my-1.5 px-2 border ${selected ? 'bg-qura-neutral-ghost border-gray-200' : 'border-transparent '} hover:bg-qura-neutral-ghost hover:border-gray-200 rounded-md cursor-pointer`}
            onClick={onClick && handleClick}>
            {titleIcon}
            {tag && (
                <div className="flex min-w-20">
                    <div
                        className={`flex items-center text-xs bg-qura-neutral-ghost rounded px-2 border ${selected ? 'border-transparent' : 'border-gray-200 group-hover:border-transparent'}`}>
                        {tagIcon}
                        <p className="pl-1.5 py-1">{tag}</p>
                    </div>
                    <div className="w-2" />
                </div>
            )}
            <p className="pl-2.5 flex-1 line-clamp-1 text-sm">{title}</p>
        </div>
    );
};

const VisitedBundle = (props: { bundlePk: string, select: (doc: Bundle) => void }) => {
    const { bundlePk, select } = props;

    const {data: bundle} = useBundle(bundlePk);
    
    return (
        <SearchBarDropdownOption
            selected={false}
            title={bundle ? selectTranslationFromArray(bundle.titles) ?? "Loading..." : "Loading..."}
            tag={bundle ? selectTranslationFromArray(bundle.names) ?? "..." : "..."}
            tagIcon="Document"
            onClick={() => bundle && select(bundle)}
        />
    )
}

type HistoryCardProps = {
    queryOption: CrossBundleSearchDocument;
    select: (option: CrossBundleSearchDocument, doc?: Bundle) => void;
};
const HistoryCard: FC<HistoryCardProps> = ({ queryOption, select }) => {
    const t = useTranslations();
    return (
        <div className="rounded-[8px] shadow-qura border-1 border-gray-100 transition-all relative overflow-visible py-0.5 px-1">
            <SearchBarDropdownOption
                selected={false}
                title={queryOption.query}
                titleIcon={<ClockIcon className="w-4 h-4 text-qura-neutral-balanced" />}
                onClick={() => select(queryOption)}
            />
            {queryOption.visited_bundles.length > 0 && (
                <div className="flex flex-col mb-1">
                    <p className="text-xs px-3 mt-4 mb-2 text-qura-neutral-balanced">
                        {evaluateCountInTemplate(t.searchHistory.documentsVisited, queryOption.visited_bundles.length)}
                        
                    </p>
                    {queryOption.visited_bundles.map((bundlePk) => (
                        <VisitedBundle bundlePk={bundlePk} select={b => select(queryOption, b)}  key={bundlePk}/>
                    ))}
                </div>
            )}
        </div>
    );
};


type HistoryDateProps = {
    date: number;
};
const HistoryDate: FC<HistoryDateProps> = ({ date }) => {
    const t = useTranslations()

    function daysBetweenDates(date1: Date, date2: Date): number {
        const midnightDate1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const midnightDate2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
        const diffTime = Math.abs(midnightDate2.getTime() - midnightDate1.getTime());
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24) + 0.5);

        return diffDays;
    }

    const dateString = useMemo(() => {
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();
        const weekday = dateObj.getDay();
        const month = dateObj.getMonth();
        const dayDiff = daysBetweenDates(new Date(), dateObj);

        if (dayDiff === 0) {
            return `${t.common.today} - ${t.common.weekday[weekday]} ${day} ${t.common.month[month]} ${year}`;
        } else if (dayDiff === 1) {
            return `${t.common.yesterday} - ${t.common.weekday[weekday]} ${day} ${t.common.month[month]} ${year}`;
        } else if (dayDiff < 7) {
            return `${t.common.lastWeekday[weekday]} - ${day} ${t.common.month[month]} ${year}`;
        } else {
            return `${t.common.weekday[weekday]} ${day} ${t.common.month[month]} ${year}`;
        }
    }, [date]);

    return (
        <div className="sticky top-0 bg-white z-20 pt-1 pb-3 px-9">
            <p className="font-normal text-xs text-qura-neutral-balanced">
                {dateString.charAt(0).toUpperCase() + dateString.slice(1)}
            </p>
        </div>
    );
};

const HistoryDialogTrigger = () => {
    return (
        <div className="group" >
            <DialogTrigger >
                <TooltipProvider delayDuration={0}>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center w-8 h-8 group-hover:bg-q-white-10 rounded-md bg-q-black-50">
                                <ClockIcon className='w-5 h-5 text-white group-hover:text-black' />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent side="right">
                            History
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </DialogTrigger>
        </div>
    )
}

export const HistoryDialog = () => {
    const [open, setOpen] = useState(false)

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <HistoryDialogTrigger />
            <HistoryDialogContent setOpen={setOpen} />
        </Dialog>
    )
}