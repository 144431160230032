import { Album, ChevronLeft, ChevronRight, Crosshair, LucideFileText } from 'lucide-react';
import { useMemo, useRef, useState } from 'react';
import {
  Button,
  ResizableHandle,
  ResizablePanel,
  selectTranslation,
  selectTranslationFromArray,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@qura/ui';
import {
  Bundle,
  Document,
  InBundleSearchDocument,
  Lang,
  ReadableFile,
  TocNode,
} from '@qura/shared-types';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { BundleFilePosition } from './BundleViewerPage';
import { useTocNodes } from '../../services/firebase/actions/useTocNodes';

const DocumentsTocList = ({
  bundleId,
  documents,
  searchDoc,
  language,
  selectedDocumentIdx,
  selectedFileIdx,
  setSelectedDocumentIdx,
  setSelectedFileIdx,
  setSelectedTocNode,
  scrollToFilePosition,
}: {
  bundleId: string;
  documents: Document[];
  searchDoc?: InBundleSearchDocument;
  language: Lang;
  selectedDocumentIdx: number;
  selectedFileIdx: number;
  setSelectedDocumentIdx: (idx: number) => void;
  setSelectedFileIdx: (idx: number) => void;
  setSelectedTocNode: (node: TocNode | null) => void;
  scrollToFilePosition: (position: BundleFilePosition) => void;
}) => {
  const sortedDocuments = useMemo(() => {
    return documents.sort((a, b) => {
      return a.order - b.order;
    });
  }, [documents]);

  return (
    <div className="flex flex-col gap-2 p-4 h-full overflow-y-auto">
      {sortedDocuments.map((document, index) => (
        <DocumentNodeEntry
          key={index}
          bundleId={bundleId}
          document={document}
          searchDoc={searchDoc}
          language={language}
          isSelected={index === selectedDocumentIdx}
          setThisDocumentSelected={() => setSelectedDocumentIdx(index)}
          selectedFileIdx={selectedFileIdx}
          setSelectedFileIdx={setSelectedFileIdx}
          setSelectedTocNode={setSelectedTocNode}
          scrollToFilePosition={scrollToFilePosition}
        />
      ))}
    </div>
  );
};

const DocumentNodeEntry = ({
  bundleId,
  document,
  searchDoc,
  language,
  isSelected,
  selectedFileIdx,
  setThisDocumentSelected,
  setSelectedFileIdx,
  setSelectedTocNode,
  scrollToFilePosition,
}: {
  bundleId: string;
  document: Document;
  searchDoc?: InBundleSearchDocument;
  language: Lang;
  isSelected: boolean;
  selectedFileIdx: number;
  setThisDocumentSelected: () => void;
  setSelectedFileIdx: (idx: number) => void;
  setSelectedTocNode: (node: TocNode | null) => void;
  scrollToFilePosition: (position: BundleFilePosition) => void;
}) => {
  const name = selectTranslation(document.label, language) ?? 'Document';
  const sortedFiles = useMemo(() => {
    return document.files.sort((a, b) => {
      return a.order - b.order;
    });
  }, [document.files]);

  const hitCount = searchDoc?.hits
    .filter((hit) => hit.document_pk === document.document_id)
    .reduce((acc, hit) => acc + hit.block_hits.length, 0);

  return (
    <div className="flex flex-col gap-2">
      <Button
        variant={isSelected ? 'secondary' : 'ghost'}
        className="w-full justify-start text-left font-normal border border-gray-200 gap-2 truncate bg-q-gray-20"
        onClick={setThisDocumentSelected}>
        <LucideFileText className="w-3 h-3 shrink-0" />
        {name}
        <div className="flex-grow" />
        {hitCount !== undefined && hitCount > 0 && (
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger>
                <div className="flex items-center bg-q-gray-40 rounded-sm px-1 gap-1">
                  <Crosshair className="w-2 h-2" />
                  <span className="text-sm ">{hitCount}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent side="bottom">Search highlights in this document</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </Button>
      {isSelected && (
        <div className="flex flex-col gap-2 pl-2">
          {sortedFiles.map((file, index) => (
            <FileNodeEntry
              key={index}
              bundleId={bundleId}
              documentId={document.document_id}
              hideFileEntry={sortedFiles.length === 1}
              file={file}
              language={language}
              isSelected={index === selectedFileIdx && isSelected}
              setThisFileSelected={() => setSelectedFileIdx(index)}
              setSelectedTocNode={setSelectedTocNode}
              scrollToFilePosition={scrollToFilePosition}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const FileNodeEntry = ({
  bundleId,
  documentId,
  hideFileEntry,
  file,
  language,
  isSelected,
  setThisFileSelected,
  setSelectedTocNode,
  scrollToFilePosition,
}: {
  bundleId: string;
  documentId: string;
  hideFileEntry?: boolean;
  file: ReadableFile;
  language: Lang;
  isSelected: boolean;
  setThisFileSelected: () => void;
  setSelectedTocNode: (node: TocNode | null) => void;
  scrollToFilePosition: (position: BundleFilePosition) => void;
}) => {
  const { data: tocNodes } = useTocNodes(bundleId, documentId, file.file_id, null);
  const [selectedNodeIdx, setSelectedNodeIdx] = useState<number | null>(null);

  return (
    <div className="flex flex-col gap-2">
      {!hideFileEntry && (
        <div className="flex flex-row gap-2">
          <Button
            variant={isSelected ? 'secondary' : 'ghost'}
            className="w-full justify-start text-left font-normal h-6 border border-gray-200 truncate"
            onClick={setThisFileSelected}>
            {file.label ?? `Part ${file.order + 1}`}
          </Button>
        </div>
      )}
      {isSelected &&
        (tocNodes && tocNodes.length > 0 ? (
          <div className="flex flex-col gap-2 pl-2">
            {tocNodes.map((node, index) => (
              <TocNodeEntry
                key={index}
                bundleId={bundleId}
                documentId={documentId}
                fileId={file.file_id}
                node={node}
                language={language}
                isSelected={index === selectedNodeIdx && isSelected}
                setThisNodeSelected={() => setSelectedNodeIdx(index)}
                setSelectedTocNode={setSelectedTocNode}
                scrollToFilePosition={scrollToFilePosition}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-2 p-2">
            <span className="text-sm text-gray-500 text-left flex items-center gap-2">
              <Album className="w-4 h-4 shrink-0" />
              <span className="line-clamp-1">No table of content available</span>
            </span>
          </div>
        ))}
    </div>
  );
};

const TocNodeEntry = ({
  bundleId,
  documentId,
  fileId,
  node,
  language,
  isSelected,
  setThisNodeSelected,
  setSelectedTocNode,
  scrollToFilePosition,
}: {
  bundleId: string;
  documentId: string;
  fileId: string;
  node: TocNode;
  language: Lang;
  isSelected: boolean;
  setThisNodeSelected: () => void;
  setSelectedTocNode: (node: TocNode | null) => void;
  scrollToFilePosition: (position: BundleFilePosition) => void;
}) => {
  const { data: childNodes } = useTocNodes(bundleId, documentId, fileId, node.node_index);
  const [selectedNodeIdx, setSelectedNodeIdx] = useState<number | null>(null);

  const handleNodeClick = () => {
    setThisNodeSelected();
    setSelectedTocNode(node);
    if (node.file_position.position_type === 'md_line') {
      scrollToFilePosition({
        position_type: 'md_tag',
        reference_ids: [`line-${node.file_position.lines[0].line_index}`],
      });
    } else if (node.file_position.position_type === 'md_tag') {
      scrollToFilePosition(node.file_position);
      window.location.hash = node.file_position.reference_ids[0];
    } else if (node.file_position.position_type === 'offset') {
      scrollToFilePosition(node.file_position);
    } else if (node.file_position.position_type === 'bounding_box') {
      scrollToFilePosition({
        position_type: 'offset',
        page_index: node.file_position.page_index,
        offset: Math.min(...node.file_position.bounding_boxes.map((box) => box.y0)),
      });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <TooltipProvider delayDuration={500}>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant={isSelected ? 'secondary' : 'ghost'}
              className="w-full justify-start text-left font-normal h-6 truncate pl-6 relative"
              onClick={handleNodeClick}>
              {node.children_indices.length > 0 && (
                <ChevronRight
                  className={`h-4 w-4 shrink-0 transition-transform absolute left-1 top-1 ${
                    isSelected ? 'rotate-90' : ''
                  }`}
                />
              )}
              <span className="truncate">{node.label}</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right">{node.label}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {isSelected && childNodes && childNodes.length > 0 && (
        <div className="flex flex-col gap-2 pl-2">
          {childNodes.map((child, index) => (
            <TocNodeEntry
              key={index}
              bundleId={bundleId}
              documentId={documentId}
              fileId={fileId}
              node={child}
              language={language}
              isSelected={index === selectedNodeIdx && isSelected}
              setThisNodeSelected={() => setSelectedNodeIdx(index)}
              setSelectedTocNode={setSelectedTocNode}
              scrollToFilePosition={scrollToFilePosition}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const LeftPanel = ({
  bundle,
  searchDoc,
  language,
  selectedDocumentIdx,
  selectedFileIdx,
  setSelectedDocumentIdx,
  setSelectedFileIdx,
  setSelectedTocNode,
  scrollToFilePosition,
}: {
  bundle: Bundle;
  searchDoc?: InBundleSearchDocument;
  language: Lang;
  selectedDocumentIdx: number;
  selectedFileIdx: number;
  setSelectedDocumentIdx: (idx: number) => void;
  setSelectedFileIdx: (idx: number) => void;
  setSelectedTocNode: (node: TocNode | null) => void;
  scrollToFilePosition: (position: BundleFilePosition) => void;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const panelRef = useRef<ImperativePanelHandle>(null);
  const openSize = 20;
  const collapsedSize = 3;
  const maxSize = 40;

  const handlePanelResize = (size: number) => {
    if (size > collapsedSize) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };

  const bundleName = selectTranslationFromArray(bundle.names, language) ?? null;

  return (
    <>
      <ResizablePanel
        ref={panelRef}
        defaultSize={openSize}
        minSize={collapsedSize}
        maxSize={maxSize}
        onResize={handlePanelResize}
        order={0}>
        <div className="flex flex-col h-full">
          {isExpanded ? (
            <div className="flex flex-col">
              <div className="border-b border-gray-200 flex flex-row cursor-pointer items-center gap-2 h-14 p-3">
                <span className="text-md font-normal truncate line-clamp-1">{bundleName}</span>
                <div className="flex-grow" />
                <ChevronLeft
                  className="w-6 h-6"
                  onClick={() => panelRef.current?.resize(collapsedSize)}
                />
              </div>
            </div>
          ) : (
            <div className="border-b border-gray-200 flex flex-row cursor-pointer items-center justify-center h-14">
              <ChevronRight
                className="w-6 h-6"
                onClick={() => panelRef.current?.resize(openSize)}
              />
            </div>
          )}
          {isExpanded && (
            <DocumentsTocList
              bundleId={bundle.bundle_id}
              documents={bundle?.documents}
              searchDoc={searchDoc}
              language={language}
              selectedDocumentIdx={selectedDocumentIdx}
              selectedFileIdx={selectedFileIdx}
              setSelectedDocumentIdx={setSelectedDocumentIdx}
              setSelectedFileIdx={setSelectedFileIdx}
              setSelectedTocNode={setSelectedTocNode}
              scrollToFilePosition={scrollToFilePosition}
            />
          )}
        </div>
      </ResizablePanel>
      <ResizableHandle withHandle />
    </>
  );
};
