import {
  Bundle,
  BundleHit,
  DocumentHit,
  Highlight,
  MetadataDate,
  PdfHighlight,
  ReadableFile,
} from '@qura/shared-types';
import { useBundle } from '../../services/firebase/actions/useBundle';
import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { PdfViewer, PdfViewerRef, selectTranslationFromArray } from '@qura/ui';
import { getFirebaseStorageUrl } from '../../services/firebase/utils';
import { useInBundleSearch } from '../../services/firebase/actions/useInBundleSearch';
import { useNavigate, useParams } from 'react-router-dom';
import { HighlightPreview } from '../../components/HighlightPreview';
import { PdfViewerPosition } from '@qura/ui/dist/components/pdf-viewer/PDFViewer';
import { visitBundleInCrossBundleSearch } from '../../services/firebase/actions/useCrossBundleSearchHistory';

const formatDate = (date: MetadataDate) => {
  return `${date.year}${date.month && date.year && '-'}${date.month?.toString().padStart(2, '0')}${date.day && date.month && '-'}${date.day?.toString().padStart(2, '0')}`;
};

const computeDateRangeString = (bundle: Bundle) => {
  const dates = bundle.documents.flatMap((doc) => doc.metadata.document_date.entry as MetadataDate);

  if (dates.length === 0) {
    return undefined;
  }

  if (dates.length === 1) {
    return formatDate(dates[0]);
  }

  const sortedDates = dates.sort((a, b) => {
    if ((a.year ?? 0) !== (b.year ?? 0)) {
      return (a.year ?? 0) - (b.year ?? 0);
    }
    if ((a.month ?? 0) !== (b.month ?? 0)) {
      return (a.month ?? 0) - (b.month ?? 0);
    }
    return (a.day ?? 0) - (b.day ?? 0);
  });

  const startDate = sortedDates[0];
  const endDate = sortedDates[sortedDates.length - 1];

  if (!startDate || !endDate) {
    return undefined;
  }

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

const Tag = ({ text, onClick }: { text: string; onClick?: () => void }) => {
  return (
    <span className="px-3 py-1 bg-gray-100 rounded-full text-sm cursor-pointer" onClick={onClick}>
      {text}
    </span>
  );
};

const UnavailableCard = ({ bundleId }: { bundleId?: string }) => {
  return (
    <div className="bg-gray-100 h-full w-full flex flex-col items-center justify-center gap-2 p-6 relative">
      <div className="text-base font-medium text-gray-500">Document not available</div>
      <div className="text-sm text-gray-400 text-center max-w-md">
        The document you're looking for could not be found or you are not authorized to view it.
        Please let us know if you think this is an error.
      </div>
      {bundleId && (
        <div className="absolute top-4 left-4 text-xs text-gray-400">ID: {bundleId}</div>
      )}
    </div>
  );
};

const LoadingCard = () => {
  return (
    <div className="bg-gray-100 h-full w-full flex flex-col items-center justify-center gap-2 p-8">
      <div className="animate-spin w-8 h-8 border-4 border-gray-300 border-t-gray-500 rounded-full"></div>
    </div>
  );
};

const DocumentPreview = ({
  isExpanded,
  activeFile,
  highlights,
  pdfViewerRef,
  defaultPosition,
}: {
  isExpanded: boolean;
  activeFile?: ReadableFile;
  highlights?: Highlight[];
  pdfViewerRef: React.RefObject<PdfViewerRef>;
  defaultPosition?: PdfViewerPosition;
}) => {
  return (
    <div
      className="w-full rounded-lg h-full overflow-hidden"
      onClick={(e) => {
        if (isExpanded) {
          e.stopPropagation();
        }
      }}>
      {(() => {
        if (activeFile && activeFile.file_format === 'pdf') {
          return (
            <PdfViewer
              pdfViewerRef={pdfViewerRef}
              fileEndpoint={getFirebaseStorageUrl(activeFile.file_url)}
              defaultPosition={defaultPosition}
              defaultZoom={100}
              onPositionChange={() => {}}
              onZoomChange={() => {}}
              lockScroll={!isExpanded}
              className={`${
                isExpanded ? 'bg-q-gray-10' : 'bg-q-white-10'
              } transition-colors duration-300 ease-in-out`}
              pageProps={activeFile.page_props}
              highlights={highlights as PdfHighlight[]}
            />
          );
        }
        return null;
      })()}
    </div>
  );
};

interface ResultContentProps {
  bundle: Bundle;
  query: string;
  isExpanded: boolean;
  setExpansionState: (isExpanded: boolean) => void;
  documentHits?: DocumentHit[];
  render: boolean;
}

const ResultContent = ({ bundle, query, isExpanded, documentHits, render }: ResultContentProps) => {
  const { searchId } = useParams();
  const navigate = useNavigate();
  const bundleTitle = selectTranslationFromArray(bundle.titles);
  const bundleName = selectTranslationFromArray(bundle.names);
  const bundleDateString = computeDateRangeString(bundle);

  const [activeDocIdx] = useState(0);
  const [activeFileIdx] = useState(0);

  const activeDoc = useMemo(() => bundle.documents[activeDocIdx], [bundle, activeDocIdx]);
  const activeFile = useMemo(() => activeDoc.files[activeFileIdx], [activeDoc, activeFileIdx]);

  const highlights = useMemo(() => {
    return documentHits
      ?.filter((hit) => hit.document_pk === activeDoc.document_id)
      .flatMap((hit) => hit.block_hits.map((blockHit) => blockHit.highlight));
  }, [documentHits, activeDoc]);

  // Find the lowest page index and the lowest y0 offset
  const defaultPosition: PdfViewerPosition | undefined = useMemo(() => {
    if (!highlights || highlights.length === 0) return undefined;
    const firstHighlight = highlights[0];
    if (firstHighlight.highlight_format === 'pdf') {
      const lowestPageIndex = Math.min(...firstHighlight.position.map((pos) => pos.page_index));
      return {
        pageIndex: lowestPageIndex,
        offset: firstHighlight.position
          .filter((pos) => pos.page_index === lowestPageIndex)
          .flatMap((pos) => pos.bounding_boxes.map((box) => box.y0))
          .reduce((min, y0) => Math.min(min, y0)),
      };
    }
    return undefined;
  }, [highlights]);

  const pdfViewerRef = useRef<PdfViewerRef>(null);

  const onOpenBundle = () => {
    if (!searchId) throw new Error("Search ID is required");
    visitBundleInCrossBundleSearch(searchId, bundle.bundle_id)
      .then(() => {
        console.log("VISITED", searchId, bundle.bundle_id)
      })
      .catch((e) => {
        console.error(e);
      });
    navigate(
      `/bundle/${bundle.bundle_id}?documentIdx=${activeDocIdx}&fileIdx=${activeFileIdx}&query=${query}&sid=${searchId}`,
    );
  };

  const goToHighlight = (highlight: Highlight) => {
    if (highlight.position[0].position_type === 'bounding_box') {
      pdfViewerRef.current?.scrollToPosition({
        pageIndex: highlight.position[0].page_index,
        offset: highlight.position[0].bounding_boxes[0].y0,
      });
    }
  };

  return (
    <div className="flex flex-col h-full overflow-hidden w-full">
      <div className="flex flex-col">
        <div className="flex justify-between items-center p-3">
          <div className="flex gap-2">
            <Tag text={bundleName ?? ''} />
            {bundleDateString && <Tag text={bundleDateString} />}
            <Tag text="Database Filter" />
          </div>
          <div className="flex gap-2">
            <Tag text="Open" onClick={onOpenBundle} />
          </div>
        </div>
        <div className="px-4">{bundleTitle}</div>
        <div
          className={`py-2 px-4 flex gap-2 transition-all duration-500 ease-in-out ${
            isExpanded ? 'opacity-100 max-h-24' : 'opacity-0 max-h-0'
          } overflow-hidden`}>
          {highlights?.map((highlight, index) => (
            <HighlightPreview
              key={index}
              highlight={highlight}
              onClick={(e) => {
                e.stopPropagation();
                goToHighlight(highlight);
              }}
            />
          ))}
        </div>
      </div>

      <div
        className={`flex border-t border-gray-200 p-2 transition-all duration-500 ease-in-out ${
          isExpanded ? 'h-96' : 'h-48'
        }`}>
        {render && defaultPosition && (
          <DocumentPreview
            isExpanded={isExpanded}
            activeFile={activeFile}
            highlights={highlights}
            pdfViewerRef={pdfViewerRef}
            defaultPosition={defaultPosition}
          />
        )}
      </div>
    </div>
  );
};

interface ResultCardProps {
  bundleHit: BundleHit;
  query: string;
  isExpanded: boolean;
  setExpansionState: (isExpanded: boolean) => void;
  render: boolean;
}

export const ResultCard = ({
  bundleHit,
  query,
  isExpanded,
  render,
  setExpansionState,
}: ResultCardProps) => {
  const { searchId } = useParams();
  const bid = bundleHit.bundle_pk;
  const { data: bundleDoc, isLoading } = useBundle(bid);
  const { inBundleSearchDoc: SearchDoc } = useInBundleSearch(`${searchId}_${bid}`, query, bid);

  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimedOut(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-64 w-full flex">
      <div className="min-w-0 flex-grow"></div>
      <div
        className={`flex justify-center shadow-qura rounded-xl w-full max-w-4xl overflow-hidden ${
          isExpanded ? 'outline outline-2 outline-qura-black-10 border-qura-black-10' : ''
        }`}
        onClick={() => setExpansionState(!isExpanded)}>
        {bundleDoc && !isLoading ? (
          <ResultContent
            bundle={bundleDoc}
            query={query}
            isExpanded={isExpanded}
            setExpansionState={setExpansionState}
            documentHits={SearchDoc?.hits}
            render={render}
          />
        ) : timedOut ? (
          <UnavailableCard bundleId={bid} />
        ) : (
          <LoadingCard />
        )}
      </div>
      <div className="min-w-0 flex-grow"></div>
    </div>
  );
};
