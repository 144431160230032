import {
  ResizableHandle,
  ResizablePanel,
  selectTranslation,
  selectTranslationFromArray,
} from '@qura/ui';
import { useRef, useState } from 'react';
import { Bundle, Lang, Document, TocNode } from '@qura/shared-types';
import { ChevronLeft, ChevronRight, FileIcon, LucideFileStack, LucideFileText } from 'lucide-react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { MetadataCard } from './MetadataCard';

export const RightPanel = ({
  bundle,
  selectedDocument,
  selectedTocNode,
  language,
}: {
  bundle: Bundle;
  selectedDocument?: Document;
  selectedTocNode?: TocNode;
  language: Lang;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const panelRef = useRef<ImperativePanelHandle>(null);
  const openSize = 20;
  const collapsedSize = 3;
  const maxSize = 40;

  const handlePanelResize = (size: number) => {
    if (size > collapsedSize) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };

  const showDocumentMetadata =
    selectedDocument?.metadata &&
    Object.keys(selectedDocument?.metadata)
      .map((key) => selectedDocument?.metadata[key])
      .some((value) => value !== null && value !== undefined && !value.hidden);

  const showBundleMetadata =
    bundle?.metadata &&
    Object.keys(bundle?.metadata)
      .map((key) => bundle?.metadata[key])
      .some((value) => value !== null && value !== undefined && !value.hidden);

  return (
    <>
      <ResizableHandle withHandle />
      <ResizablePanel
        ref={panelRef}
        defaultSize={openSize}
        minSize={collapsedSize}
        maxSize={maxSize}
        onResize={handlePanelResize}
        order={2}>
        <div className="flex flex-col h-full">
          {isExpanded ? (
            <div className="flex flex-col">
              <div className="border-b border-gray-200 flex flex-row cursor-pointer items-center gap-2 h-14 pl-3">
                <ChevronRight
                  className="w-6 h-6"
                  onClick={() => panelRef.current?.resize(collapsedSize)}
                />
                <span className="text-md font-normal truncate line-clamp-1">Information</span>
              </div>
            </div>
          ) : (
            <div className="border-b border-gray-200 flex flex-row cursor-pointer items-center justify-center h-14">
              <ChevronLeft className="w-6 h-6" onClick={() => panelRef.current?.resize(openSize)} />{' '}
            </div>
          )}
          {isExpanded && (
            <div className="flex flex-col flex-1 p-4 gap-4 overflow-y-auto">
              {selectedTocNode && (
                <MetadataCard
                  name={selectedTocNode?.label}
                  metadata={selectedTocNode?.metadata ?? {}}
                />
              )}
              {selectedDocument && showDocumentMetadata && (
                <MetadataCard
                  name={selectTranslation(selectedDocument?.label, language)}
                  metadata={selectedDocument?.metadata ?? {}}
                  icon={<LucideFileText className="w-4 h-4" />}
                />
              )}
              {showBundleMetadata && (
                <MetadataCard
                  name="All documents"
                  metadata={bundle?.metadata ?? {}}
                  icon={<LucideFileStack className="w-4 h-4" />}
                />
              )}
            </div>
          )}
        </div>
      </ResizablePanel>
    </>
  );
};
