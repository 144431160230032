import { z } from 'zod';

type RemoveVitePrefix<T extends string> = T extends `VITE_${infer Rest}` ? Rest : T;
function stripVitePrefixes<T extends Record<string, unknown>>(obj: T) {
  const result: Record<string, unknown> = {};
  for (const key in obj) {
    const newKey = key.replace(/^VITE_/, '');
    result[newKey] = obj[key];
  }
  return result as { [K in keyof T as RemoveVitePrefix<string & K>]: T[K] };
}

const envSchema = z
  .object({
    VITE_NODE_ENV: z.preprocess(
      (val) => (val === 'production' ? 'production' : 'development'),
      z.enum(['development', 'production']),
    ),
    VITE_EXPRESS_BACKEND_URL: z.string().default(''),
    VITE_FASTAPI_BACKEND_URL: z.string().default(''),
    VITE_CROSS_BUNDLE_SEARCH_BACKEND: z.enum(['fastapi', 'express']).default('fastapi'),
    VITE_IN_BUNDLE_SEARCH_BACKEND: z.enum(['fastapi', 'express']).default('fastapi'),
    VITE_DOCUMENT_SUGGESTION_BACKEND: z.enum(['fastapi', 'express']).default('fastapi'),
    VITE_FIREBASE_EMULATOR: z.string().transform((val) => val === 'true'),
    VITE_DISABLE_AUTH: z.string().transform((val) => val === 'true'),
    VITE_SENTRY_DSN: z.string().optional().default(''),
    VITE_SENTRY_ORG: z.string().optional().default(''),
    VITE_SENTRY_PROJECT: z.string().optional().default(''),
    VITE_POSTHOG_API_KEY: z.string().optional().default(''),
    VITE_POSTHOG_HOST: z.string().optional().default(''),
    VITE_FIREBASE_API_KEY: z.string(),
    VITE_FIREBASE_AUTH_DOMAIN: z.string(),
    VITE_FIREBASE_PROJECT_ID: z.string(),
    VITE_FIREBASE_STORAGE_BUCKET: z.string(),
    VITE_FIREBASE_MESSAGING_SENDER_ID: z.string(),
    VITE_FIREBASE_APP_ID: z.string(),
    VITE_FIREBASE_MEASUREMENT_ID: z.string(),
  })
  .transform(stripVitePrefixes);

export const env = envSchema.parse(import.meta.env);

export const BACKEND_API_URLS = {
  fastapi: `${env.FASTAPI_BACKEND_URL}/api/fastapi`,
  express: `${env.EXPRESS_BACKEND_URL}/api/express`,
};

export const BUNDLE_COLLECTION = 'bundles2';
export const CROSS_BUNDLE_SEARCH_COLLECTION = 'cross_bundle_searches';
export const IN_BUNDLE_SEARCH_COLLECTION = 'in_bundle_searches';
export const EXAMPLE_QUERY_COLLECTION = 'example_queries';
export const SEARCH_FILTER_COLLECTION = 'search_filters';
export const DOCUMENT_COLLECTION = 'documents';
export const FILE_COLLECTION = 'files';