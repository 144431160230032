import { useNavigate, useParams } from 'react-router-dom';
import { useCrossBundleSearch } from '../../services/firebase/actions/useCrossBundleSearch';
import { useEffect, useRef, useState, useMemo } from 'react';
import { useQueryState, parseAsString, parseAsArrayOf, parseAsInteger } from 'nuqs';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@qura/ui';
import { useTranslations } from '@qura/translations';
import { SourceFilter } from './FilterSection';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { CrossBundleSearchDocument } from '@qura/shared-types';
import { ResultCard } from './ResultCard';
import { YearFilter } from './YearFilter';
import { Layout } from '../../components/Layout/Layout';

const FilterSectionContainer = () => {
  const [leftExpanded, setLeftExpanded] = useState(true);
  const openSize = 20;
  const collapsedSize = 3;
  const maxSize = 40;
  const t = useTranslations();

  const leftPanelRef = useRef<ImperativePanelHandle>(null);

  const handlePanelResize = (size: number) => {
    if (size > collapsedSize) {
      setLeftExpanded(true);
    } else {
      setLeftExpanded(false);
    }
  };

  return (
    <ResizablePanel
      ref={leftPanelRef}
      defaultSize={openSize}
      minSize={collapsedSize}
      maxSize={maxSize}
      onResize={handlePanelResize}>
      <div className="flex flex-col h-full shadow-lg">
        {leftExpanded ? (
          <div className="flex flex-col">
            <div className="border-b border-gray-200 flex flex-row cursor-pointer items-center gap-2 h-14 p-3">
              <span className="text-md font-normal truncate line-clamp-1">Filters</span>
              <div className="flex-grow" />
              <ChevronLeft
                className="w-6 h-6"
                onClick={() => leftPanelRef.current?.resize(collapsedSize)}
              />
            </div>
          </div>
        ) : (
          <div className="border-b border-gray-200 flex flex-row cursor-pointer items-center justify-center h-14">
            <ChevronRight
              className="w-6 h-6"
              onClick={() => leftPanelRef.current?.resize(openSize)}
            />
          </div>
        )}

        {leftExpanded && (
          <div className="overflow-y-auto">
            <div className="p-4 border-b border-qura-neutral-mist" title={t.filters.legalSources}>
              <SourceFilter />
            </div>
            <div className="p-4 border-b border-qura-neutral-mist" title={t.filters.date}>
              <YearFilter />
            </div>
          </div>
        )}
      </div>
    </ResizablePanel>
  );
};

const SearchLoading = () => {
  return (
    <div className="flex flex-col items-center gap-4 pr-4">
      <div className="relative flex flex-col items-center">
        <div className="w-12 h-12 border-4 border-gray-200 border-t-gray-600 rounded-full animate-spin" />
        <div className="mt-4 text-base font-medium text-gray-500 tracking-wide">
          Searching documents
        </div>
        <div className="mt-1 text-sm text-gray-400">This may take a moment</div>
      </div>
    </div>
  );
};

const NoResults = () => {
  return (
    <div className="flex flex-col items-center gap-6 pr-4">
      <div className="relative flex flex-col items-center">
        <div className="text-lg font-medium text-gray-500 tracking-wide">No results found</div>
        <div className="mt-2 text-sm text-gray-400">Try adjusting your search or filters</div>
      </div>
    </div>
  );
};

const NoQuery = () => {
  return (
    <div className="flex flex-col items-center gap-6 pr-4 flex-1">
      <div className="relative flex flex-col items-center">
        <div className="text-lg font-medium text-gray-500 tracking-wide">No query found</div>
        <div className="mt-2 text-sm text-gray-400">Please enter a search query</div>
      </div>
    </div>
  );
};

const NoSearchFound = () => {
  return (
    <div className="flex flex-col items-center gap-6 pr-4">
      <div className="relative flex flex-col items-center">
        <div className="text-lg font-medium text-gray-500 tracking-wide">Search not found</div>
        <div className="mt-2 text-sm text-gray-400 w-96 text-center">
          The search you're looking for doesn't exist. Please let us know if you think this is an
          error.
        </div>
      </div>
    </div>
  );
};

const SearchFailed = () => {
  return (
    <div className="flex flex-col items-center gap-6 pr-4">
      <div className="relative flex flex-col items-center">
        <div className="text-lg font-medium text-gray-500 tracking-wide">Search failed</div>
        <div className="mt-2 text-sm text-gray-400">Please try again later</div>
      </div>
    </div>
  );
};

const SearchResults = (props: { crossBundleSearchDoc: CrossBundleSearchDocument }) => {
  const { crossBundleSearchDoc } = props;

  const [expandedHitIndices, setExpandedHitIndices] = useQueryState(
    'expanded',
    parseAsArrayOf(parseAsInteger),
  );

  const [visibleHits, setVisibleHits] = useState<{ [key: number]: boolean }>({});
  const hitRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.getAttribute('data-index') || '');
          if (!isNaN(index)) {
            setVisibleHits((prev) => ({
              ...prev,
              [index]: entry.isIntersecting,
            }));
          }
        });
      },
      {
        rootMargin: `${window.innerHeight / 2}px 0px`,
        threshold: 0.1,
      },
    );

    crossBundleSearchDoc.hits.forEach((_, index) => {
      const element = hitRefs.current[index];
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  const toggleExpansion = (index: number, isExpanded: boolean) => {
    if (!visibleHits[index]) return;

    setExpandedHitIndices((prev) => {
      const old = prev ?? [];
      if (isExpanded) {
        return [...old, index];
      }
      const next = old.filter((i) => i !== index);
      return next.length === 0 ? null : next;
    });
  };

  return (
    <ResizablePanelGroup direction="horizontal" className="flex flex-1 min-h-0 ">
      <FilterSectionContainer />
      <ResizableHandle />
      <ResizablePanel defaultSize={80} className="flex flex-1 min-h-0">
        <div className="flex flex-1 flex-col items-stretch gap-6 py-6 overflow-auto">
          {crossBundleSearchDoc.hits.map((hit, index) => (
            <div key={index} ref={(el) => (hitRefs.current[index] = el)} data-index={index}>
              <ResultCard
                bundleHit={hit}
                render={visibleHits[index] ?? false}
                query={crossBundleSearchDoc.query}
                isExpanded={expandedHitIndices?.includes(index) ?? false}
                setExpansionState={(isExpanded) => toggleExpansion(index, isExpanded)}
              />
            </div>
          ))}
        </div>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export const SearchResultPage = () => {
  const [query] = useQueryState('query', parseAsString);
  const navigate = useNavigate();
  const { searchId } = useParams();
  const {
    crossBundleSearchDoc: searchDoc,
    isLoading: isLoadingSearch,
    dispatch,
  } = useCrossBundleSearch(searchId, query || '');

  // When search loaded set the url to the search id
  useEffect(() => {
    if (searchDoc) {
      const currentQueryParams = new URLSearchParams(window.location.search);
      navigate(`/search/${searchDoc.cross_bundle_search_id}?${currentQueryParams.toString()}`);
    }
  }, [searchDoc]);

  // If the search is not found, show the loading screen for a short time before showing the no results screen
  const [timedOut, setTimedOut] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimedOut(true);
    }, 1000); // Reduced from 1000ms to 100ms for faster feedback
    return () => clearTimeout(timer);
  }, []);

  // If the search is still processing, show the loading screen
  const isLoading = useMemo(() => {
    return (
      isLoadingSearch ||
      searchDoc?.processing_state.includes('processing') ||
      searchDoc?.processing_state.includes('pending')
    );
  }, [searchDoc, isLoadingSearch]);

  return (
    <Layout dispatch={dispatch}>
      <div className="flex flex-1 items-center justify-center  min-h-0">
        {(() => {
          if (searchDoc && searchDoc.hits.length > 0) {
            return <SearchResults crossBundleSearchDoc={searchDoc} />;
          }
          if (
            searchDoc &&
            searchDoc.processing_state.includes('completed') &&
            searchDoc.hits.length === 0
          ) {
            return <NoResults />;
          }
          if (!!searchId && !searchDoc && timedOut) {
            return <NoSearchFound />;
          }
          if (!!searchId && !searchDoc && !timedOut) {
            return <SearchLoading />;
          }
          if (!timedOut || isLoading) {
            return <SearchLoading />;
          }
          if (!query) {
            return <NoQuery />;
          }
          return <SearchFailed />;
        })()}
      </div>
    </Layout>
  );
};
