import { QueryCache, QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.error(error, query);
      // alertError(error, { function: 'queryCache' }, { query: query.queryHash });
    },
  }),
});
