import { BundleFilePosition } from './BundleViewerPage';
import { HighlightPreview } from '../../components/HighlightPreview';
import { Lang, ReadableFile, TocNode, Document, Highlight } from '@qura/shared-types';
import {
  PdfViewerRef,
  ResizablePanel,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
  TooltipProvider,
  PopoverContent,
  PopoverTrigger,
  Popover,
  Input,
} from '@qura/ui';
import { Button, RTViewer, selectTranslationFromArray, RTEditorRef, PdfViewer } from '@qura/ui';
import { Download, EllipsisIcon, Minus, Plus } from 'lucide-react';
import { Printer } from 'lucide-react';
import { getFirebaseStorageUrl } from '../../services/firebase/utils';
import { parseAsInteger, useQueryState } from 'nuqs';
import { PageInputForm } from '../../components/PageInputForm';
import { fetchTocNodeFromReferenceId } from '../../services/firebase/actions/useTocNodes';
import { useState } from 'react';

const sortHighlights = (highlights: Highlight[]) => {
  if (!highlights || highlights.length === 0) return highlights;

  return highlights.sort((a, b) => {
    const posA = a.position[0];
    const posB = b.position[0];

    if (posA.position_type === 'bounding_box' && posB.position_type === 'bounding_box') {
      return posA.page_index === posB.page_index
        ? posA.bounding_boxes[0].y0 - posB.bounding_boxes[0].y0
        : posA.page_index - posB.page_index;
    }

    if (posA.position_type === 'md_line' && posB.position_type === 'md_line') {
      return posA.lines[0].line_index - posB.lines[0].line_index;
    }

    return 0;
  });
};

const ZoomControl = ({ pdfViewerRef }: { pdfViewerRef: React.RefObject<PdfViewerRef> }) => {
  const getRoundedZoom = () => {
    const zoom = pdfViewerRef.current?.getZoom() ?? 100;
    return Math.round(zoom * 25) / 25;
  };

  const [localZoom, setLocalZoom] = useState(getRoundedZoom());

  const incrementZoom = () => {
    pdfViewerRef.current?.setZoom(getRoundedZoom() + 25);
  };

  const decrementZoom = () => {
    pdfViewerRef.current?.setZoom(getRoundedZoom() - 25);
  };

  return (
    <div className="inline-flex flex-col p-4 bg-q-gray-20 rounded-md border border-red-500">
      <div className="inline-flex gap-1 bg-q-gray-40 rounded-md p-1 items-center">
        <Minus
          className="w-6 h-6 hover:cursor-pointer hover:bg-q-gray-50 rounded-md p-1"
          onClick={decrementZoom}
        />
        <input
          value={localZoom}
          onChange={(e) => setLocalZoom(parseInt(e.target.value))}
          onBlur={(e) => pdfViewerRef.current?.setZoom(localZoom)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              pdfViewerRef.current?.setZoom(localZoom);
            }
          }}
          className="w-12 text-center bg-q-white-10 border-none outline-none"
        />
        <Plus
          className="w-6 h-6 hover:cursor-pointer hover:bg-q-gray-50 rounded-md p-1"
          onClick={incrementZoom}
        />
      </div>
    </div>
  );
};

export const MiddlePanel = ({
  bundleId,
  selectedDocument,
  selectedFile,
  language,
  pdfViewerRef,
  RTEditorRef,
  selectedTocNode,
  setSelectedTocNode,
  highlights,
  scrollToFilePosition,
}: {
  bundleId?: string;
  selectedDocument?: Document;
  language: Lang;
  selectedFile?: ReadableFile;
  pdfViewerRef: React.RefObject<PdfViewerRef>;
  RTEditorRef: React.RefObject<RTEditorRef>;
  selectedTocNode: TocNode | null;
  highlights?: Highlight[];
  setSelectedTocNode: (node: TocNode | null) => void;
  scrollToFilePosition: (position: BundleFilePosition) => void;
}) => {
  const documentName = selectTranslationFromArray(selectedDocument?.names, language) ?? null;
  const documentTitle = selectTranslationFromArray(selectedDocument?.titles, language) ?? null;
  const [pageNumber, setPageNumber] = useQueryState('page', parseAsInteger.withDefault(1));
  const [pageOffset, setPageOffset] = useQueryState('offset', parseAsInteger.withDefault(0));

  const goToHighlight = (highlight: Highlight) => {
    if (highlight.position[0].position_type === 'bounding_box') {
      pdfViewerRef.current?.scrollToPosition({
        pageIndex: highlight.position[0].page_index,
        offset: highlight.position[0].bounding_boxes[0].y0,
      });
    }
  };

  const setTocFromReferenceId = async (referenceId: string) => {
    if (
      selectedTocNode?.file_position.position_type === 'md_tag' &&
      selectedTocNode?.file_position.reference_ids.includes(referenceId)
    ) {
      setSelectedTocNode(null);
      return;
    }

    const tocNode = await fetchTocNodeFromReferenceId(
      bundleId,
      selectedDocument?.document_id,
      selectedFile?.file_id,
      referenceId,
    );
    setSelectedTocNode(tocNode);
  };

  return (
    <ResizablePanel order={1}>
      <div className="h-full flex relative">
        {/* Bottom sidebar with highlights */}

        {/* Main content */}
        <div className="flex-1 flex flex-col gap-2 transition-all duration-200 ease-in-out">
          <div className="px-3 p-2">
            {documentTitle && (
              <div className="line-clamp-2 flex-1 hover:line-clamp-none group rounded-md px-2 py-1">
                {documentTitle}
              </div>
            )}
          </div>

          <main className="flex-1 overflow-y-auto border-t border-gray-200 relative">
            <div className="absolute right-4 top-2 z-10 flex flex-col gap-2">
              <div className="flex gap-2 items-start">
                <TooltipProvider delayDuration={500}>
                  <Tooltip>
                    <TooltipTrigger>
                      <Button size="sm" variant="secondary" className="flex w-8 h-8 bg-q-gray-40">
                        <Printer className="w-4 h-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">Print</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider delayDuration={500}>
                  <Tooltip>
                    <TooltipTrigger>
                      <Button size="sm" variant="secondary" className="flex w-8 h-8 bg-q-gray-40">
                        <Download className="w-4 h-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">Download</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider delayDuration={500}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            size="sm"
                            variant="secondary"
                            className="flex w-8 h-8 bg-q-gray-40">
                            <EllipsisIcon className="w-4 h-4" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="p-0 w-fit">
                          {selectedFile?.file_format === 'pdf' && (
                            <ZoomControl pdfViewerRef={pdfViewerRef} />
                          )}
                        </PopoverContent>
                      </Popover>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">More actions</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                {selectedFile?.file_format === 'pdf' && selectedFile.page_props && (
                  <PageInputForm
                    pageNumber={pageNumber}
                    totalPages={selectedFile.page_props.length}
                    scrollToFilePosition={scrollToFilePosition}
                  />
                )}
              </div>
              {highlights && highlights.length > 0 && (
                <div className="flex flex-col items-end">
                  <div className="flex flex-col  border-gray-200 gap-2">
                    {sortHighlights(highlights).map((highlight, index) => (
                      <HighlightPreview
                        key={index}
                        highlight={highlight}
                        onClick={(e) => {
                          e.stopPropagation();
                          goToHighlight(highlight);
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full h-full">
              {selectedFile?.file_format === 'pdf' && (
                <PdfViewer
                  pdfViewerRef={pdfViewerRef}
                  fileEndpoint={getFirebaseStorageUrl(selectedFile.file_url)}
                  defaultZoom={160}
                  pageProps={selectedFile.page_props}
                  defaultPosition={{ pageIndex: pageNumber - 1, offset: pageOffset ?? 0 }}
                  onPositionChange={(position) => {
                    setPageNumber(position.pageIndex + 1);
                    setPageOffset(position.offset);
                  }}
                  onZoomChange={() => {}}
                  highlights={highlights?.filter((h) => h.highlight_format === 'pdf')}
                />
              )}
              {selectedFile?.file_format === 'md' && (
                <RTViewer
                  fileEndpoint={getFirebaseStorageUrl(selectedFile.lexical_json_url)}
                  RTEditorRef={RTEditorRef}
                  selectedSection={
                    selectedTocNode?.file_position.position_type === 'md_tag'
                      ? selectedTocNode?.file_position.reference_ids[0]
                      : undefined
                  }
                  onSectionClick={setTocFromReferenceId}
                />
              )}
            </div>
          </main>
        </div>
      </div>
    </ResizablePanel>
  );
};
