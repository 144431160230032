import React, { ChangeEvent, useRef, useState } from 'react';
import { sendFeedback } from '../services/firebase/actions/sendFeedback';
import { ArrowRightIcon, MessageCircleWarningIcon } from 'lucide-react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@qura/ui";
import { useTranslations } from "@qura/translations";

const LINE_HEIGHT = 20;
const MAX_HEIGHT = 30 * 10;

const FeedbackPopover = () => {
  const [value, setValue] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showingMessage, setShowingMessage] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const messageTimeout = useRef<NodeJS.Timeout | null>(null);
  const t = useTranslations()

  const showMessage = (message: string, time: number) => {
    setMessage(message);
    setShowingMessage(true);
    if (messageTimeout.current) {
      clearTimeout(messageTimeout.current);
    }
    messageTimeout.current = setTimeout(() => {
      setShowingMessage(false);
    }, time);
  };

  const onSend = async () => {
    if (value.trim().length === 0) return;

    setIsOpen(false);
    showMessage('Sending...', 1000);
    sendFeedback(value.trim())
      .then(() => {
        showMessage(t.feedback.success, 1000);
        setValue('');
      })
      .catch((_err: unknown) => {
        // alertError(err, { function: 'sendFeedback' }, { feedback: value });
        showMessage(t.feedback.failed, 10000);
        setIsOpen(true);
      });
  };

  const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    updateTextareaHeight();
  };

  const updateTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${LINE_HEIGHT}px`;
      const height = Math.min(textarea.scrollHeight, MAX_HEIGHT);
      const heightShouldBe = Math.floor(height / LINE_HEIGHT) * LINE_HEIGHT;
      textarea.style.height = `${heightShouldBe}px`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <div className="group flex items-center justify-center w-8 h-8 hover:bg-q-white-10 rounded-md bg-q-black-50 cursor-pointer">
                <MessageCircleWarningIcon className="h-5 w-5 text-white group-hover:text-black" />
              </div>
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent side="right">
            Send Feedback
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <PopoverContent side="right" className="px-4 py-0 my-4"
        style={{
          width: '600px',
        }}
      >
        <div className="flex gap-4 items-center">
          <textarea
            className="flex flex-1 min-h-16 py-3 rounded-md bg-background text-sm ring-offset-background focus-visible:outline-none  disabled:cursor-not-allowed resize-none"
            ref={textareaRef}
            value={value}
            onChange={onChangeTextarea}
            onKeyDown={handleKeyDown}
            placeholder={t.feedback.input}
            style={{
              lineHeight: `${LINE_HEIGHT}px`,
              height: value.length > 0 ? undefined : `${LINE_HEIGHT}px`,
            }}
          />
          <div className="flex justify-between items-center">
            {showingMessage && (
              <p className="text-sm text-muted-foreground">{message}</p>
            )}
            <button
              disabled={value.trim().length === 0}
              className="ml-auto inline-flex self-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-20 bg-primary text-primary-foreground hover:bg-primary/90 px-2 py-2"
              onClick={onSend}
            >
              <ArrowRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default FeedbackPopover;
