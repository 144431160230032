import { env } from '../../constants';

export const getFirebaseStorageUrl = (gsUrl: string) => {
  if (env.FIREBASE_EMULATOR) {
    const res = gsUrl.replace('gs://', 'http://127.0.0.1:6003/');
    return res;
  }
  const bucket = gsUrl.split('/')[2];
  const name = encodeURIComponent(gsUrl.split('/').slice(3).join('/'));
  return `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${name}?alt=media`;
};
