import { cn, TextLogo } from '@qura/ui';
import { ArrowLeftIcon, ArrowRightIcon, FileIcon, SearchIcon } from 'lucide-react';
import { useState, useRef, useEffect, useMemo } from 'react';
import { useQueryState, parseAsString } from 'nuqs';
import { useNavigate } from 'react-router-dom';

export const Header = ({
  hideSearchBar,
  dispatch,
  searchingInBundle,
  bundleName,
}: {
  hideSearchBar?: boolean;
  dispatch?: (query: string) => void;
  searchingInBundle?: boolean;
  bundleName?: string;
}) => {
  const windowBackAvailable = useMemo(() => {
    return window.history.length > 1;
  }, []);
  const windowForwardAvailable = useMemo(() => {
    return window.history.state?.forward?.length > 0;
  }, []);
  const [isEditing, setIsEditing] = useState(false);
  const measureRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useQueryState('query', parseAsString.withDefault(''));
  const navigate = useNavigate();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (measureRef.current) {
        setWidth(measureRef.current.clientWidth);
      }
    };

    updateWidth();
    const resizeObserver = new ResizeObserver(updateWidth);

    if (measureRef.current) {
      resizeObserver.observe(measureRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleSearch = async (newQuery: string) => {
    if (!newQuery.trim()) return;
    if (dispatch) {
      dispatch(newQuery);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        if (query) {
          await handleSearch(query);
        }
        setIsEditing(false);
        break;
      case 'Escape':
        setIsEditing(false);
        break;
    }
  };

  const onLogoClick = () => {
    navigate('/');
  };

  return (
    <header className="flex flex-row justify-between items-center bg-sidebar h-11">
      {hideSearchBar ? (
        <div />
      ) : (
        <>
          <div className="flex w-36 shrink-0 items-center justify-end px-4">
            <ArrowLeftIcon
              className={cn(
                'w-6 h-6 mr-2 text-q-gray-50 self-center rounded-md',
                windowBackAvailable && 'text-q-white-10 hover:bg-q-black-50',
              )}
              onClick={() => window.history.back()}
            />
            <ArrowRightIcon
              className={cn(
                'w-6 h-6 mr-2 text-q-gray-50 self-center rounded-md',
                windowForwardAvailable && 'text-q-white-10 hover:bg-q-black-50',
              )}
              onClick={() => window.history.forward()}
            />
          </div>
          <div
            className="flex bg-q-black-50 rounded-md px-2 flex-grow text-q-white-10 text-center justify-center cursor-pointer hover:bg-q-black-40 transition-colors"
            onClick={() => inputRef.current?.focus()}>
            {searchingInBundle ? (
              <FileIcon className="w-4 h-4 mr-1 text-q-gray-50 self-center" />
            ) : (
              <SearchIcon
                className={cn('w-4 h-4 mr-2 text-q-gray-50 self-center transition-opacity')}
              />
            )}
            {query && searchingInBundle && (
              <>
                <span className="mr-1 text-q-gray-50 self-center">In {bundleName}:</span>
              </>
            )}
            <span
              ref={measureRef}
              className="absolute pointer-events-none opacity-0"
              style={{
                whiteSpace: 'pre',
              }}>
              {query || 'Search...'}
            </span>
            <input
              type="text"
              ref={inputRef}
              value={query}
              onChange={(e) => setQuery?.(e.target.value)}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsEditing(true)}
              onBlur={() => setIsEditing(false)}
              className="bg-transparent text-q-gray-30 outline-none h-7 whitespace-nowrap overflow-hidden transition-all duration-100"
              placeholder="Search..."
              style={{
                width: width + 10,
              }}
            />
            <SearchIcon className={cn('w-4 h-4 mr-2 opacity-0 self-center')} />
          </div>
        </>
      )}

      <div className="flex w-36 shrink-0 cursor-pointer" onClick={onLogoClick}>
        <TextLogo className="text-q-white-10 mx-9" size="sm" />
      </div>
    </header>
  );
};
