import { collection, getDocs, query, limit, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useQuery } from '@tanstack/react-query';
import { exampleQuerySchema } from '@qura/shared-types';
import { EXAMPLE_QUERY_COLLECTION } from '../../../constants';

export const getExampleQueries = async (count: number) => {
  const exampleQueriesSnapshot = await getDocs(
    query(collection(db, EXAMPLE_QUERY_COLLECTION), where('active', '==', true), limit(count))
  );

  const exampleQueries = exampleQueriesSnapshot.docs.map((doc) => doc.data());
  return exampleQuerySchema.array().parse(exampleQueries);
};

export const useExampleQueries = (count: number) => {
  return useQuery({
    queryKey: ['exampleQueries', count],
    queryFn: () => getExampleQueries(count),
  });
};
