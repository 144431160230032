import { cn, Textarea } from '@qura/ui';
import { ArrowUp } from 'lucide-react';
import { ReactNode } from 'react';

export const SearchButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <button
      className={cn(
        'absolute right-3 bottom-1 w-8 h-8 rounded-full items-center justify-center flex cursor-pointer hover:bg-q-black-20 transition-colors',
        disabled ? 'bg-qura-neutral-silver' : 'bg-q-black-10 shadow-md',
      )}
      onClick={onClick}
      disabled={disabled}>
      <ArrowUp className={'w-5.5 h-5.5 text-q-white-10'} />
    </button>
  );
};

export const SearchBar = ({
  query,
  setQuery,
  runSearch,
  selectedFilters,
}: {
  query?: string;
  setQuery?: (query: string) => void;
  runSearch?: () => void;
  selectedFilters?: ReactNode[];
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      // Allow new line on Shift+Enter or Cmd/Ctrl+Enter
      if (e.shiftKey || e.metaKey || e.ctrlKey) {
        return;
      }

      e.preventDefault();
      if (query && runSearch) {
        runSearch();
      }
    }
  };

  return (
    <div className="w-full">
      <div className="relative">
        <Textarea
          value={query || ''}
          onChange={(e) => setQuery?.(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Fråga Qura AI..."
          className="h-20 min-h-20 max-h-96 bg-qura-neutral-ghost rounded-2xl shadow-search-bar py-2 px-4" // Added padding at bottom to prevent overlap
        />
        <div className="absolute bottom-2 px-3 w-full flex flex-row gap-2 pb-1">
          {selectedFilters?.map((filter) => (
            <div className="flex flex-row gap-2 items-center">{filter}</div>
          ))}
          <SearchButton onClick={() => runSearch?.()} disabled={!query} />
        </div>
      </div>
    </div>
  );
};
