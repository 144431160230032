var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { jsxs, jsx, Fragment } from "react/jsx-runtime";
import * as React from "react";
import React__default, { useState, forwardRef, useImperativeHandle, useEffect, useRef, useMemo, useCallback, createContext, useContext } from "react";
import { X, Search, ChevronsUpDown, Check, PanelLeft, ChevronRight, Circle, LogOut, GripVertical, Loader2, ClockIcon, ArrowRightIcon, FileIcon, EyeOff, EyeIcon } from "lucide-react";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { Command as Command$1 } from "cmdk";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { Page, Document } from "react-pdf";
import { useVirtualizer } from "@tanstack/react-virtual";
import { CodeNode } from "@lexical/code";
import { LinkNode } from "@lexical/link";
import { $isListItemNode, ListNode, ListItemNode } from "@lexical/list";
import { MarkNode, $createMarkNode, $isMarkNode, $unwrapMarkNode, $wrapSelectionInMarkNode } from "@lexical/mark";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { $isHeadingNode, QuoteNode, HeadingNode } from "@lexical/rich-text";
import { $isTableRowNode, TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ContentEditable as ContentEditable$1 } from "@lexical/react/LexicalContentEditable";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { addClassNamesToElement, mergeRegister, registerNestedElementResolver } from "@lexical/utils";
import { ElementNode, $isParagraphNode, $isLineBreakNode, $getNodeByKey, $getRoot, $nodesOfType, $createRangeSelection, $setSelection, $getNearestNodeFromDOMNode } from "lexical";
import { TreeView } from "@lexical/react/LexicalTreeView";
import * as ResizablePrimitive from "react-resizable-panels";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as TabsPrimitive from "@radix-ui/react-tabs";
const BlobImage = ({ className }) => {
  return /* @__PURE__ */ jsxs("div", { className: `relative border border-red-500 ${className}`, children: [
    /* @__PURE__ */ jsx("img", { src: "./image/login-blob.png", className: "filter grayscale" }),
    /* @__PURE__ */ jsx("div", { className: "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-red-500 rounded-full" })
  ] });
};
function cn(...inputs) {
  return twMerge(clsx(inputs));
}
const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline: "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline"
      },
      size: {
        default: "h-9 px-3 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);
const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return /* @__PURE__ */ jsx(Comp, { className: cn(buttonVariants({ variant, size, className })), ref, ...props });
  }
);
Button.displayName = "Button";
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;
const DialogOverlay = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  DialogPrimitive.Overlay,
  {
    ref,
    className: cn(
      "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    ),
    ...props
  }
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = React.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs(DialogPortal, { children: [
  /* @__PURE__ */ jsx(DialogOverlay, {}),
  /* @__PURE__ */ jsxs(
    DialogPrimitive.Content,
    {
      ref,
      className: cn(
        "fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
        className
      ),
      ...props,
      children: [
        children,
        /* @__PURE__ */ jsxs(DialogPrimitive.Close, { className: "absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground", children: [
          /* @__PURE__ */ jsx(X, { className: "h-4 w-4" }),
          /* @__PURE__ */ jsx("span", { className: "sr-only", children: "Close" })
        ] })
      ]
    }
  )
] }));
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({ className, ...props }) => /* @__PURE__ */ jsx("div", { className: cn("flex flex-col space-y-1.5 text-center sm:text-left", className), ...props });
DialogHeader.displayName = "DialogHeader";
const DialogFooter = ({ className, ...props }) => /* @__PURE__ */ jsx(
  "div",
  {
    className: cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className),
    ...props
  }
);
DialogFooter.displayName = "DialogFooter";
const DialogTitle = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  DialogPrimitive.Title,
  {
    ref,
    className: cn("text-lg font-semibold leading-none tracking-tight", className),
    ...props
  }
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  DialogPrimitive.Description,
  {
    ref,
    className: cn("text-sm text-muted-foreground", className),
    ...props
  }
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;
const Command = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  Command$1,
  {
    ref,
    className: cn(
      "flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground",
      className
    ),
    ...props
  }
));
Command.displayName = Command$1.displayName;
const CommandInput = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsxs("div", { className: "flex items-center border-b px-3", "cmdk-input-wrapper": "", children: [
  /* @__PURE__ */ jsx(Search, { className: "mr-2 h-4 w-4 shrink-0 opacity-50" }),
  /* @__PURE__ */ jsx(
    Command$1.Input,
    {
      ref,
      className: cn(
        "flex h-10 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
        className
      ),
      ...props
    }
  )
] }));
CommandInput.displayName = Command$1.Input.displayName;
const CommandList = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  Command$1.List,
  {
    ref,
    className: cn("max-h-[300px] overflow-y-auto overflow-x-hidden", className),
    ...props
  }
));
CommandList.displayName = Command$1.List.displayName;
const CommandEmpty = React.forwardRef((props, ref) => /* @__PURE__ */ jsx(Command$1.Empty, { ref, className: "py-6 text-center text-sm", ...props }));
CommandEmpty.displayName = Command$1.Empty.displayName;
const CommandGroup = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  Command$1.Group,
  {
    ref,
    className: cn(
      "overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground",
      className
    ),
    ...props
  }
));
CommandGroup.displayName = Command$1.Group.displayName;
const CommandSeparator = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  Command$1.Separator,
  {
    ref,
    className: cn("-mx-1 h-px bg-border", className),
    ...props
  }
));
CommandSeparator.displayName = Command$1.Separator.displayName;
const CommandItem = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  Command$1.Item,
  {
    ref,
    className: cn(
      "relative flex cursor-default gap-2 select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled=true]:pointer-events-none data-[selected=true]:bg-accent data-[selected=true]:text-accent-foreground data-[disabled=true]:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
      className
    ),
    ...props
  }
));
CommandItem.displayName = Command$1.Item.displayName;
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;
const PopoverContent = React.forwardRef(({ className, align = "center", sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx(PopoverPrimitive.Portal, { children: /* @__PURE__ */ jsx(
  PopoverPrimitive.Content,
  {
    ref,
    align,
    sideOffset,
    className: cn(
      "z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    ),
    ...props
  }
) }));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
function ComboBox({
  options,
  value,
  onChange,
  placeholder,
  searchPlaceholder
}) {
  var _a;
  const [open, setOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Popover, { open, onOpenChange: setOpen, children: [
    /* @__PURE__ */ jsx(PopoverTrigger, { asChild: true, children: /* @__PURE__ */ jsxs(
      Button,
      {
        variant: "outline",
        role: "combobox",
        "aria-expanded": open,
        className: "justify-between w-full",
        children: [
          value ? (_a = options.find((option) => option.value === value)) == null ? void 0 : _a.label : placeholder,
          /* @__PURE__ */ jsx(ChevronsUpDown, { className: "opacity-50" })
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(
      PopoverContent,
      {
        align: "start",
        className: "p-0",
        style: { width: "var(--radix-popover-trigger-width)" },
        children: /* @__PURE__ */ jsxs(Command, { children: [
          /* @__PURE__ */ jsx(CommandInput, { placeholder: searchPlaceholder, className: "h-9" }),
          /* @__PURE__ */ jsxs(CommandList, { children: [
            /* @__PURE__ */ jsx(CommandEmpty, { children: "No framework found." }),
            /* @__PURE__ */ jsx(CommandGroup, { children: options.map((option) => /* @__PURE__ */ jsxs(
              CommandItem,
              {
                value: option.value,
                onSelect: (currentValue) => {
                  onChange(currentValue === value ? "" : currentValue);
                  setOpen(false);
                },
                children: [
                  option.label,
                  /* @__PURE__ */ jsx(
                    Check,
                    {
                      className: cn("ml-auto", value === option.value ? "opacity-100" : "opacity-0")
                    }
                  )
                ]
              },
              option.value
            )) })
          ] })
        ] })
      }
    )
  ] });
}
const Avatar = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  AvatarPrimitive.Root,
  {
    ref,
    className: cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-sm", className),
    ...props
  }
));
Avatar.displayName = AvatarPrimitive.Root.displayName;
const AvatarImage = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  AvatarPrimitive.Image,
  {
    ref,
    className: cn("aspect-square h-full w-full", className),
    ...props
  }
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
const AvatarFallback = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  AvatarPrimitive.Fallback,
  {
    ref,
    className: cn("flex h-full w-full items-center justify-center bg-muted", className),
    ...props
  }
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;
const MOBILE_BREAKPOINT = 768;
function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(void 0);
  React.useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT - 1}px)`);
    const onChange = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    };
    mql.addEventListener("change", onChange);
    setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    return () => mql.removeEventListener("change", onChange);
  }, []);
  return !!isMobile;
}
const Input = React.forwardRef(
  ({ className, type, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "input",
      {
        type,
        className: cn(
          "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
          className
        ),
        ref,
        ...props
      }
    );
  }
);
Input.displayName = "Input";
const Separator = React.forwardRef(({ className, orientation = "horizontal", decorative = true, ...props }, ref) => /* @__PURE__ */ jsx(
  SeparatorPrimitive.Root,
  {
    ref,
    decorative,
    orientation,
    className: cn(
      "shrink-0 bg-border",
      orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
      className
    ),
    ...props
  }
));
Separator.displayName = SeparatorPrimitive.Root.displayName;
const Sheet = DialogPrimitive.Root;
const SheetTrigger = DialogPrimitive.Trigger;
const SheetClose = DialogPrimitive.Close;
const SheetPortal = DialogPrimitive.Portal;
const SheetOverlay = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  DialogPrimitive.Overlay,
  {
    className: cn(
      "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    ),
    ...props,
    ref
  }
));
SheetOverlay.displayName = DialogPrimitive.Overlay.displayName;
const sheetVariants = cva(
  "fixed z-50 gap-4 bg-background p-6 shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom: "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
        right: "inset-y-0 right-0 h-full w-3/4 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm"
      }
    },
    defaultVariants: {
      side: "right"
    }
  }
);
const SheetContent = React.forwardRef(({ side = "right", className, children, ...props }, ref) => /* @__PURE__ */ jsxs(SheetPortal, { children: [
  /* @__PURE__ */ jsx(SheetOverlay, {}),
  /* @__PURE__ */ jsxs(DialogPrimitive.Content, { ref, className: cn(sheetVariants({ side }), className), ...props, children: [
    /* @__PURE__ */ jsxs(DialogPrimitive.Close, { className: "absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary", children: [
      /* @__PURE__ */ jsx(X, { className: "h-4 w-4" }),
      /* @__PURE__ */ jsx("span", { className: "sr-only", children: "Close" })
    ] }),
    children
  ] })
] }));
SheetContent.displayName = DialogPrimitive.Content.displayName;
const SheetHeader = ({ className, ...props }) => /* @__PURE__ */ jsx("div", { className: cn("flex flex-col space-y-2 text-center sm:text-left", className), ...props });
SheetHeader.displayName = "SheetHeader";
const SheetFooter = ({ className, ...props }) => /* @__PURE__ */ jsx(
  "div",
  {
    className: cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className),
    ...props
  }
);
SheetFooter.displayName = "SheetFooter";
const SheetTitle = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  DialogPrimitive.Title,
  {
    ref,
    className: cn("text-lg font-semibold text-foreground", className),
    ...props
  }
));
SheetTitle.displayName = DialogPrimitive.Title.displayName;
const SheetDescription = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  DialogPrimitive.Description,
  {
    ref,
    className: cn("text-sm text-muted-foreground", className),
    ...props
  }
));
SheetDescription.displayName = DialogPrimitive.Description.displayName;
function Skeleton({ className, ...props }) {
  return /* @__PURE__ */ jsx("div", { className: cn("animate-pulse rounded-md bg-primary/10", className), ...props });
}
const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipContent = React.forwardRef(({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx(TooltipPrimitive.Portal, { children: /* @__PURE__ */ jsx(
  TooltipPrimitive.Content,
  {
    ref,
    sideOffset,
    className: cn(
      "border z-50 overflow-hidden rounded-md bg-primary px-3 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    ),
    ...props
  }
) }));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
const SIDEBAR_COOKIE_NAME = "sidebar:state";
const SIDEBAR_COOKIE_MAX_AGE = 60 * 60 * 24 * 7;
const SIDEBAR_WIDTH = "16rem";
const SIDEBAR_WIDTH_MOBILE = "18rem";
const SIDEBAR_WIDTH_ICON = "3rem";
const SIDEBAR_KEYBOARD_SHORTCUT = "b";
const SidebarContext = React.createContext(null);
function useSidebar() {
  const context = React.useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider.");
  }
  return context;
}
const SidebarProvider = React.forwardRef(
  ({
    defaultOpen = true,
    open: openProp,
    onOpenChange: setOpenProp,
    className,
    style,
    children,
    ...props
  }, ref) => {
    const isMobile = useIsMobile();
    const [openMobile, setOpenMobile] = React.useState(false);
    const [_open, _setOpen] = React.useState(defaultOpen);
    const open = openProp ?? _open;
    const setOpen = React.useCallback(
      (value) => {
        const openState = typeof value === "function" ? value(open) : value;
        if (setOpenProp) {
          setOpenProp(openState);
        } else {
          _setOpen(openState);
        }
        document.cookie = `${SIDEBAR_COOKIE_NAME}=${openState}; path=/; max-age=${SIDEBAR_COOKIE_MAX_AGE}`;
      },
      [setOpenProp, open]
    );
    const toggleSidebar = React.useCallback(() => {
      return isMobile ? setOpenMobile((open2) => !open2) : setOpen((open2) => !open2);
    }, [isMobile, setOpen, setOpenMobile]);
    React.useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === SIDEBAR_KEYBOARD_SHORTCUT && (event.metaKey || event.ctrlKey)) {
          event.preventDefault();
          toggleSidebar();
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }, [toggleSidebar]);
    const state = open ? "expanded" : "collapsed";
    const contextValue = React.useMemo(
      () => ({
        state,
        open,
        setOpen,
        isMobile,
        openMobile,
        setOpenMobile,
        toggleSidebar
      }),
      [state, open, setOpen, isMobile, openMobile, setOpenMobile, toggleSidebar]
    );
    return /* @__PURE__ */ jsx(SidebarContext.Provider, { value: contextValue, children: /* @__PURE__ */ jsx(TooltipProvider, { delayDuration: 0, children: /* @__PURE__ */ jsx(
      "div",
      {
        style: {
          "--sidebar-width": SIDEBAR_WIDTH,
          "--sidebar-width-icon": SIDEBAR_WIDTH_ICON,
          ...style
        },
        className: cn(
          "group/sidebar-wrapper flex min-h-svh w-full has-[[data-variant=inset]]:bg-sidebar",
          className
        ),
        ref,
        ...props,
        children
      }
    ) }) });
  }
);
SidebarProvider.displayName = "SidebarProvider";
const Sidebar = React.forwardRef(
  ({
    side = "left",
    variant = "sidebar",
    collapsible = "offcanvas",
    className,
    children,
    ...props
  }, ref) => {
    const { isMobile, state, openMobile, setOpenMobile } = useSidebar();
    if (collapsible === "none") {
      return /* @__PURE__ */ jsx(
        "div",
        {
          className: cn(
            "flex h-full w-[--sidebar-width] flex-col bg-sidebar text-sidebar-foreground",
            className
          ),
          ref,
          ...props,
          children
        }
      );
    }
    if (isMobile) {
      return /* @__PURE__ */ jsx(Sheet, { open: openMobile, onOpenChange: setOpenMobile, ...props, children: /* @__PURE__ */ jsx(
        SheetContent,
        {
          "data-sidebar": "sidebar",
          "data-mobile": "true",
          className: "w-[--sidebar-width] bg-sidebar p-0 text-sidebar-foreground [&>button]:hidden",
          style: {
            "--sidebar-width": SIDEBAR_WIDTH_MOBILE
          },
          side,
          children: /* @__PURE__ */ jsx("div", { className: "flex h-full w-full flex-col", children })
        }
      ) });
    }
    return /* @__PURE__ */ jsxs(
      "div",
      {
        ref,
        className: "group peer hidden md:block text-sidebar-foreground",
        "data-state": state,
        "data-collapsible": state === "collapsed" ? collapsible : "",
        "data-variant": variant,
        "data-side": side,
        children: [
          /* @__PURE__ */ jsx(
            "div",
            {
              className: cn(
                "duration-200 relative h-svh w-[--sidebar-width] bg-transparent transition-[width] ease-linear",
                "group-data-[collapsible=offcanvas]:w-0",
                "group-data-[side=right]:rotate-180",
                variant === "floating" || variant === "inset" ? "group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]" : "group-data-[collapsible=icon]:w-[--sidebar-width-icon]"
              )
            }
          ),
          /* @__PURE__ */ jsx(
            "div",
            {
              className: cn(
                "duration-200 fixed inset-y-0 z-10 hidden h-svh w-[--sidebar-width] transition-[left,right,width] ease-linear md:flex",
                side === "left" ? "left-0 group-data-[collapsible=offcanvas]:left-[calc(var(--sidebar-width)*-1)]" : "right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]",
                // Adjust the padding for floating and inset variants.
                variant === "floating" || variant === "inset" ? "p-2 group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4)_+2px)]" : "group-data-[collapsible=icon]:w-[--sidebar-width-icon]",
                className
              ),
              ...props,
              children: /* @__PURE__ */ jsx(
                "div",
                {
                  "data-sidebar": "sidebar",
                  className: "flex h-full w-full flex-col bg-sidebar group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:border-sidebar-border group-data-[variant=floating]:shadow",
                  children
                }
              )
            }
          )
        ]
      }
    );
  }
);
Sidebar.displayName = "Sidebar";
const SidebarTrigger = React.forwardRef(({ className, onClick, ...props }, ref) => {
  const { toggleSidebar } = useSidebar();
  return /* @__PURE__ */ jsxs(
    Button,
    {
      ref,
      "data-sidebar": "trigger",
      variant: "ghost",
      size: "icon",
      className: cn("h-7 w-7", className),
      onClick: (event) => {
        onClick == null ? void 0 : onClick(event);
        toggleSidebar();
      },
      ...props,
      children: [
        /* @__PURE__ */ jsx(PanelLeft, {}),
        /* @__PURE__ */ jsx("span", { className: "sr-only", children: "Toggle Sidebar" })
      ]
    }
  );
});
SidebarTrigger.displayName = "SidebarTrigger";
const SidebarRail = React.forwardRef(
  ({ className, ...props }, ref) => {
    const { toggleSidebar } = useSidebar();
    return /* @__PURE__ */ jsx(
      "button",
      {
        ref,
        "data-sidebar": "rail",
        "aria-label": "Toggle Sidebar",
        tabIndex: -1,
        onClick: toggleSidebar,
        title: "Toggle Sidebar",
        className: cn(
          "absolute inset-y-0 z-20 hidden w-4 -translate-x-1/2 transition-all ease-linear after:absolute after:inset-y-0 after:left-1/2 after:w-[2px] hover:after:bg-sidebar-border group-data-[side=left]:-right-4 group-data-[side=right]:left-0 sm:flex",
          "[[data-side=left]_&]:cursor-w-resize [[data-side=right]_&]:cursor-e-resize",
          "[[data-side=left][data-state=collapsed]_&]:cursor-e-resize [[data-side=right][data-state=collapsed]_&]:cursor-w-resize",
          "group-data-[collapsible=offcanvas]:translate-x-0 group-data-[collapsible=offcanvas]:after:left-full group-data-[collapsible=offcanvas]:hover:bg-sidebar",
          "[[data-side=left][data-collapsible=offcanvas]_&]:-right-2",
          "[[data-side=right][data-collapsible=offcanvas]_&]:-left-2",
          className
        ),
        ...props
      }
    );
  }
);
SidebarRail.displayName = "SidebarRail";
const SidebarInset = React.forwardRef(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "main",
      {
        ref,
        className: cn(
          "relative flex min-h-svh flex-1 flex-col bg-background",
          "peer-data-[variant=inset]:min-h-[calc(100svh-theme(spacing.4))] md:peer-data-[variant=inset]:m-2 md:peer-data-[state=collapsed]:peer-data-[variant=inset]:ml-2 md:peer-data-[variant=inset]:ml-0 md:peer-data-[variant=inset]:rounded-xl md:peer-data-[variant=inset]:shadow",
          className
        ),
        ...props
      }
    );
  }
);
SidebarInset.displayName = "SidebarInset";
const SidebarInput = React.forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx(
    Input,
    {
      ref,
      "data-sidebar": "input",
      className: cn(
        "h-8 w-full bg-background shadow-none focus-visible:ring-2 focus-visible:ring-sidebar-ring",
        className
      ),
      ...props
    }
  );
});
SidebarInput.displayName = "SidebarInput";
const SidebarHeader = React.forwardRef(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "div",
      {
        ref,
        "data-sidebar": "header",
        className: cn("flex flex-col gap-2 p-2", className),
        ...props
      }
    );
  }
);
SidebarHeader.displayName = "SidebarHeader";
const SidebarFooter = React.forwardRef(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "div",
      {
        ref,
        "data-sidebar": "footer",
        className: cn("flex flex-col gap-2 p-2", className),
        ...props
      }
    );
  }
);
SidebarFooter.displayName = "SidebarFooter";
const SidebarSeparator = React.forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx(
    Separator,
    {
      ref,
      "data-sidebar": "separator",
      className: cn("mx-2 w-auto bg-sidebar-border", className),
      ...props
    }
  );
});
SidebarSeparator.displayName = "SidebarSeparator";
const SidebarContent = React.forwardRef(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "div",
      {
        ref,
        "data-sidebar": "content",
        className: cn(
          "flex min-h-0 flex-1 flex-col gap-2 overflow-auto group-data-[collapsible=icon]:overflow-hidden",
          className
        ),
        ...props
      }
    );
  }
);
SidebarContent.displayName = "SidebarContent";
const SidebarGroup = React.forwardRef(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "div",
      {
        ref,
        "data-sidebar": "group",
        className: cn("relative flex w-full min-w-0 flex-col p-2", className),
        ...props
      }
    );
  }
);
SidebarGroup.displayName = "SidebarGroup";
const SidebarGroupLabel = React.forwardRef(({ className, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "div";
  return /* @__PURE__ */ jsx(
    Comp,
    {
      ref,
      "data-sidebar": "group-label",
      className: cn(
        "duration-200 flex h-8 shrink-0 items-center rounded-md px-2 text-xs font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opa] ease-linear focus-visible:ring-2 [&>svg]:size-4 [&>svg]:shrink-0",
        "group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0",
        className
      ),
      ...props
    }
  );
});
SidebarGroupLabel.displayName = "SidebarGroupLabel";
const SidebarGroupAction = React.forwardRef(({ className, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button";
  return /* @__PURE__ */ jsx(
    Comp,
    {
      ref,
      "data-sidebar": "group-action",
      className: cn(
        "absolute right-3 top-3.5 flex aspect-square w-5 items-center justify-center rounded-md p-0 text-sidebar-foreground outline-none ring-sidebar-ring transition-transform hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 [&>svg]:size-4 [&>svg]:shrink-0",
        // Increases the hit area of the button on mobile.
        "after:absolute after:-inset-2 after:md:hidden",
        "group-data-[collapsible=icon]:hidden",
        className
      ),
      ...props
    }
  );
});
SidebarGroupAction.displayName = "SidebarGroupAction";
const SidebarGroupContent = React.forwardRef(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx(
    "div",
    {
      ref,
      "data-sidebar": "group-content",
      className: cn("w-full text-sm", className),
      ...props
    }
  )
);
SidebarGroupContent.displayName = "SidebarGroupContent";
const SidebarMenu = React.forwardRef(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx(
    "ul",
    {
      ref,
      "data-sidebar": "menu",
      className: cn("flex w-full min-w-0 flex-col gap-1", className),
      ...props
    }
  )
);
SidebarMenu.displayName = "SidebarMenu";
const SidebarMenuItem = React.forwardRef(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx(
    "li",
    {
      ref,
      "data-sidebar": "menu-item",
      className: cn("group/menu-item relative", className),
      ...props
    }
  )
);
SidebarMenuItem.displayName = "SidebarMenuItem";
const sidebarMenuButtonVariants = cva(
  "peer/menu-button flex w-full items-center gap-2 overflow-hidden rounded-md p-2 text-left text-sm outline-none ring-sidebar-ring transition-[width,height,padding] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 aria-disabled:pointer-events-none aria-disabled:opacity-50 data-[active=true]:bg-sidebar-accent data-[active=true]:font-medium data-[active=true]:text-sidebar-accent-foreground data-[state=open]:hover:bg-sidebar-accent data-[state=open]:hover:text-sidebar-accent-foreground group-data-[collapsible=icon]:!size-8 group-data-[collapsible=icon]:!p-2 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0",
  {
    variants: {
      variant: {
        default: "hover:bg-sidebar-accent hover:text-sidebar-accent-foreground",
        outline: "bg-background shadow-[0_0_0_1px_hsl(var(--sidebar-border))] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground hover:shadow-[0_0_0_1px_hsl(var(--sidebar-accent))]"
      },
      size: {
        default: "h-8 text-sm",
        sm: "h-7 text-xs",
        lg: "h-12 text-sm group-data-[collapsible=icon]:!p-0"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);
const SidebarMenuButton = React.forwardRef(
  ({
    asChild = false,
    isActive = false,
    variant = "default",
    size = "default",
    tooltip,
    className,
    ...props
  }, ref) => {
    const Comp = asChild ? Slot : "button";
    const { isMobile, state } = useSidebar();
    const button = /* @__PURE__ */ jsx(
      Comp,
      {
        ref,
        "data-sidebar": "menu-button",
        "data-size": size,
        "data-active": isActive,
        className: cn(sidebarMenuButtonVariants({ variant, size }), className),
        ...props
      }
    );
    if (!tooltip) {
      return button;
    }
    if (typeof tooltip === "string") {
      tooltip = {
        children: tooltip
      };
    }
    return /* @__PURE__ */ jsxs(Tooltip, { children: [
      /* @__PURE__ */ jsx(TooltipTrigger, { asChild: true, children: button }),
      /* @__PURE__ */ jsx(
        TooltipContent,
        {
          side: "right",
          align: "center",
          hidden: state !== "collapsed" || isMobile,
          ...tooltip
        }
      )
    ] });
  }
);
SidebarMenuButton.displayName = "SidebarMenuButton";
const SidebarMenuAction = React.forwardRef(({ className, asChild = false, showOnHover = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button";
  return /* @__PURE__ */ jsx(
    Comp,
    {
      ref,
      "data-sidebar": "menu-action",
      className: cn(
        "absolute right-1 top-1.5 flex aspect-square w-5 items-center justify-center rounded-md p-0 text-sidebar-foreground outline-none ring-sidebar-ring transition-transform hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 peer-hover/menu-button:text-sidebar-accent-foreground [&>svg]:size-4 [&>svg]:shrink-0",
        // Increases the hit area of the button on mobile.
        "after:absolute after:-inset-2 after:md:hidden",
        "peer-data-[size=sm]/menu-button:top-1",
        "peer-data-[size=default]/menu-button:top-1.5",
        "peer-data-[size=lg]/menu-button:top-2.5",
        "group-data-[collapsible=icon]:hidden",
        showOnHover && "group-focus-within/menu-item:opacity-100 group-hover/menu-item:opacity-100 data-[state=open]:opacity-100 peer-data-[active=true]/menu-button:text-sidebar-accent-foreground md:opacity-0",
        className
      ),
      ...props
    }
  );
});
SidebarMenuAction.displayName = "SidebarMenuAction";
const SidebarMenuBadge = React.forwardRef(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx(
    "div",
    {
      ref,
      "data-sidebar": "menu-badge",
      className: cn(
        "absolute right-1 flex h-5 min-w-5 items-center justify-center rounded-md px-1 text-xs font-medium tabular-nums text-sidebar-foreground select-none pointer-events-none",
        "peer-hover/menu-button:text-sidebar-accent-foreground peer-data-[active=true]/menu-button:text-sidebar-accent-foreground",
        "peer-data-[size=sm]/menu-button:top-1",
        "peer-data-[size=default]/menu-button:top-1.5",
        "peer-data-[size=lg]/menu-button:top-2.5",
        "group-data-[collapsible=icon]:hidden",
        className
      ),
      ...props
    }
  )
);
SidebarMenuBadge.displayName = "SidebarMenuBadge";
const SidebarMenuSkeleton = React.forwardRef(({ className, showIcon = false, ...props }, ref) => {
  const width = React.useMemo(() => {
    return `${Math.floor(Math.random() * 40) + 50}%`;
  }, []);
  return /* @__PURE__ */ jsxs(
    "div",
    {
      ref,
      "data-sidebar": "menu-skeleton",
      className: cn("rounded-md h-8 flex gap-2 px-2 items-center", className),
      ...props,
      children: [
        showIcon && /* @__PURE__ */ jsx(Skeleton, { className: "size-4 rounded-md", "data-sidebar": "menu-skeleton-icon" }),
        /* @__PURE__ */ jsx(
          Skeleton,
          {
            className: "h-4 flex-1 max-w-[--skeleton-width]",
            "data-sidebar": "menu-skeleton-text",
            style: {
              "--skeleton-width": width
            }
          }
        )
      ]
    }
  );
});
SidebarMenuSkeleton.displayName = "SidebarMenuSkeleton";
const SidebarMenuSub = React.forwardRef(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx(
    "ul",
    {
      ref,
      "data-sidebar": "menu-sub",
      className: cn(
        "mx-3.5 flex min-w-0 translate-x-px flex-col gap-1 border-l border-sidebar-border px-2.5 py-0.5",
        "group-data-[collapsible=icon]:hidden",
        className
      ),
      ...props
    }
  )
);
SidebarMenuSub.displayName = "SidebarMenuSub";
const SidebarMenuSubItem = React.forwardRef(
  ({ ...props }, ref) => /* @__PURE__ */ jsx("li", { ref, ...props })
);
SidebarMenuSubItem.displayName = "SidebarMenuSubItem";
const SidebarMenuSubButton = React.forwardRef(({ asChild = false, size = "md", isActive, className, ...props }, ref) => {
  const Comp = asChild ? Slot : "a";
  return /* @__PURE__ */ jsx(
    Comp,
    {
      ref,
      "data-sidebar": "menu-sub-button",
      "data-size": size,
      "data-active": isActive,
      className: cn(
        "flex h-7 min-w-0 -translate-x-px items-center gap-2 overflow-hidden rounded-md px-2 text-sidebar-foreground outline-none ring-sidebar-ring hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 [&>svg]:text-sidebar-accent-foreground",
        "data-[active=true]:bg-sidebar-accent data-[active=true]:text-sidebar-accent-foreground",
        size === "sm" && "text-xs",
        size === "md" && "text-sm",
        "group-data-[collapsible=icon]:hidden",
        className
      ),
      ...props
    }
  );
});
SidebarMenuSubButton.displayName = "SidebarMenuSubButton";
const DropdownMenu = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
const DropdownMenuGroup = DropdownMenuPrimitive.Group;
const DropdownMenuPortal = DropdownMenuPrimitive.Portal;
const DropdownMenuSub = DropdownMenuPrimitive.Sub;
const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
const DropdownMenuSubTrigger = React.forwardRef(({ className, inset, children, ...props }, ref) => /* @__PURE__ */ jsxs(
  DropdownMenuPrimitive.SubTrigger,
  {
    ref,
    className: cn(
      "flex cursor-default gap-2 select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
      inset && "pl-8",
      className
    ),
    ...props,
    children: [
      children,
      /* @__PURE__ */ jsx(ChevronRight, { className: "ml-auto" })
    ]
  }
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;
const DropdownMenuSubContent = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  DropdownMenuPrimitive.SubContent,
  {
    ref,
    className: cn(
      "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    ),
    ...props
  }
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;
const DropdownMenuContent = React.forwardRef(({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx(DropdownMenuPrimitive.Portal, { children: /* @__PURE__ */ jsx(
  DropdownMenuPrimitive.Content,
  {
    ref,
    sideOffset,
    className: cn(
      "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md",
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    ),
    ...props
  }
) }));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
const DropdownMenuItem = React.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx(
  DropdownMenuPrimitive.Item,
  {
    ref,
    className: cn(
      "relative flex cursor-default select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&>svg]:size-4 [&>svg]:shrink-0",
      inset && "pl-8",
      className
    ),
    ...props
  }
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
const DropdownMenuCheckboxItem = React.forwardRef(({ className, children, checked, ...props }, ref) => /* @__PURE__ */ jsxs(
  DropdownMenuPrimitive.CheckboxItem,
  {
    ref,
    className: cn(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    ),
    checked,
    ...props,
    children: [
      /* @__PURE__ */ jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ jsx(DropdownMenuPrimitive.ItemIndicator, { children: /* @__PURE__ */ jsx(Check, { className: "h-4 w-4" }) }) }),
      children
    ]
  }
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
const DropdownMenuRadioItem = React.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs(
  DropdownMenuPrimitive.RadioItem,
  {
    ref,
    className: cn(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    ),
    ...props,
    children: [
      /* @__PURE__ */ jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ jsx(DropdownMenuPrimitive.ItemIndicator, { children: /* @__PURE__ */ jsx(Circle, { className: "h-2 w-2 fill-current" }) }) }),
      children
    ]
  }
));
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;
const DropdownMenuLabel = React.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx(
  DropdownMenuPrimitive.Label,
  {
    ref,
    className: cn("px-2 py-1.5 text-sm font-semibold", inset && "pl-8", className),
    ...props
  }
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;
const DropdownMenuSeparator = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  DropdownMenuPrimitive.Separator,
  {
    ref,
    className: cn("-mx-1 my-1 h-px bg-muted", className),
    ...props
  }
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
const DropdownMenuShortcut = ({ className, ...props }) => {
  return /* @__PURE__ */ jsx("span", { className: cn("ml-auto text-xs tracking-widest opacity-60", className), ...props });
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";
const CommandBar = ({
  items,
  user,
  logout,
  isLoading
}) => {
  return /* @__PURE__ */ jsxs(Sidebar, { collapsible: "icon", side: "left", variant: "sidebar", children: [
    /* @__PURE__ */ jsx(SidebarContent, { children: /* @__PURE__ */ jsx(SidebarGroup, { children: /* @__PURE__ */ jsx(SidebarGroupContent, { children: /* @__PURE__ */ jsx(SidebarMenu, { children: isLoading ? Array.from({ length: 3 }).map((_, index) => /* @__PURE__ */ jsx(SidebarMenuItem, { children: /* @__PURE__ */ jsx(SidebarMenuSkeleton, {}) }, index)) : /* @__PURE__ */ jsx(TooltipProvider, { delayDuration: 10, children: items.map((item) => /* @__PURE__ */ jsx(SidebarMenuItem, { children: /* @__PURE__ */ jsxs(Tooltip, { children: [
      /* @__PURE__ */ jsx(TooltipTrigger, { asChild: true, children: /* @__PURE__ */ jsx(
        SidebarMenuButton,
        {
          asChild: true,
          isActive: item.selected,
          children: /* @__PURE__ */ jsxs("span", { children: [
            item.element,
            /* @__PURE__ */ jsx("span", { children: item.title })
          ] })
        }
      ) }),
      /* @__PURE__ */ jsx(TooltipContent, { side: "right", children: item.title })
    ] }) }, item.title)) }) }) }) }) }),
    /* @__PURE__ */ jsx(SidebarFooter, { children: /* @__PURE__ */ jsx(SidebarGroup, { className: "px-0", children: /* @__PURE__ */ jsxs(DropdownMenu, { children: [
      /* @__PURE__ */ jsx(DropdownMenuTrigger, { disabled: isLoading, asChild: true, children: /* @__PURE__ */ jsx(Avatar, { className: "h-8 w-8 cursor-pointer", children: isLoading ? /* @__PURE__ */ jsx(AvatarFallback, { className: "animate-pulse bg-muted" }) : /* @__PURE__ */ jsx(AvatarFallback, { className: "text-primary", children: user == null ? void 0 : user.displayName.slice(0, 2).toUpperCase() }) }) }),
      /* @__PURE__ */ jsxs(DropdownMenuContent, { side: "right", align: "end", className: "w-[240px]", children: [
        /* @__PURE__ */ jsx(DropdownMenuLabel, { children: /* @__PURE__ */ jsxs("div", { className: "flex flex-col gap-2", children: [
          /* @__PURE__ */ jsxs("div", { className: "flex flex-col", children: [
            /* @__PURE__ */ jsx("span", { className: "text-sm font-medium", children: user == null ? void 0 : user.displayName }),
            /* @__PURE__ */ jsx("span", { className: "text-sm font-medium text-muted-foreground", children: user == null ? void 0 : user.companyName })
          ] }),
          /* @__PURE__ */ jsx("span", { className: "text-xs text-muted-foreground/60", children: user == null ? void 0 : user.email })
        ] }) }),
        /* @__PURE__ */ jsx(DropdownMenuSeparator, {}),
        /* @__PURE__ */ jsxs(DropdownMenuItem, { onClick: logout, className: "cursor-pointer", children: [
          /* @__PURE__ */ jsx(LogOut, { className: "mr-2 h-4 w-4" }),
          /* @__PURE__ */ jsx("span", { children: "Log out" })
        ] })
      ] })
    ] }) }) })
  ] });
};
const NameCard = ({
  name,
  onClick,
  color,
  draggable = true,
  onDragStart,
  onDragEnd
}) => {
  const colorClass = !color ? "bg-[#B2E500]" : color === "yellow" ? "bg-[#FFE248]" : color === "red" ? "bg-[#E5322C]" : "bg-[#B2E500]";
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: `flex items-center h-7 bg-white border border-black/5 shadow-[0px_0px_20px_-1px_rgba(0,0,0,0.05)] rounded-md overflow-hidden ${draggable ? "cursor-grab active:cursor-grabbing" : ""}`,
      draggable,
      onDragStart,
      onDragEnd,
      onClick,
      children: [
        /* @__PURE__ */ jsx("div", { className: `min-w-[6px] h-full ${colorClass}` }),
        /* @__PURE__ */ jsxs("div", { className: "flex items-center justify-between pr-2 flex-1", children: [
          draggable && /* @__PURE__ */ jsx(GripVertical, { className: "w-4 h-4 text-gray-500" }),
          /* @__PURE__ */ jsx("span", { className: "text-xs font-medium truncate", children: name })
        ] })
      ]
    }
  );
};
const Icon = ({ name, className = "", size = "md", ...props }) => {
  const sizeClasses = {
    xs: "text-xs",
    sm: "text-md",
    md: "text-xl",
    lg: "text-2xl",
    xl: "text-4xl"
  };
  return /* @__PURE__ */ jsx("i", { className: `icon-${name} ${sizeClasses[size]} ${className}`, ...props });
};
const TextLogo = ({ size = "md", onClick, className }) => {
  const widths = {
    xs: "50px",
    sm: "68px",
    md: "82px",
    lg: "100px",
    xl: "148px"
  };
  return /* @__PURE__ */ jsx(
    "div",
    {
      style: { width: widths[size] },
      onClick,
      className: cn(
        onClick ? "cursor-pointer hover:scale-105 transition-all duration-300" : "",
        className
      ),
      children: /* @__PURE__ */ jsx(Icon, { name: "qura-logo-text", size })
    }
  );
};
function LoadingPage({ pulse = true }) {
  return /* @__PURE__ */ jsx("div", { className: "flex flex-col items-center justify-center w-full h-full", children: /* @__PURE__ */ jsx(
    TextLogo,
    {
      size: "xl",
      className: cn(pulse ? "animate-pulse duration-600" : "animate-none duration-0")
    }
  ) });
}
const HighlightBox = ({ highlight, zoom }) => {
  const colorLookup = {
    text: "rgba(215, 255, 215, 0.8)",
    keyword: "rgba(215, 215, 255, 0.8)"
  };
  const padding = 2;
  return /* @__PURE__ */ jsx(Fragment, { children: highlight.position.map(
    (position) => position.bounding_boxes.map((rect, rectIndex) => /* @__PURE__ */ jsx(
      "div",
      {
        style: {
          cursor: "default",
          userSelect: "none",
          pointerEvents: "none",
          position: "absolute",
          zIndex: 100,
          mixBlendMode: "multiply",
          background: colorLookup[highlight.highlight_type],
          top: rect.y0 * zoom * 0.01 - padding,
          left: rect.x0 * zoom * 0.01 - padding,
          width: (rect.x1 - rect.x0) * zoom * 0.01 + padding * 2,
          height: (rect.y1 - rect.y0) * zoom * 0.01 + padding * 2,
          borderRadius: "5px"
        }
      },
      rectIndex
    ))
  ) });
};
const PageItem = ({
  virtualItem,
  zoom,
  pageIndex,
  virtualizer,
  width,
  height,
  highlights,
  blank,
  textLayer
}) => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      ref: virtualizer.measureElement,
      "data-index": virtualItem.index,
      className: `${pageIndex % 2 ? "ListItemOdd" : "ListItemEven"} top-0 left-0 right-0 mr-auto ml-auto absolute`,
      style: {
        width: width * zoom / 100,
        height: height * zoom / 100,
        transform: `translateY(${virtualItem.start}px)`
      },
      children: blank ? /* @__PURE__ */ jsx("div", { className: "flex justify-center items-center h-full w-full bg-white" }) : /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          Page,
          {
            pageIndex: virtualItem.index,
            width,
            scale: zoom / 100,
            loading: /* @__PURE__ */ jsx("div", { className: "flex justify-center items-center h-full w-full bg-white", children: /* @__PURE__ */ jsx(Loader2, { className: "animate-spin" }) }),
            error: /* @__PURE__ */ jsx("div", { className: "flex justify-center items-center h-full w-full", children: /* @__PURE__ */ jsx("p", { children: "Error loading page" }) }),
            renderAnnotationLayer: false,
            renderTextLayer: textLayer
          }
        ),
        highlights == null ? void 0 : highlights.map((highlight, index) => /* @__PURE__ */ jsx(HighlightBox, { highlight, zoom }, index))
      ] })
    },
    virtualItem.key
  );
};
const VirtualizerList = forwardRef(
  ({
    defaultPosition,
    onPositionChange,
    defaultZoom,
    pageProps,
    scrollRef,
    onZoomChange,
    highlights,
    lockZoom,
    textLayer
  }, ref) => {
    const [zoom, _setZoom] = useState(defaultZoom);
    const [zooming, setZooming] = useState(false);
    const virtualizer = useVirtualizer({
      count: pageProps.length,
      getScrollElement: () => scrollRef.current,
      estimateSize: (i2) => {
        var _a;
        return (((_a = pageProps[i2]) == null ? void 0 : _a.height) || 0) * (zoom / 100) + 20;
      },
      overscan: 10,
      gap: 10
    });
    const items = virtualizer.getVirtualItems();
    const scrollToPosition = (position) => {
      const offsetResult = virtualizer.getOffsetForIndex(position.pageIndex, "start");
      if (offsetResult) {
        virtualizer.scrollToOffset(offsetResult[0] + position.offset * zoom / 100);
      }
    };
    const setZoom = (zoom2) => {
      if (!lockZoom) {
        _setZoom(zoom2);
      }
    };
    useImperativeHandle(ref, () => ({
      scrollToPosition,
      setZoom,
      getZoom: () => zoom
    }));
    useEffect(() => {
      scrollToPosition(defaultPosition);
    }, []);
    useEffect(() => {
      if (lockZoom) return;
      let zoomingTimeout;
      const handleWheel = (event) => {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          setZooming(true);
          const zoomFactor = -event.deltaY * 1.5;
          const newZoom = Math.min(Math.max(zoom + zoomFactor, 25), 500);
          setZoom(newZoom);
          clearTimeout(zoomingTimeout);
          zoomingTimeout = setTimeout(() => {
            setZooming(false);
          }, 500);
        }
      };
      const scrollElement = scrollRef.current;
      if (scrollElement) {
        scrollElement.addEventListener("wheel", handleWheel, { passive: false });
        return () => {
          scrollElement.removeEventListener("wheel", handleWheel);
        };
      }
    }, [scrollRef, zoom]);
    useEffect(() => {
      if (lockZoom) return;
      const scrollListener = () => {
        if (!virtualizer.range) return;
        const middleIndex = Math.floor(
          (virtualizer.range.startIndex + virtualizer.range.endIndex) / 2
        );
        const currentOffset = virtualizer.scrollOffset;
        const pageStartOffset = virtualizer.getOffsetForIndex(middleIndex, "start");
        if (pageStartOffset && currentOffset !== null) {
          const newOffset = Math.round(currentOffset - pageStartOffset[0]);
          if (middleIndex !== defaultPosition.pageIndex || newOffset !== defaultPosition.offset) {
            onPositionChange == null ? void 0 : onPositionChange({ pageIndex: middleIndex, offset: newOffset });
          }
        }
      };
      const scrollElement = virtualizer.scrollElement;
      if (scrollElement) {
        scrollElement.addEventListener("scroll", scrollListener, { passive: true });
        return () => {
          scrollElement.removeEventListener("scroll", scrollListener);
        };
      }
    }, [virtualizer, onPositionChange, defaultPosition, lockZoom]);
    useEffect(() => {
      onZoomChange == null ? void 0 : onZoomChange(zoom);
    }, [onZoomChange, zoom]);
    return /* @__PURE__ */ jsx("div", { style: { height: virtualizer.getTotalSize() }, className: `relative w-full`, children: items.map((virtualItem) => /* @__PURE__ */ jsx(
      PageItem,
      {
        virtualItem,
        pageIndex: virtualItem.index,
        zoom,
        virtualizer,
        width: pageProps[virtualItem.index].width,
        height: pageProps[virtualItem.index].height,
        highlights: highlights == null ? void 0 : highlights.filter(
          (highlight) => highlight.position.some((pos) => pos.page_index === virtualItem.index)
        ),
        blank: zooming,
        textLayer
      },
      virtualItem.key
    )) });
  }
);
const PdfViewer = ({
  fileEndpoint,
  pageProps,
  defaultZoom = 100,
  defaultPosition = { pageIndex: 0, offset: 0 },
  onPositionChange,
  onZoomChange,
  onLoadSuccess,
  onLoadError,
  pdfViewerRef,
  lockScroll = false,
  className = "bg-q-gray-20",
  highlights,
  textLayer = true
}) => {
  const scrollRef = useRef(null);
  return /* @__PURE__ */ jsx("div", { className: `w-full h-full flex-col overflow-hidden ${className || ""}`, children: /* @__PURE__ */ jsx(
    "div",
    {
      ref: scrollRef,
      className: `h-full w-full relative ${lockScroll ? "" : "overflow-y-auto"}`,
      style: { overflowY: lockScroll ? "hidden" : "auto" },
      children: /* @__PURE__ */ jsx(
        Document,
        {
          file: fileEndpoint,
          onLoadSuccess,
          onLoadError,
          loading: /* @__PURE__ */ jsx("div", { className: "flex justify-center items-center w-full h-full absolute inset-0", children: /* @__PURE__ */ jsx(Loader2, { className: "animate-spin" }) }),
          children: /* @__PURE__ */ jsx(
            VirtualizerList,
            {
              ref: pdfViewerRef,
              pageProps,
              defaultZoom,
              defaultPosition,
              onPositionChange,
              onZoomChange,
              scrollRef,
              highlights,
              lockZoom: lockScroll,
              textLayer
            }
          )
        }
      )
    }
  ) });
};
function ContentEditable({ onRef }) {
  return /* @__PURE__ */ jsx("div", { className: "h-full overflow-auto relative", children: /* @__PURE__ */ jsx("div", { ref: onRef, className: "w-full flex justify-center", children: /* @__PURE__ */ jsx(ContentEditable$1, { className: "h-fit min-h-full w-full py-2 px-6 text-base relative outline-none" }) }) });
}
class HRNode extends ElementNode {
  constructor(key) {
    super(key);
  }
  static getType() {
    return "hr";
  }
  static clone(node) {
    return new HRNode(node == null ? void 0 : node.__key);
  }
  static importJSON(serializedNode) {
    return $createHRNode();
  }
  exportJSON() {
    return {
      ...super.exportJSON()
    };
  }
  createDOM(_config, _editor) {
    const element = document.createElement("hr");
    addClassNamesToElement(element, "border-t-1", "border-gray-300", "my-4");
    return element;
  }
  decorate(_editor) {
    return null;
  }
}
function $createHRNode() {
  return new HRNode();
}
function $isHRNode(node) {
  return node instanceof HRNode;
}
const $isParagraphWithoutLineBreaks = (node) => {
  return $isParagraphNode(node) && !node.getChildren().some($isLineBreakNode);
};
const $isFirstChildOfParagraph = (node) => {
  return $isParagraphNode(node.getParent()) && node.getTextContent().trim() && node.getPreviousSibling() === null;
};
const $isAfterLineBreak = (node) => {
  return node.getTextContent().trim() && $isLineBreakNode(node.getPreviousSibling());
};
const $isLineNode = (node) => {
  return $isParagraphWithoutLineBreaks(node) || $isHeadingNode(node) || $isListItemNode(node) || $isHRNode(node) || $isTableRowNode(node) || $isFirstChildOfParagraph(node) || $isAfterLineBreak(node);
};
const $getLineNodes = (node) => {
  const lineNodes = [];
  if ($isLineNode(node)) {
    lineNodes.push(node);
  } else {
    if (node instanceof ElementNode) {
      const children = node.getChildren();
      children.forEach((child) => {
        lineNodes.push(...$getLineNodes(child));
      });
    }
  }
  return lineNodes;
};
const HighlightPluginComponent = (props, ref) => {
  const { highlights, onHighlightCreation } = props;
  const [editor] = useLexicalComposerContext();
  const [lineNodes, setLineNodes] = useState([]);
  const markNodeMap = useMemo(() => /* @__PURE__ */ new Map(), []);
  const scrollToHighlight = (highlightId) => {
    const keys = markNodeMap.get(highlightId);
    const firstKey = keys == null ? void 0 : keys.values().next().value;
    if (firstKey) {
      const elem = editor.getElementByKey(firstKey);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };
  useImperativeHandle(ref, () => ({
    scrollToHighlight
  }));
  useEffect(() => {
    const markNodeKeysToIDs = /* @__PURE__ */ new Map();
    return mergeRegister(
      registerNestedElementResolver(
        editor,
        MarkNode,
        (from) => {
          return $createMarkNode(from.getIDs());
        },
        (from, to) => {
          const ids = from.getIDs();
          ids.forEach((id) => {
            to.addID(id);
          });
        }
      ),
      editor.registerMutationListener(
        MarkNode,
        (mutations) => {
          editor.getEditorState().read(() => {
            for (const [key, mutation] of mutations) {
              const node = $getNodeByKey(key);
              let ids = [];
              if ($isMarkNode(node)) {
                ids = node.getIDs();
              }
              for (let i2 = 0; i2 < ids.length; i2++) {
                const id = ids[i2];
                let markNodeKeys = markNodeMap.get(id);
                markNodeKeysToIDs.set(key, ids);
                if (mutation !== "destroyed") {
                  if (markNodeKeys === void 0) {
                    markNodeKeys = /* @__PURE__ */ new Set();
                    markNodeMap.set(id, markNodeKeys);
                  }
                  if (!markNodeKeys.has(key)) {
                    markNodeKeys.add(key);
                  }
                }
              }
            }
          });
        },
        { skipInitialization: false }
      )
      // editor.registerUpdateListener(({ editorState }) => {
      //   editorState.read(() => {
      //     const selection = $getSelection();
      //     let hasActiveIds = false;
      //
      //     if ($isRangeSelection(selection)) {
      //       const anchorNode = selection.anchor.getNode();
      //
      //       if ($isTextNode(anchorNode)) {
      //         const commentIDs = $getMarkIDs(anchorNode, selection.anchor.offset);
      //         if (commentIDs !== null) {
      //           setActiveIDs(commentIDs);
      //           hasActiveIds = true;
      //         }
      //       }
      //     }
      //
      //     if (!hasActiveIds) {
      //       setActiveIDs((_activeIds) => (_activeIds.length === 0 ? _activeIds : []));
      //     }
      //   });
      // }),
    );
  }, [editor, markNodeMap]);
  useEffect(() => {
    let lineNodes2 = [];
    editor.read(() => {
      lineNodes2 = $getLineNodes($getRoot());
    });
    setLineNodes(lineNodes2.map((node) => node.getKey()));
  }, [editor]);
  useEffect(() => {
    editor.update(() => {
      const markNodes = $nodesOfType(MarkNode);
      markNodes.forEach((markNode) => {
        $unwrapMarkNode(markNode);
      });
    });
    highlights == null ? void 0 : highlights.forEach((highlight) => {
      editor.update(() => {
        const newSelection = $createRangeSelection();
        const anchorNodeKey = lineNodes[highlight.fromLine];
        const focusNodeKey = lineNodes[highlight.toLine];
        if (!anchorNodeKey || !focusNodeKey) {
          return;
        }
        newSelection.anchor.key = anchorNodeKey;
        newSelection.anchor.offset = 0;
        newSelection.anchor.type = "text";
        newSelection.focus.key = focusNodeKey;
        newSelection.focus.offset = 0;
        newSelection.focus.type = "text";
        $wrapSelectionInMarkNode(newSelection, false, `${highlight.fromLine}_${highlight.toLine}`);
        $setSelection(null);
      });
    });
  }, [highlights, editor, lineNodes.length]);
  return null;
};
const HighlightPlugin = forwardRef(HighlightPluginComponent);
class LineNode extends ElementNode {
  constructor(lineIndex, key) {
    super(key);
    __publicField(this, "__lineIndex");
    this.__lineIndex = lineIndex;
  }
  getLineIndex() {
    return this.__lineIndex;
  }
  static getType() {
    return "line";
  }
  static clone(node) {
    return new LineNode(node.__lineIndex, node == null ? void 0 : node.__key);
  }
  static importJSON(serializedNode) {
    return $createLineNode(serializedNode.lineIndex);
  }
  exportJSON() {
    return {
      ...super.exportJSON(),
      lineIndex: this.getLineIndex()
    };
  }
  createDOM(_config, _editor) {
    const element = document.createElement("div");
    return element;
  }
  decorate(_editor) {
    return null;
  }
}
function $createLineNode(lineIndex) {
  return new LineNode(lineIndex);
}
class ReferenceNode extends ElementNode {
  constructor(referenceId, referenceType, key) {
    super(key);
    __publicField(this, "__referenceId");
    __publicField(this, "__referenceType");
    this.__referenceId = referenceId;
    this.__referenceType = referenceType;
  }
  static getType() {
    return "reference";
  }
  getReferenceId() {
    return this.__referenceId;
  }
  getReferenceType() {
    return this.__referenceType;
  }
  static clone(node) {
    return new ReferenceNode(node.__referenceId, node.__referenceType, node == null ? void 0 : node.__key);
  }
  createDOM() {
    const dom = document.createElement("span");
    dom.setAttribute("id", this.__referenceId);
    dom.setAttribute("data-reference-type", this.__referenceType);
    addClassNamesToElement(dom, "q_reference", "block");
    return dom;
  }
  exportDOM() {
    const element = document.createElement("span");
    element.setAttribute("data-reference-type", this.__referenceType);
    element.setAttribute("id", this.__referenceId);
    return { element };
  }
  updateDOM() {
    return false;
  }
  static importJSON(serializedNode) {
    return $createReferenceNode(serializedNode.referenceId, serializedNode.referenceType);
  }
  exportJSON() {
    return {
      ...super.exportJSON(),
      referenceType: this.getReferenceType(),
      referenceId: this.getReferenceId(),
      type: "reference"
    };
  }
  getTextContent() {
    return `<single-reference id="${this.__referenceId}" type="${this.__referenceType}" />`;
  }
}
function $createReferenceNode(referenceId, referenceType) {
  return new ReferenceNode(referenceId, referenceType);
}
function $isReferenceNode(node) {
  return node instanceof ReferenceNode;
}
const useSectionEvents = (onSectionScroll, onSectionClick) => {
  const [lexicalEditor] = useLexicalComposerContext();
  useEffect(() => {
    const visibleSections = /* @__PURE__ */ new Set();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.id) {
            if (!visibleSections.has(entry.target.id)) {
              visibleSections.add(entry.target.id);
              onSectionScroll == null ? void 0 : onSectionScroll(entry.target.id);
            }
          }
        });
      },
      { threshold: 0.5 }
    );
    const handleClick = (e) => {
      const target = e.target;
      const highlight = target.closest("mark");
      const domNode = target.closest("span[data-reference-type]");
      if (!domNode || highlight) {
        return;
      }
      let refNode = null;
      lexicalEditor.update(() => {
        const maybeRefNode = $getNearestNodeFromDOMNode(domNode);
        if ($isReferenceNode(maybeRefNode)) {
          refNode = maybeRefNode;
        }
      });
      if (refNode) {
        onSectionClick == null ? void 0 : onSectionClick(refNode.getReferenceId());
      }
    };
    const editor = document.querySelector('[data-lexical-editor="true"]');
    if (editor) {
      editor.addEventListener("click", handleClick);
      editor.querySelectorAll("span[data-reference-type]").forEach((element) => {
        observer.observe(element);
      });
    }
    return () => {
      observer.disconnect();
      const editor2 = document.querySelector('[data-lexical-editor="true"]');
      editor2 == null ? void 0 : editor2.removeEventListener("click", handleClick);
    };
  }, [onSectionScroll, onSectionClick]);
};
const useSelectedSection = (selectedSectionId) => {
  useEffect(() => {
    var _a;
    if (selectedSectionId) {
      (_a = document.getElementById(selectedSectionId)) == null ? void 0 : _a.classList.add("q_reference_selected");
    }
    document.querySelectorAll(".q_reference_selected").forEach((elem) => {
      if (elem.id !== selectedSectionId) {
        elem.classList.remove("q_reference_selected");
      }
    });
  }, [selectedSectionId]);
};
const SectionPlugin = (props) => {
  const { selectedSection, onSectionScroll, onSectionClick } = props;
  useSectionEvents(onSectionScroll, onSectionClick);
  useSelectedSection(selectedSection);
  return null;
};
function TreeViewPlugin() {
  const [editor] = useLexicalComposerContext();
  return /* @__PURE__ */ jsx("div", { className: "text-xs text-muted-foreground p-4", children: /* @__PURE__ */ jsx(
    TreeView,
    {
      treeTypeButtonClassName: "debug-treetype-button",
      timeTravelButtonClassName: "debug-timetravel-button",
      timeTravelPanelButtonClassName: "debug-timetravel-button",
      timeTravelPanelClassName: "debug-timetravel-panel",
      timeTravelPanelSliderClassName: "debug-timetravel-slider",
      viewClassName: "tree-view-output",
      editor
    }
  ) });
}
const theme = {
  code: "block my-2 font-inter",
  embedBlock: {
    base: "my-2 p-2 border rounded",
    focus: "ring-2 ring-blue-500"
  },
  heading: {
    h1: "text-2xl font-bold mb-6 mt-8",
    h2: "text-2xl font-bold mb-5 mt-7",
    h3: "text-xl font-bold mb-4 mt-6",
    h4: "text-xl font-semibold mb-3 mt-5",
    h5: "text-lg font-semibold mb-3 mt-4",
    h6: "text-base font-semibold mb-2 mt-3"
  },
  list: {
    checklist: "list-none pl-4",
    listitem: "ml-2 mb-1",
    listitemChecked: "line-through text-gray-500",
    listitemUnchecked: "text-gray-900",
    nested: {
      listitem: "ml-1 mb-1"
    },
    olDepth: [
      "list-decimal pl-5",
      "list-decimal pl-8",
      "list-decimal pl-11",
      "list-decimal pl-14",
      "list-decimal pl-17"
    ],
    ol: "list-decimal pl-5 mt-2 mb-2",
    ul: "list-disc pl-5 mt-2 mb-2"
  },
  text: {
    underline: "underline decoration-1",
    code: "px-1 font-inter"
  },
  paragraph: "mb-2",
  hr: "border-t-2 border-gray-300 my-4",
  link: "text-blue-600 hover:text-blue-800 underline",
  mark: "bg-q-green-20 border-b-1 border-q-green-20 cursor-pointer q_mark p-1 rounded-md",
  markOverlap: "bg-blue-100/30 border-b-2 border-blue-200/70 q_mark",
  quote: "border-l-4 border-gray-300 pl-4 italic my-4",
  table: "w-full border-collapse border border-gray-300 my-4",
  tableCell: "border border-gray-300 p-2",
  tableRow: "even:bg-gray-100/40",
  tableCellHeader: "border border-gray-300 p-2 bg-gray-200/40 text-left"
};
const RTEditorComponent = (props, ref) => {
  const {
    highlights = [],
    debug = false,
    defaultState = "",
    isEditable = true,
    selectedSection
  } = props;
  const { onPositionChange, onSectionClick, onHighlightCreation } = props;
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const highlightPluginRef = React__default.createRef();
  const scrollToSection = (sectionId) => {
    var _a;
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      (_a = highlightPluginRef.current) == null ? void 0 : _a.scrollToHighlight(sectionId);
    }
  };
  useImperativeHandle(ref, () => ({
    scrollToSection
  }));
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };
  const initialConfig = {
    namespace: "RT_EDITOR",
    nodes: [
      HRNode,
      QuoteNode,
      LinkNode,
      TableCellNode,
      TableNode,
      TableRowNode,
      CodeNode,
      HeadingNode,
      ListNode,
      ListItemNode,
      ReferenceNode,
      MarkNode,
      LineNode
    ],
    editorState: defaultState,
    editable: isEditable,
    onError: (error) => console.error(error),
    theme
  };
  return /* @__PURE__ */ jsx("div", { className: "h-full relative flex flex-col overflow-hidden w-full border", children: /* @__PURE__ */ jsxs(LexicalComposer, { initialConfig, children: [
    /* @__PURE__ */ jsx(ListPlugin, {}),
    /* @__PURE__ */ jsx(TablePlugin, {}),
    /* @__PURE__ */ jsx(
      RichTextPlugin,
      {
        contentEditable: /* @__PURE__ */ jsx(ContentEditable, { onRef }),
        ErrorBoundary: LexicalErrorBoundary
      }
    ),
    /* @__PURE__ */ jsx(
      HighlightPlugin,
      {
        ref: highlightPluginRef,
        highlights,
        selectedSection,
        onHighlightCreation,
        onHighlightSelection: onSectionClick
      }
    ),
    floatingAnchorElem && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
      SectionPlugin,
      {
        selectedSection,
        onSectionClick,
        onSectionScroll: onPositionChange,
        anchorElem: floatingAnchorElem
      }
    ) }),
    debug && /* @__PURE__ */ jsx(TreeViewPlugin, {})
  ] }) });
};
const RTEditor = forwardRef(RTEditorComponent);
function RTViewer(props) {
  const { fileEndpoint, RTEditorRef: RTEditorRef2, selectedSection, onSectionClick, onPositionChange } = props;
  const [content, setContent] = useState("");
  useEffect(() => {
    const fetchContent = async () => {
      const response = await fetch(fileEndpoint);
      const text = await response.text();
      setContent(text);
    };
    fetchContent();
  }, [fileEndpoint]);
  if (!content) return null;
  return /* @__PURE__ */ jsx(
    RTEditor,
    {
      highlights: [],
      defaultState: content,
      isEditable: false,
      ref: RTEditorRef2,
      selectedSection,
      onSectionClick,
      onPositionChange
    }
  );
}
const badgeVariants = cva(
  "inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80",
        secondary: "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive: "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
        outline: "text-foreground"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
);
function Badge({ className, variant, ...props }) {
  return /* @__PURE__ */ jsx("div", { className: cn(badgeVariants({ variant }), className), ...props });
}
const ResizablePanelGroup = ({
  className,
  ...props
}) => /* @__PURE__ */ jsx(
  ResizablePrimitive.PanelGroup,
  {
    className: cn("flex h-full w-full data-[panel-group-direction=vertical]:flex-col", className),
    ...props
  }
);
const ResizablePanel = ResizablePrimitive.Panel;
const ResizableHandle = ({
  withHandle,
  className,
  ...props
}) => /* @__PURE__ */ jsx(
  ResizablePrimitive.PanelResizeHandle,
  {
    className: cn(
      "relative flex w-px items-center justify-center bg-border after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 data-[panel-group-direction=vertical]:h-px data-[panel-group-direction=vertical]:w-full data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-1 data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2 data-[panel-group-direction=vertical]:after:translate-x-0 [&[data-panel-group-direction=vertical]>div]:rotate-90",
      className
    ),
    ...props,
    children: withHandle && /* @__PURE__ */ jsx("div", { className: "z-10 flex h-4 w-3 items-center justify-center rounded-sm border bg-border", children: /* @__PURE__ */ jsx(GripVertical, { className: "h-2.5 w-2.5" }) })
  }
);
const LINE_HEIGHT = 23;
const MAX_HEIGHT = 23 * 6;
const SearchBar = ({
  query,
  placeholder,
  SearchBarDropdown: SearchBarDropdown2 = null,
  onFocus,
  onBlur,
  onSearchClick,
  queryChanged,
  onKeyUpDown,
  className
}) => {
  const textareaRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState(LINE_HEIGHT);
  const updateTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${LINE_HEIGHT}px`;
      const height = Math.min(textarea.scrollHeight, MAX_HEIGHT);
      const heightShouldBe = Math.floor(height / LINE_HEIGHT) * LINE_HEIGHT;
      textarea.style.height = `${heightShouldBe}px`;
      setTextareaHeight(heightShouldBe);
    }
  }, []);
  const updateLocalQuery = useCallback(
    (query2) => {
      updateTextareaHeight();
      queryChanged && queryChanged(query2);
    },
    [updateTextareaHeight, queryChanged]
  );
  useEffect(() => {
    updateLocalQuery(query || "");
  }, [query, updateLocalQuery]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        onSearchClick && onSearchClick();
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      onKeyUpDown && onKeyUpDown(e.key === "ArrowUp" ? 1 : -1);
    }
  };
  return /* @__PURE__ */ jsxs("div", { className: `relative ${className}`, children: [
    /* @__PURE__ */ jsx(
      "div",
      {
        className: `flex flex-col w-full px-5 py-4 rounded-t-[28px] bg-white text-sm shadow-highlight z-20 ${SearchBarDropdown2 ? "" : "rounded-b-[28px]"}`,
        children: /* @__PURE__ */ jsxs("div", { className: "flex relative gap-[10px] items-center", style: { height: textareaHeight }, children: [
          /* @__PURE__ */ jsx("button", { onClick: onSearchClick, title: "Search", children: /* @__PURE__ */ jsx(Search, { className: "w-5 h-5" }) }),
          /* @__PURE__ */ jsx(
            "textarea",
            {
              ref: textareaRef,
              onFocus,
              onBlur,
              value: query,
              className: "flex-1 resize-none bg-transparent outline-none border-none",
              style: { lineHeight: `${LINE_HEIGHT}px` },
              onChange: (e) => updateLocalQuery(e.target.value),
              onKeyDown: handleKeyDown,
              placeholder
            }
          )
        ] })
      }
    ),
    SearchBarDropdown2
  ] });
};
const SearchBarDropdownOption = (props) => {
  const { title, tag, TitleIcon, TagIcon, selected, onClick } = props;
  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) onClick();
  };
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: `pointer-events-auto group flex items-center h-7 my-1.5 px-2 border ${selected ? "bg-qura-neutral-ghost border-gray-200" : "border-transparent "} hover:bg-qura-neutral-ghost hover:border-gray-200 rounded-md cursor-pointer`,
      onClick: onClick && handleClick,
      children: [
        TitleIcon,
        tag && /* @__PURE__ */ jsxs("div", { className: "flex min-w-20", children: [
          /* @__PURE__ */ jsxs(
            "div",
            {
              className: `flex items-center text-xs bg-qura-neutral-ghost rounded px-2 border ${selected ? "border-transparent" : "border-gray-200 group-hover:border-transparent"}`,
              children: [
                TagIcon,
                /* @__PURE__ */ jsx("p", { className: "pl-1.5 py-1", children: tag })
              ]
            }
          ),
          /* @__PURE__ */ jsx("div", { className: "w-2" })
        ] }),
        /* @__PURE__ */ jsx("p", { className: "pl-2.5 flex-1 line-clamp-1 text-sm", children: title })
      ]
    }
  );
};
const HistoryDropdown = ({
  onSearchClick,
  onOpenHistoryModal,
  recentSearches,
  selectedIndex,
  seeMoreLabel,
  searchHistoryLabel
}) => {
  if (!recentSearches) return null;
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx("p", { className: "text-tiny mt-2 text-qura-neutral-balanced", children: searchHistoryLabel }),
    recentSearches.recent.map((search, index) => /* @__PURE__ */ jsx(
      SearchBarDropdownOption,
      {
        title: search.query,
        onClick: () => onSearchClick(search.searchId, search.query),
        TitleIcon: /* @__PURE__ */ jsx(ClockIcon, { className: "w-3 h-4" }),
        selected: selectedIndex === index
      },
      search.searchId
    )),
    /* @__PURE__ */ jsx(
      "button",
      {
        onClick: onOpenHistoryModal,
        className: "hover:underline font-normal text-xs text-qura-neutral-balanced",
        children: /* @__PURE__ */ jsxs("div", { className: "flex items-center", children: [
          /* @__PURE__ */ jsx("p", { className: "text-tiny", children: seeMoreLabel }),
          /* @__PURE__ */ jsx(ArrowRightIcon, { className: "ml-1 inline w-[12px]" })
        ] })
      }
    )
  ] });
};
const DocumentSuggestionsDropdown = ({
  label,
  documentSuggestions,
  selectedIndex,
  onSuggestionClick
}) => {
  if (!documentSuggestions || documentSuggestions.completions.length === 0) return null;
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx("p", { className: "text-tiny mt-2 text-qura-neutral-balanced", children: label }),
    documentSuggestions.completions.map((completion, index) => /* @__PURE__ */ jsx(
      SearchBarDropdownOption,
      {
        title: completion.title,
        tag: completion.legal_id,
        TagIcon: /* @__PURE__ */ jsx(FileIcon, { className: "w-3 h-4" }),
        onClick: () => onSuggestionClick(completion.document_id),
        selected: selectedIndex === index
      },
      completion.document_id + index
    ))
  ] });
};
const SearchBarDropdown = (props) => {
  const { children } = props;
  return /* @__PURE__ */ jsxs("div", { className: "bg-white shadow-lg rounded-b-[28px] pb-5 px-5", children: [
    /* @__PURE__ */ jsx("div", { className: "h-[1px] w-full bg-gray-200 mb-1" }),
    children
  ] });
};
const Switch = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  SwitchPrimitives.Root,
  {
    className: cn(
      "peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
      className
    ),
    ...props,
    ref,
    children: /* @__PURE__ */ jsx(
      SwitchPrimitives.Thumb,
      {
        className: cn(
          "pointer-events-none block h-4 w-4 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0"
        )
      }
    )
  }
));
Switch.displayName = SwitchPrimitives.Root.displayName;
const Tabs = TabsPrimitive.Root;
const TabsList = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  TabsPrimitive.List,
  {
    ref,
    className: cn(
      "inline-flex h-9 items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground",
      className
    ),
    ...props
  }
));
TabsList.displayName = TabsPrimitive.List.displayName;
const TabsTrigger = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  TabsPrimitive.Trigger,
  {
    ref,
    className: cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow",
      className
    ),
    ...props
  }
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;
const TabsContent = React.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx(
  TabsPrimitive.Content,
  {
    ref,
    className: cn(
      "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    ),
    ...props
  }
));
TabsContent.displayName = TabsPrimitive.Content.displayName;
const Textarea = React.forwardRef(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "textarea",
      {
        className: cn(
          "flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-base placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm resize-none",
          // Added resize-none here
          className
        ),
        ref,
        ...props
      }
    );
  }
);
Textarea.displayName = "Textarea";
const AuthBackground = ({ children }) => {
  return /* @__PURE__ */ jsx("div", { className: "flex flex-col items-center justify-center h-screen bg-white ", children });
};
const AuthContainerCard = ({ children }) => {
  return /* @__PURE__ */ jsxs("div", { className: "flex relative flex-col w-[90%] rounded-2xl max-w-96 bg-white px-12 overflow-clip shadow-auth-container ", children: [
    /* @__PURE__ */ jsx(
      "img",
      {
        src: "./image/login-blob.png",
        className: "absolute aspect-square min-w-[950px] w-[950px] self-center bottom-0 z-0 filter grayscale translate-y-[620px] rotate-[110deg]"
      }
    ),
    children
  ] });
};
const AuthDescription = ({ text }) => {
  return /* @__PURE__ */ jsx("div", { className: "flex relative flex-col items-center text-sm font-light mb-8", children: text.split("\n").map((line, index) => /* @__PURE__ */ jsx("p", { className: "text-center", children: line }, index)) });
};
const AuthError = ({ error }) => {
  return /* @__PURE__ */ jsx("div", { className: "", children: error !== null && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx("p", { className: "text-xs font-medium text-qura-red", children: error }) }) });
};
const d = {
  loginPage: {
    title: "Log In",
    forgotPassword: "Forgot password?",
    login: "Log in",
    description: `Enter your details
to sign in to your account`
  },
  confirmEmailPage: {
    title: "Confirm Email",
    confirmedMessage: "Your email has been confirmed. You can now log in.",
    confirmEmailMessage: "Confirm your email adress to log in. We've sent a confirmation link to:",
    isLoading: "Sending...",
    resendLink: "Resend Link",
    backToLogin: "Back to Login",
    spamEmail: "Don't forget to check your spam folder",
    confirming: "Confirming your email",
    confirmingError: "Failed to confirm your email. Please try again."
  },
  forgotPasswordPage: {
    title: "Forgot Password",
    recoverPassword: "Recover Password",
    emailSentSuccess: "A link to reset your password has been sent to your email.",
    description: `Enter your email
to recover your password`
  },
  resetPasswordPage: {
    resetPassword: "Reset Password",
    passwordPlaceholder: "Enter new password",
    confirmPasswordPlaceholder: "Confirm new password",
    login: "Log in",
    success: {
      passwordReset: "Your password has been reset!",
      backToLogin: "Back to Login"
    }
  },
  signupPage: {
    namePlaceholder: "Enter your name",
    confirmPasswordPlaceholder: "Confirm your password",
    signup: "Sign Up",
    description: `Enter your details
to create a new account`
  },
  feedback: {
    input: "Type your feedback here...",
    success: "Feedback sent",
    failed: "Failed to send feedback"
  },
  searchPromptPage: {
    subtitle: "Ask in natural language what you are searching for"
  },
  userDropdown: {
    changePassword: "Change Password",
    logout: "Log Out",
    pending: "Sending reset password email...",
    success: "A link to reset your password has been sent to your email.",
    error: "Failed to send reset password email. Please try again."
  },
  searchBar: {
    placeholder: "Search Qura AI...",
    searchingStatus: "Searching in document",
    documentMatch: "Document Match",
    suggestions: "Suggestions",
    searchHistory: "Search History",
    seeAll: "See all",
    inDocumentSearch: "Search in document"
  },
  searchHistory: {
    searchHistory: "Search History",
    documentsVisited: {
      one: "{{count}} visited document",
      other: "{{count}} visited documents"
    }
  },
  searchResult: {
    openTag: "Open",
    noSearchResults: "No search results found",
    tryUpdateFilters: "Try updating your filters or query.",
    newSearch: "New Search",
    searchMatch: "Search match",
    pageNumberShort: "p.",
    openPdf: "Open",
    clickToExpand: "Click to expand",
    clickToMinimize: "Click to minimize",
    previewLoading: "Loading preview...",
    previewError: "Failed to load preview",
    noPreview: "No preview available",
    feedbackSubmitted: "Thank you for your feedback!",
    keywords: "Keywords"
  },
  searchDocumentPage: {
    title: "Search results in document",
    downloadDocumentWithHighlights: "Download PDF with highlights",
    downloadDocumentWithoutHighlights: "Download PDF without highlights",
    downloadHighlightedPages: "Download highlighted pages",
    printDocumentWithHighlights: "Print PDF with highlights",
    printDocumentWithoutHighlights: "Print PDF without highlights",
    printHighlightedPages: "Print highlighted pages",
    downloadError: "Failed to download PDF",
    backToSearch: "Back to search",
    foundChunksCount: {
      one: "Found {{count}} relevant page",
      other: "Found {{count}} relevant pages"
    },
    loadingChunks: "Loading pages...",
    noChunksFound: "No relevant pages found",
    referenceNotFound: "Document currently not available",
    citedDocuments: "Cited Documents",
    citedByDocuments: "Cited By Documents",
    relatedDocuments: "Related Documents",
    deepSearch: "Refine Search"
  },
  documentMetadata: {
    legal_id: "Legal ID",
    publication_date: "Publication Date",
    source: "Source",
    no_source_link: "Source not available"
  },
  filters: {
    filter: "Filter",
    filterByYear: "Filter by year",
    applyFilters: "Apply selected filters",
    from: "From",
    to: "to",
    reset: "Reset",
    legalSources: "Legal Sources",
    date: "Date"
  },
  errors: {
    invalidCredentials: "Invalid Credentials",
    network: "You are offline.",
    invalidSignupToken: "Your signup link is invalid.",
    emailNotConfirmed: "Email not confirmed.",
    emailAlreadyUsed: "Email has already been used with another account.",
    noResults: "No results found.",
    loadResourcesFailed: `Our servers are currently down.
Please try again later.`,
    resultChunkFailed: "Failed to load preview in ResultChunk with react pdf document. Error: ",
    unknown: "An unknown has error occurred.",
    invalidSearchId: "Was not able to find the search on this link.",
    invalidEmail: "Your email is invalid.",
    invalidPassword: "Your password is invalid.",
    invalidUsername: "Your name is invalid.",
    document404Title: "Could not find the document",
    document404Description: "Please write in the feedback form if this should be a link to a document.",
    search404Title: "Could not find the search",
    search404Description: "Please write in the feedback form if this should not have happened.",
    dataRole404Title: "User is not valid",
    dataRole404Description: "Please contact us to resolve this.",
    errorPageTitle: "Unknown Error",
    errorPageDescription: "This should not have happened. Please write in the feedback form to help us understand what went wrong.",
    searchFailed: "Failed to start search. Please try again.",
    nameRequired: "Name is required.",
    nameTooShort: "Name must be at least three characters long.",
    emailRequired: "Email is required.",
    emailInvalid: "Invalid email format.",
    passwordRequired: "Password is required.",
    passwordTooShort: "Password must be at least 8 characters long.",
    passwordMismatch: "Passwords do not match."
  },
  common: {
    tryAgain: "Try again",
    passwordPlaceholder: "Enter your password",
    emailPlaceholder: "Enter your email",
    login: "Login",
    loading: "Loading...",
    password: "Password",
    email: "Email",
    confirmPassword: "Confirm password",
    name: "Name",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    weekday: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    today: "Today",
    yesterday: "Yesterday",
    tomorrow: "Tomorrow",
    lastWeekday: [
      "Last Sunday",
      "Last Monday",
      "Last Tuesday",
      "Last Wednesday",
      "Last Thursday",
      "Last Friday",
      "Last Saturday"
    ],
    backToHome: "Back to home",
    loadMore: "Load more"
  },
  searchStatus: {
    searchScore: "Search Score",
    documentsFound: "Found Documents",
    documentsSearched: "Searched Documents",
    analyzingQuery: "Analyzing Query",
    searchingDatabases: "Searching Database",
    rankingDocuments: "Ranking Documents",
    done: "Done",
    completed: "Completed",
    ongoing: "In Progress...",
    pending: "Pending",
    error: "Failed"
  },
  layout: {
    allResults: "All Results"
  },
  databaseInformation: {
    databaseInformation: "Database Information"
  },
  examplePrompts: {
    swe: [
      "Hur ska köp av osäkra fordringar behandlas i mervärdesskattehänseende?",
      "Vad har brottslighetens art tillmätts för betydelse vid påföljdsval i rättspraxis?",
      "När kan en statlig myndighet ha klagorätt genom att företräda ett privaträttsligt intresse?"
    ],
    eu: [
      'How is "communication to the public" interpreted in article 3(19) of Directive 2001/29?',
      "How does judicial independence relate to the right to a fair trial?",
      'How to decide where a child is a "habitually resident"?'
    ],
    default: [
      'How is "communication to the public" interpreted in article 3(19) of Directive 2001/29?',
      "How does judicial independence relate to the right to a fair trial?",
      'How to decide where a child is a "habitually resident"?'
    ]
  },
  authFooter: "Qura uses AI to find the relevant laws, cases and regulations for any legal context. We strive to transform legal research.",
  authFooterAlt1: "© 2024 Lawlabs AB ",
  authFooterAlt2: "Stockholm 111 20",
  onboardingPage: {
    title: "Get Started with Our Quick Guide",
    welcomeMessage: "Welcome to Qura!",
    learnHowToUseQura: "Learn how to use Qura.",
    description: "We provide tips on how to make the best use of our powerful AI search engine and its features.",
    viewQuickGuide: "View Quick Guide",
    showMeLater: "Show me later",
    haveMoreQuestions: "Do you have more questions?",
    contactTeam: "Contact the team",
    quickGuideUrl: "https://www.qura.law/quick-guide-en"
  },
  helpButton: {
    modalLabel: "Do you need help?",
    modalTitle: "Quick Guide",
    modalDescription: "Learn how to use Qura's powerful AI search engine.",
    viewQuickGuide: "View Quick Guide",
    haveMoreQuestions: "Do you have more questions?",
    contactTeam: "Contact the team",
    notificationTitle: "Quick Guide",
    notificationDescription: "You can always find it in the question mark."
  },
  relatedDocuments: {
    title: "Related Documents",
    citedDocuments: "Cited Documents",
    citedByDocuments: "Cited By Documents"
  },
  NPSForm: {
    modalLabel: "Help us get better",
    modalQuestion: "How likely are you to recommend Qura to a friend or colleague?",
    dismissButton: "Remind me later",
    lowerBound: "1 = Not likely",
    upperBound: "10 = Very likely"
  }
}, l = {
  loginPage: {
    title: "Logga in",
    forgotPassword: "Glömt lösenord?",
    login: "Logga in",
    description: `Ange dina uppgifter
för att logga in`
  },
  confirmEmailPage: {
    title: "Bekräfta e-post",
    confirmedMessage: "Din e-post har bekräftats. Du kan nu logga in.",
    confirmEmailMessage: "Bekräfta din e-postadress för att logga in. Vi har skickat en bekräftelselänk till:",
    isLoading: "Skickar...",
    resendLink: "Skicka länken igen",
    backToLogin: "Tillbaka till inloggning",
    spamEmail: "Glöm inte att kolla din skräppost",
    confirming: "Bekräftar din e-post",
    confirmingError: "Misslyckades med att bekräfta din e-post. Försök igen."
  },
  forgotPasswordPage: {
    title: "Glömt lösenord",
    recoverPassword: "Återställ lösenord",
    emailSentSuccess: "En länk för att återställa ditt lösenord har skickats till din e-post.",
    description: `Ange din e-post
för att återställa ditt lösenord`
  },
  resetPasswordPage: {
    resetPassword: "Återställ lösenord",
    passwordPlaceholder: "Ange nytt lösenord",
    confirmPasswordPlaceholder: "Bekräfta nytt lösenord",
    success: {
      passwordReset: "Ditt lösenord har återställts!",
      backToLogin: "Tillbaka till inloggning"
    },
    login: "Logga in"
  },
  signupPage: {
    namePlaceholder: "Ange ditt namn",
    confirmPasswordPlaceholder: "Bekräfta ditt lösenord",
    signup: "Registrera dig",
    description: `Enter your details
to create a new account`
  },
  feedback: {
    input: "Skriv din feedback här...",
    success: "Feedback skickad",
    failed: "Misslyckades med att skicka feedback"
  },
  searchPromptPage: {
    subtitle: "Skriv i fritext vad du söker efter"
  },
  userDropdown: {
    changePassword: "Byt lösenord",
    logout: "Logga ut",
    pending: "Skickar e-post för återställning av lösenord...",
    success: "En länk för att återställa ditt lösenord har skickats till din e-post.",
    error: "Misslyckades med att skicka e-post för återställning av lösenord. Försök igen."
  },
  searchBar: {
    placeholder: "Sök Qura AI...",
    searchingStatus: "Söker i dokument",
    documentMatch: "Dokumentmatch",
    suggestions: "Förslag",
    searchHistory: "Sökhistorik",
    seeAll: "Visa allt",
    inDocumentSearch: "Sök i dokument"
  },
  searchHistory: {
    searchHistory: "Sökhistorik",
    documentsVisited: {
      one: "{{count}} besökt dokument",
      other: "{{count}} besökta dokument"
    }
  },
  searchResult: {
    openTag: "Öppna",
    noSearchResults: "Inga sökresultat hittades",
    tryUpdateFilters: "Försök att uppdatera dina filter eller sökfråga.",
    newSearch: "Ny sökning",
    searchMatch: "Matchar",
    pageNumberShort: "s.",
    openPdf: "Öppna",
    clickToExpand: "Klicka för att expandera",
    clickToMinimize: "Klicka för att minimera",
    previewLoading: "Laddar förhandsvisning...",
    previewError: "Misslyckades med att ladda förhandsvisning",
    noPreview: "Ingen förhandsvisning tillgänglig",
    feedbackSubmitted: "Tack för din feedback!",
    keywords: "Nyckelord"
  },
  searchDocumentPage: {
    title: "Sökresultat i dokument",
    downloadDocumentWithHighlights: "Ladda ner PDF med markeringar",
    downloadDocumentWithoutHighlights: "Ladda ner PDF utan markeringar",
    downloadHighlightedPages: "Ladda ner endast markeringar",
    printDocumentWithHighlights: "Skriv ut PDF med markeringar",
    printDocumentWithoutHighlights: "Skriv ut PDF utan markeringar",
    printHighlightedPages: "Skriv ut endast markeringar",
    downloadError: "Misslyckades med att ladda ner PDF",
    backToSearch: "Tillbaka till sökning",
    foundChunksCount: {
      one: "Hittade {{count}} relevant sida",
      other: "Hittade {{count}} relevanta sidor"
    },
    loadingChunks: "Laddar sidor...",
    noChunksFound: "Inga relevanta sidor hittades",
    referenceNotFound: "Dokumentet är för närvarande inte tillgängligt",
    citedDocuments: "Refererade dokument",
    citedByDocuments: "Refereras av dokument",
    relatedDocuments: "Relaterade dokument",
    deepSearch: "Förfina sökning"
  },
  documentMetadata: {
    legal_id: "Juridiskt ID",
    publication_date: "Publiceringsdatum",
    source: "Källa",
    no_source_link: "Källa ej tillgänglig"
  },
  filters: {
    filter: "Filter",
    filterByYear: "Filtrera efter år",
    applyFilters: "Tillämpa valda filter",
    from: "Från",
    to: "till",
    reset: "Återställ",
    legalSources: "Rättskällor",
    date: "Datum"
  },
  errors: {
    invalidCredentials: "Ogiltiga uppgifter",
    network: "Du är offline.",
    invalidSignupToken: "Din registreringslänk är ogiltig.",
    emailNotConfirmed: "E-post ej bekräftad.",
    emailAlreadyUsed: "E-post har redan använts med ett annat konto.",
    noResults: "Inga resultat hittades.",
    loadResourcesFailed: `Våra servrar är för närvarande nere.
Försök igen senare.`,
    resultChunkFailed: "Misslyckades med att läsa in förhandsgranskning i ResultChunk med react pdf dokument. Fel: ",
    unknown: "Ett okänt fel har inträffat.",
    invalidSearchId: "Kunde inte hitta sökningen på denna länk.",
    invalidEmail: "Din e-post är ogiltig.",
    invalidPassword: "Ditt lösenord är ogiltigt.",
    invalidUsername: "Ditt namn är ogiltigt.",
    document404Title: "Kunde inte hitta dokumentet",
    document404Description: "Skriv gärna i feedback rutan om detta borde vara en länk till ett dokument.",
    search404Title: "Kunde inte hitta sökningen",
    search404Description: "Skriv gärna i feedback rutan om detta inte borde ha hänt.",
    dataRole404Title: "Användaren är ogiltig",
    dataRole404Description: "Kontakta oss för att lösa detta.",
    errorPageTitle: "Okänt Fel",
    errorPageDescription: "Detta borde inte ha hänt. Skriv gärna i feedback rutan för att hjälpa oss förstå vad som gick fel.",
    searchFailed: "Misslyckades med att starta sökningen. Försök igen.",
    nameRequired: "Namn är obligatoriskt.",
    nameTooShort: "Namn måste vara minst tre tecken långt.",
    emailRequired: "E-post är obligatorisk.",
    emailInvalid: "Ogiltig e-postadress.",
    passwordRequired: "Lösenord är obligatoriskt.",
    passwordTooShort: "Lösenord måste vara minst 8 tecken långt.",
    passwordMismatch: "Lösenorden matchar inte."
  },
  common: {
    tryAgain: "Försök igen",
    passwordPlaceholder: "Ange ditt lösenord",
    emailPlaceholder: "Ange din e-post",
    login: "Logga in",
    loading: "Laddar...",
    password: "Lösenord",
    email: "E-post",
    confirmPassword: "Bekräfta lösenord",
    name: "Namn",
    month: [
      "januari",
      "februari",
      "mars",
      "april",
      "maj",
      "juni",
      "juli",
      "augusti",
      "september",
      "oktober",
      "november",
      "december"
    ],
    weekday: ["söndag", "måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag"],
    today: "Idag",
    yesterday: "Igår",
    tomorrow: "Imorgon",
    lastWeekday: [
      "I söndags",
      "I måndags",
      "I tisdags",
      "I onsdags",
      "I torsdags",
      "I fredags",
      "I lördags"
    ],
    backToHome: "Tillbaka till startsidan",
    loadMore: "Ladda fler"
  },
  layout: {
    allResults: "Alla resultat"
  },
  searchStatus: {
    searchScore: "Träffsäkerhet",
    documentsFound: "Hittade dokument",
    documentsSearched: "Sökta dokument",
    analyzingQuery: "Analyserar frågan",
    searchingDatabases: "Söker i databasen",
    rankingDocuments: "Rankar dokumenten",
    done: "Klar",
    completed: "Färdig",
    ongoing: "Pågående...",
    pending: "Väntar",
    error: "Misslyckades"
  },
  databaseInformation: {
    databaseInformation: "Databasinformation"
  },
  authFooter: "Qura använder AI för att hitta relevanta lagar, fall och förordningar för varje juridiskt sammanhang. Vi strävar efter att transformera juridisk forskning.",
  authFooterAlt1: "© 2024 Lawlabs AB ",
  authFooterAlt2: "Stockholm 111 20",
  examplePrompts: {
    swe: [
      "Hur ska köp av osäkra fordringar behandlas i mervärdesskattehänseende?",
      "Vad har brottslighetens art tillmätts för betydelse vid påföljdsval i rättspraxis?",
      "När kan en statlig myndighet ha klagorätt genom att företräda ett privaträttsligt intresse?"
    ],
    eu: [
      'How is "communication to the public" interpreted in article 3(19) of Directive 2001/29?',
      "How does judicial independence relate to the right to a fair trial?",
      'How to decide where a child is a "habitually resident"?'
    ],
    default: [
      "Hur ska köp av osäkra fordringar behandlas i mervärdesskattehänseende?",
      "Vad har brottslighetens art tillmätts för betydelse vid påföljdsval i rättspraxis?",
      "När kan en statlig myndighet ha klagorätt genom att företräda ett privaträttsligt intresse?"
    ]
  },
  onboardingPage: {
    title: "Kom igång med vår Quick Guide",
    welcomeMessage: "Välkommen till Qura!",
    learnHowToUseQura: "Lär dig hur du använder Qura.",
    description: "Vi ger dig tips på hur du utnyttjar vår kraftfulla AI-sökmotor och dess funktioner på bästa sätt.",
    viewQuickGuide: "Se Quick Guide",
    showMeLater: "Visa mig senare",
    haveMoreQuestions: "Har du fler frågor?",
    contactTeam: "Kontakta teamet",
    quickGuideUrl: "https://www.qura.law/quick-guide"
  },
  helpButton: {
    modalLabel: "Behöver du hjälp?",
    modalTitle: "Quick Guide",
    modalDescription: "Lär dig hur du utnyttjar Quras kraftfulla AI-sökmotor.",
    viewQuickGuide: "Se Quick Guide",
    haveMoreQuestions: "Har du fler frågor?",
    contactTeam: "Kontakta teamet",
    notificationTitle: "Quick Guide",
    notificationDescription: "Du hittar den alltid i frågetecknet."
  },
  relatedDocuments: {
    title: "Relaterade dokument",
    citedDocuments: "Citerade dokument",
    citedByDocuments: "Citerat av dokument"
  },
  NPSForm: {
    modalLabel: "Hjälp oss att bli bättre",
    modalQuestion: "Hur troligt är det att du skulle rekommendera Qura till en vän eller kollega?",
    dismissButton: "Påminn mig senare",
    lowerBound: "1 = Inte troligt",
    upperBound: "10 = Mycket troligt"
  }
}, u = {
  en: d,
  sv: l
}, i = createContext({ locale: "sv" }), h = () => {
  const e = useContext(i);
  if (!e)
    throw new Error("useTranslations must be used within a TranslationsProvider");
  return u[e.locale];
};
const AuthFooter = () => {
  const text = h();
  return /* @__PURE__ */ jsxs("p", { className: "z-10 text-xs text-medium mt-8 mx-2 text-center", children: [
    text.authFooterAlt1,
    /* @__PURE__ */ jsx("span", { className: "text-qura-neutral-balanced", children: text.authFooterAlt2 })
  ] });
};
const AuthInputCard = ({ children, onSubmit, className }) => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      onKeyDown: (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          if (onSubmit) {
            onSubmit();
          }
        }
      },
      className: `flex relative flex-col items-stretch ${className ?? ""}`,
      children
    }
  );
};
const AuthLogo = () => {
  return /* @__PURE__ */ jsx("div", { className: "flex justify-center mb-2 self-stretch", children: /* @__PURE__ */ jsx(TextLogo, { className: "self-center" }) });
};
const AuthTextInput = (props) => {
  const { label, isError, Icon: Icon2, RightIcon, onRightIconClick, ...inputProps } = props;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx("label", { className: `text-xs font-medium mb-1 ${isError ? "text-qura-red" : ""}`, children: label }),
    /* @__PURE__ */ jsxs(
      "div",
      {
        className: `bg-qura-neutral-ghost flex items-center px-2 py-[1px] rounded-md ${isError ? "border-qura-red border-2" : "border-transparent"}`,
        children: [
          /* @__PURE__ */ jsx(Icon2, {}),
          /* @__PURE__ */ jsx(
            "input",
            {
              ...inputProps,
              className: `focus:outline-none focus:border-none font-inter text-xs bg-transparent rounded-smaller w-full py-2 px-2 text-black leading-tight outline-0 placeholder-qura-neutral-jet/50`,
              style: {
                transition: "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s"
              }
            }
          ),
          RightIcon && /* @__PURE__ */ jsx("button", { onClick: onRightIconClick, children: /* @__PURE__ */ jsx(RightIcon, {}) })
        ]
      }
    )
  ] });
};
const AuthPasswordInput = (props) => {
  const { isError, placeholder, id, label, value, onChange, ...inputProps } = props;
  const [isPasswordHide, setPasswordHide] = useState(true);
  const handleRightIconClick = () => {
    setPasswordHide(!isPasswordHide);
  };
  return /* @__PURE__ */ jsx(
    AuthTextInput,
    {
      label,
      onChange,
      type: isPasswordHide ? "password" : "text",
      id,
      value,
      placeholder,
      RightIcon: isPasswordHide ? () => /* @__PURE__ */ jsx(EyeOff, { className: "w-[18px] h-[18px]" }) : () => /* @__PURE__ */ jsx(EyeIcon, { className: "w-[18px] h-[18px]" }),
      isError,
      onRightIconClick: handleRightIconClick,
      ...inputProps
    }
  );
};
const AuthSubmitButton = (props) => {
  const { showLoading, ...buttonProps } = props;
  const text = h();
  return /* @__PURE__ */ jsx(
    "button",
    {
      ...buttonProps,
      type: "submit",
      className: `group ${showLoading ? "animate-pulse" : ""} rounded-full p-[3px] bg-qura-neutral-ghost shadow-auth-submit `,
      title: showLoading ? text.common.loading : props.title,
      children: /* @__PURE__ */ jsx("p", { className: "text-black rounded-full py-[6px] bg-white group-hover:bg-qura-neutral-ghost", children: showLoading ? text.common.loading : props.children })
    }
  );
};
const selectTranslation = (obj, language = "default", fallback) => {
  return (obj == null ? void 0 : obj[language]) ?? (obj == null ? void 0 : obj["default"]) ?? (obj && Object.keys(obj).length === 1 ? obj[Object.keys(obj)[0]] : fallback);
};
const selectTranslationFromArray = (obj, language = "default", fallback) => {
  var _a, _b, _c;
  return ((_a = obj == null ? void 0 : obj[language]) == null ? void 0 : _a[0]) ?? ((_b = obj == null ? void 0 : obj["default"]) == null ? void 0 : _b[0]) ?? (obj && Object.keys(obj).length === 1 ? (_c = obj[Object.keys(obj)[0]]) == null ? void 0 : _c[0] : fallback);
};
export {
  AuthBackground,
  AuthContainerCard,
  AuthDescription,
  AuthError,
  AuthFooter,
  AuthInputCard,
  AuthLogo,
  AuthPasswordInput,
  AuthSubmitButton,
  AuthTextInput,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Badge,
  BlobImage,
  Button,
  ComboBox,
  CommandBar,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  DocumentSuggestionsDropdown,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  HistoryDropdown,
  Icon,
  Input,
  LoadingPage,
  NameCard,
  PdfViewer,
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverTrigger,
  RTEditor,
  RTViewer,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
  SearchBar,
  SearchBarDropdown,
  SearchBarDropdownOption,
  Separator,
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupAction,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInput,
  SidebarInset,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSkeleton,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
  SidebarSeparator,
  SidebarTrigger,
  Skeleton,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TextLogo,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  badgeVariants,
  buttonVariants,
  cn,
  selectTranslation,
  selectTranslationFromArray,
  useSidebar
};
