import { Header } from './Header';
import { PropsWithChildren } from 'react';
import { Sidebar } from './Sidebar';

type LayoutProps = PropsWithChildren & {
  hideSearchBar?: boolean;
  dispatch?: (query: string) => void;
  searchingInBundle?: boolean;
  bundleName?: string;
};

export const Layout = ({
  children,
  hideSearchBar,
  dispatch,
  searchingInBundle,
  bundleName,
}: LayoutProps) => {
  return (
    <div className="flex flex-col w-screen h-screen bg-sidebar">
      <Header
        hideSearchBar={hideSearchBar}
        dispatch={dispatch}
        searchingInBundle={searchingInBundle}
        bundleName={bundleName}
      />
      <div className="flex flex-1 min-h-0">
        <Sidebar />
        <div className="flex flex-col flex-1 bg-q-white-10 rounded-tl-lg">{children}</div>
      </div>
    </div>
  );
};
