import React, { useEffect, useState } from 'react';
import { parseAsInteger, useQueryState } from 'nuqs';

import { useTranslations } from '@qura/translations';
import { XIcon } from 'lucide-react';

// Fallback dates if we don't have them from the API:
const AVAILABLE_DATES = {
    from: 1900,
    to: new Date().getFullYear(),
};

export const YearFilter = () => {
    const t = useTranslations()

    // Query param for the "from" year (afterDate) and "to" year (beforeDate)
    // parseAsInteger will parse them from the URL query string into numbers (or null if invalid/absent).
    const [from, setFrom] = useQueryState('from', parseAsInteger);
    const [to, setTo] = useQueryState('to', parseAsInteger);

    // Fetch your available date range if needed
    const availableDates = AVAILABLE_DATES;

    // Local inputs so the user can partially type "19" or "202" etc. before it gets fully validated:
    const [fromInput, setFromInput] = useState(from?.toString() || '');
    const [toInput, setToInput] = useState(to?.toString() || '');

    useEffect(() => {
        if(fromInput.length === 4) {
            const parsedFrom = parseInt(fromInput);

            if(isNaN(parsedFrom) || parsedFrom < availableDates.from || parsedFrom > availableDates.to) {
                setFromInput((from ?? availableDates.from).toString());
                return
            }

            if(to && parsedFrom > to) {
                setToInput(parsedFrom.toString());
                setTo(parsedFrom);
            }

            setFrom(parsedFrom);
        }
    }, [fromInput]);


    useEffect(() => {
        if(toInput.length === 4) {
            const parsedTo = parseInt(toInput);

            if(isNaN(parsedTo) || parsedTo < availableDates.from || parsedTo > availableDates.to) {
                setToInput((to ?? availableDates.to).toString());
                return
            }

            if(from && parsedTo < from) {
                setFromInput(parsedTo.toString());
                setFrom(parsedTo);
            }

            setTo(parsedTo);
        }
    }, [toInput]);

    const isActive = from != null || to != null;

    useEffect(() => {
        // If "from" is out of range
        if (from != null && from < availableDates.from) {
            setFrom(availableDates.from);
            setFromInput(String(availableDates.from));
        }
        // If "to" is out of range
        if (to != null && to > availableDates.to) {
            setTo(availableDates.to);
            setToInput(String(availableDates.to));
        }
    }, [availableDates, from, to]);

    // Toggling on/off:
    // - If already active, clear the filter (from/to => null)
    // - If inactive, set defaults from availableDates
    const handleToggle = () => {
        if (isActive) {
            setFrom(null);
            setTo(null);
            setFromInput('');
            setToInput('');
        } else {
            const defaultFrom = availableDates.from;
            const defaultTo = availableDates.to;
            setFrom(defaultFrom);
            setTo(defaultTo);
            setFromInput(String(defaultFrom));
            setToInput(String(defaultTo));
        }
    };

    return (
        <div className="flex items-center px-5 h-8 text-xs">
            <button
                onClick={handleToggle}
                className={`flex items-center p-2 gap-2 hover:bg-qura-neutral-ghost rounded h-8 ${!isActive && 'w-full'
                    }`}
            >
                <div className="w-2 h-2 rounded-full border border-qura-neutral-jet flex justify-center items-center">
                    {isActive && <div className="w-1 h-1 rounded-full bg-qura-neutral-jet" />}
                </div>
                {!isActive && <p className="text-xs">{t.filters.filterByYear}</p>}
            </button>

            {isActive && (
                <div className="flex justify-between w-full pr-[34px]">
                    <div className="flex gap-2 items-center ">
                        <label htmlFor="fromYear" className="text-gray-600 w-fit">
                            {t.filters.from}
                        </label>
                        <input
                            className="text-gray-600 w-10  rounded-sm text-center bg-qura-neutral-ghost border focus:text-black focus:border-slate-200 focus:bg-transparent border-qura-neutral-silver outline-none no-spin"
                            type="number"
                            id="fromYear"
                            value={fromInput}
                            onChange={(e) => setFromInput(e.target.value.substring(0, 4))}
                        />
                        <label htmlFor="toYear" className="text-gray-600">
                            {t.filters.to}
                        </label>
                        <input
                            className="text-gray-600 w-10  rounded-sm text-center border bg-qura-neutral-ghost focus:text-black focus:border-slate-200 focus:bg-transparent border-qura-neutral-silver outline-none no-spin"
                            type="number"
                            id="toYear"
                            value={toInput}
                            onChange={(e) => setToInput(e.target.value.substring(0, 4))}
                        />
                    </div>

                    <button
                        onClick={handleToggle}
                        className="flex items-center p-2 gap-2 hover:bg-qura-neutral-ghost rounded h-8 ml-2"
                    >
                        <XIcon className="w-4 h-4" />
                    </button>
                </div>
            )}
        </div>
    );
};