import { Lang } from "@qura/shared-types";
import { cn } from "@qura/ui";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { parseAsArrayOf } from "nuqs";
import { parseAsString } from "nuqs";
import { useQueryState } from "nuqs";
import { PropsWithChildren, useMemo, useState } from "react";

const filterTags: FilterTag[] = [
  {
    id: "sfs",
    title: {
      sv: "Författningar",
    },
    subTags: [
      {
        id: "lagar",
        title: {
          sv: "Lagar",
        },
        subTags: [],
      },
      {
        id: "forordningar",
        title: {
          sv: "Förordningar",
        },
        subTags: [],
      },
      {
        id: "foreskrifter",
        title: {
          sv: "Föreskrifter",
        },
        subTags: [],
      }
    ],
  },
  {
    id: "forarbeten",
    title: {
      sv: "Förarbeten",
    },
    subTags: [
      {
        id: "prop",
        title: {
          sv: "Propositioner",
        },
        subTags: [],
      },
      {
        id: "sou",
        title: {
          sv: "SOU",
        },
        subTags: [],
      },
      {
        id: "bet",
        title: {
          sv: "Betänkanden",
        },
        subTags: [],
      },
      {
        id: "ds",
        title: {
          sv: "Departementsserien",
        },
        subTags: [],
      }
    ],
  },
  {
    id: "swe-case-law",
    title: {
      sv: "Rättspraxis",
    },
    subTags: [
      {
        id: "swe-case-law-general",
        title: {
          sv: "Allmän domstol",
        },
        subTags: [
          {
            id: "swe-case-law-hd",
            title: {
              sv: "Högsta domstolen",
            },
            subTags: [],
          },
          {
            id: "swe-case-law-hovratt",
            title: {
              sv: "Hovrätt",
            },
            subTags: [],
          },
          {
            id: "swe-case-law-tingsratt",
            title: {
              sv: "Tingsrätt",
            },
            subTags: [],
          }
        ],
      },
      {
        id: "swe-case-law-forvaltning",
        title: {
          sv: "Förvaltningsdomstolen",
        },
        subTags: [
          {
            id: "swe-case-law-hra",
            title: {
              sv: "Högsta förvaltningsdomstolen",
            },
            subTags: [],
          },
          {
            id: "swe-case-law-kammarratt",
            title: {
              sv: "Kammarrätt",
            },
            subTags: [],
          },
          {
            id: "swe-case-law-forvaltningsratt",
            title: {
              sv: "Förvaltningsrätt",
            },
            subTags: [],
          }
        ],
      },
      {
        id: "swe-case-law-special",
        title: {
          sv: "Specialdomstolar",
        },
        subTags: [
          {
            id: "swe-case-law-ad",
            title: {
              sv: "Arbetsdomstolen",
            },
            subTags: [],
          },
          {
            id: "swe-case-law-md",
            title: {
              sv: "Marknadsdomstolen",
            },
            subTags: [],
          },
          {
            id: "swe-case-law-pbu",
            title: {
              sv: "Patent- och besvärsrätten",
            },
            subTags: [],
          }
        ],
      }
    ],
  },
  {
    id: "myndigheter",
    title: {
      sv: "Myndigheter",
    },
    subTags: [
      {
        id: "skatteverket",
        title: {
          sv: "Skatteverket",
        },
        subTags: [],
      },
      {
        id: "finansinspektionen",
        title: {
          sv: "Finansinspektionen",
        },
        subTags: [],
      },
      {
        id: "kronofogden",
        title: {
          sv: "Kronofogdemyndigheten",
        },
        subTags: [],
      },
      {
        id: "jo",
        title: {
          sv: "Justitieombudsmannen",
        },
        subTags: [],
      }
    ],
  }
]

function transformTagSelection(
  transformTo: 'leaf-only' | 'parent-only' | 'all',
  selection: string[],
  tagNodes: FilterTag[],
): string[] {
  const newSelection = new Set<string>();

  const recursiveSelect = (tagNode: FilterTag, parentSelected?: boolean): boolean => {
    const isLeaf = tagNode.subTags.length === 0;

    const selected = selection.includes(tagNode.id);

    if (isLeaf) {
      if (transformTo == 'leaf-only') {
        if (parentSelected || selected) {
          newSelection.add(tagNode.id);
        }
      }

      return selected;
    }

    const subTagSelected = tagNode.subTags.map((sn) =>
      recursiveSelect(sn, selected || parentSelected),
    );
    const shouldBeSelected = subTagSelected.every((s) => s) || selected;

    if (!shouldBeSelected && transformTo === 'parent-only') {
      tagNode.subTags.forEach((sn, i) => {
        if (subTagSelected[i]) {
          newSelection.add(sn.id);
        }
      });
    }

    if (transformTo === 'all' && shouldBeSelected) {
      newSelection.add(tagNode.id);
    }

    return shouldBeSelected;
  };

  if (transformTo === 'parent-only') {
    tagNodes.forEach((tn) => {
      if (recursiveSelect(tn)) {
        newSelection.add(tn.id);
      }
    });
  } else {
    tagNodes.forEach((tn) => recursiveSelect(tn));
  }

  return [...newSelection].sort();
}

type FilterTag = {
  title: Partial<Record<Lang, string>>;
  id: string
  subTags: FilterTag[]
}

const FilterTagComponent = ({ tag, selection, setSelection, depth = 0 }: { selection: string[], setSelection: (selection: string[]) => void, tag: FilterTag, parent?: FilterTag, depth?: number }) => {
  const [isOpen, setIsOpen] = useState(false);  

  const leafChildren = useMemo(() => {
    const allTagsDownRecursive = (tag: FilterTag): string[] => 
        tag.subTags.length > 0 ? [...tag.subTags.map(allTagsDownRecursive).flat()] : [tag.id]

    return allTagsDownRecursive(tag)
  }, [tag]);

  const isSelected = leafChildren.some((s) => selection.includes(s));
  const allSelected = leafChildren.every((s) => selection.includes(s));

  const onClick = () => {  
    if(allSelected) {
      setSelection(selection.filter((s) => !leafChildren.includes(s)));
    } else {
      setSelection([...selection, ...leafChildren]);
    }
  }

  return (
    <div className={cn('flex flex-col', depth > 0 && 'ml-4')}>
      <div
        className='flex items-center cursor-pointer py-2'
      >
        <div className="w-7">
          {tag.subTags.length > 0 && (
            <ChevronRightIcon
              className={cn('w-5.5 h-5.5 p-1 transition-transform',
                isOpen && 'rotate-90')}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
        </div>
        <div className={cn('font-medium text-xs pl-2 pr-3 py-0.5 rounded-2xl border flex items-center gap-1',
          isSelected? 'bg-white' : 'bg-qura-neutral-snow'
        )} onClick={onClick}>
          <div className={cn('w-2 h-2 border rounded-full flex items-center justify-center', isSelected? 'border-black' : 'border-qura-neutral-silver')}>
            <div className={cn(
              'rounded-full',
              isSelected && 'bg-black',
              allSelected? "w-1 h-1" : "w-1 h-0.5",
            )} />
          </div>
          <p className="">{tag.title.sv}</p>
        </div>
      </div>
      {isOpen && tag.subTags.length > 0 && (
        <div className='flex flex-col'>
          {tag.subTags.map((subTag) => (
            <FilterTagComponent
              key={subTag.id}
              tag={subTag}
              depth={depth + 1}
              parent={tag}
              selection={selection}
              setSelection={setSelection}
            />
          ))}
        </div>
      )}
    </div>
  );
};


export const SourceFilter = () => {
  const [queryFilters, setQueryFilters] = useQueryState(
    'filters',
    parseAsArrayOf(parseAsString)
  );

  const selection = useMemo(() => transformTagSelection('leaf-only', queryFilters ?? [], filterTags), [queryFilters]);
  const setSelection = (selection: string[]) => {
    setQueryFilters(transformTagSelection('parent-only', selection, filterTags));
  }

  return (
    <div>
      {filterTags.map((tag) => (
        <FilterTagComponent key={tag.id} tag={tag} selection={selection} setSelection={setSelection} />
      ))}
    </div>
  )
}

export const FilterSection = ({ title, children }: PropsWithChildren<{ title: string }>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='flex flex-col bg-q-white-10 rounded-tl-lg py-6 px-4'>
      <div className='flex justify-between items-center cursor-pointer mb-4' onClick={() => setIsOpen(!isOpen)}>
        <p className='font'>{title}</p>
        <ChevronLeftIcon className={cn('w-6 h-6 p-1 transition-transform', isOpen && '-rotate-90')} />
      </div>
      {isOpen && children}
    </div>
  )
}
