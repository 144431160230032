import { logout, useAuthUser, useUserData } from '@qura/auth';
import { HistoryDialog } from '../HistoryDialog';
import {
  Avatar,
  AvatarFallback,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@qura/ui';
import { LogOutIcon, SearchIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { DatabaseInfoDialog } from '../DatabaseInfoDialog';
import { OnboardingDialog } from '../OnBoardingDialog';
import FeedbackPopover from '../FeedbackPopover';

type SidebarOptionProps = PropsWithChildren<{
  onClick: () => void;
  tooltip: string;
}>;
const SidebarOption = ({ children, onClick, tooltip }: SidebarOptionProps) => {
  return (
    <div className="group">
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger onClick={onClick}>{children}</TooltipTrigger>
          <TooltipContent side="right">{tooltip}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export const Sidebar = () => {
  const userData = useUserData();
  const authUser = useAuthUser();
  const isLoading = false;
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col w-11 self-stretch items-center justify-between py-2">
      <div className="flex flex-col gap-2">
        <SidebarOption tooltip="Search" onClick={handleSearch}>
          <div className="flex items-center justify-center w-8 h-8 group-hover:bg-q-white-10 rounded-md bg-q-black-50">
            <SearchIcon className="w-5 h-5 text-white group-hover:text-black" />
          </div>
        </SidebarOption>

        <HistoryDialog />
      </div>
      <div className="flex flex-col items-center gap-2">
        <FeedbackPopover />
        <OnboardingDialog />
        <DatabaseInfoDialog />
        <div className="h-1" />
        <DropdownMenu>
          <DropdownMenuTrigger disabled={isLoading} asChild>
            <Avatar className="h-8 w-8 cursor-pointer">
              {isLoading ? (
                <AvatarFallback className="animate-pulse bg-muted" />
              ) : (
                <AvatarFallback className="text-primary">
                  {authUser?.displayName?.slice(0, 2).toUpperCase()}
                </AvatarFallback>
              )}
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent side="right" align="end" className="w-[240px]">
            <DropdownMenuLabel>
              <div className="flex flex-col gap-3">
                <div className="flex flex-col">
                  <span className="text-sm font-medium">{authUser?.displayName}</span>
                  <span className="text-sm font-medium text-muted-foreground">
                    {userData?.company.name}
                  </span>
                </div>
                <span className="text-xs text-muted-foreground/60">{authUser?.email}</span>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={logout} className="cursor-pointer">
              <LogOutIcon className="mr-2 h-4 w-4" />
              <span>Log out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
