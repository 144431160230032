import { useParams } from 'react-router-dom';
import { useBundle } from '../../services/firebase/actions/useBundle';
import { parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useInBundleSearch } from '../../services/firebase/actions/useInBundleSearch';
import { ResizablePanelGroup, RTEditorRef, selectTranslationFromArray } from '@qura/ui';
import { LeftPanel } from './LeftPanel';
import { MiddlePanel } from './MiddlePanel';
import { PdfViewerRef } from '@qura/ui';
import { useRef } from 'react';
import { RightPanel } from './RightPanel';
import { useState } from 'react';
import { useMemo } from 'react';
import { MdTaggedPosition, PdfOffsetPosition, TocNode } from '@qura/shared-types';
import { Layout } from '../../components/Layout/Layout';
import {
  fetchTocNodeFromReferenceId,
  useTocNodeFromReferenceId,
} from '../../services/firebase/actions/useTocNodes';
const language = 'sv';

export type BundleFilePosition = PdfOffsetPosition | MdTaggedPosition;

const LoadingPage = () => {
  return (
    <div className="bg-gray-100 h-full w-full flex flex-col items-center justify-center gap-2 pr-4">
      <div className="animate-spin w-8 h-8 border-4 border-gray-300 border-t-gray-500 rounded-full"></div>
      <div className="text-gray-500">Loading document</div>
    </div>
  );
};

export const BundleViewerPage = () => {
  const { bundleId } = useParams();
  const { data: bundle } = useBundle(bundleId);
  const [query] = useQueryState('query', parseAsString.withDefault(''));
  const [searchId] = useQueryState('sid');

  const [selectedDocumentIdx, setSelectedDocumentIdx] = useQueryState(
    'documentIdx',
    parseAsInteger.withDefault(0),
  );
  const [selectedFileIdx, setSelectedFileIdx] = useQueryState(
    'fileIdx',
    parseAsInteger.withDefault(0),
  );

  const selectedDocument = useMemo(
    () => bundle?.documents[selectedDocumentIdx],
    [bundle, selectedDocumentIdx],
  );

  const selectedFile = useMemo(
    () => selectedDocument?.files[selectedFileIdx],
    [selectedDocument, selectedFileIdx],
  );

  const documentMetadata = useMemo(() => {
    if (!selectedDocument) return null;
    return selectedDocument.metadata;
  }, [selectedDocument]);

  const { inBundleSearchDoc: SearchDoc, dispatch } = useInBundleSearch(
    searchId ? `${searchId}_${bundleId}` : undefined,
    query,
    bundleId,
  );

  const highlights = useMemo(() => {
    return SearchDoc?.hits
      ?.filter((hit) => hit.document_pk === selectedDocument?.document_id)
      .flatMap((hit) => hit.block_hits.map((blockHit) => blockHit.highlight));
  }, [SearchDoc, selectedDocument]);

  const [selectedTocNode, setSelectedTocNode] = useState<TocNode | null>(null);

  const pdfViewerRef = useRef<PdfViewerRef>(null);
  const RTEditorRef = useRef<RTEditorRef>(null);

  const scrollToFilePosition = (position: BundleFilePosition) => {
    if (position.position_type === 'md_tag') {
      RTEditorRef.current?.scrollToSection(position.reference_ids[0]);
    } else if (position.position_type === 'offset') {
      pdfViewerRef.current?.scrollToPosition({
        pageIndex: position.page_index,
        offset: position.offset,
      });
    }
  };

  return (
    <Layout
      bundleName={selectTranslationFromArray(bundle?.names, language)}
      searchingInBundle={true}
      dispatch={dispatch}>
      <ResizablePanelGroup direction="horizontal">
        {!bundle ? (
          <LoadingPage />
        ) : (
          <>
            <LeftPanel
              bundle={bundle}
              searchDoc={SearchDoc ?? undefined}
              language={language}
              selectedDocumentIdx={selectedDocumentIdx}
              selectedFileIdx={selectedFileIdx}
              setSelectedDocumentIdx={setSelectedDocumentIdx}
              setSelectedFileIdx={setSelectedFileIdx}
              setSelectedTocNode={setSelectedTocNode}
              scrollToFilePosition={scrollToFilePosition}
            />
            <MiddlePanel
              bundleId={bundleId}
              selectedDocument={selectedDocument}
              selectedFile={selectedFile}
              language={language}
              scrollToFilePosition={scrollToFilePosition}
              RTEditorRef={RTEditorRef}
              pdfViewerRef={pdfViewerRef}
              selectedTocNode={selectedTocNode}
              highlights={highlights}
              setSelectedTocNode={setSelectedTocNode}
            />
            <RightPanel
              selectedTocNode={selectedTocNode ?? undefined}
              selectedDocument={selectedDocument ?? undefined}
              bundle={bundle}
              language={language}
            />
          </>
        )}
      </ResizablePanelGroup>
    </Layout>
  );
};
