import { collection, orderBy, getDocs, doc, getDoc, query } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { bundleSchema } from '@qura/shared-types';
import { useQuery } from '@tanstack/react-query';
import { BUNDLE_COLLECTION } from '../../../constants';

export const getBundle = async (bundleId: string | undefined) => {
  if (!bundleId) return null;

  const bundleDocPromise = getDoc(doc(db, BUNDLE_COLLECTION, bundleId));
  const documentsSnapshotPromise = getDocs(
    query(collection(db, BUNDLE_COLLECTION, bundleId, 'documents'), orderBy('order', 'asc')),
  );
  const annexesSnapshotPromise = getDocs(
    query(collection(db, BUNDLE_COLLECTION, bundleId, 'annexes'), orderBy('order', 'asc')),
  );

  const [bundleDoc, documentsSnapshot, annexesSnapshot] = await Promise.all([
    bundleDocPromise,
    documentsSnapshotPromise,
    annexesSnapshotPromise,
  ]);

  if (!bundleDoc.exists()) return null;
  const bundleData = bundleDoc.data();

  const documentsPromises = documentsSnapshot.docs.map(async (docSnapshot) => {
    const document = docSnapshot.data();
    const filesSnapshot = await getDocs(
      query(
        collection(db, BUNDLE_COLLECTION, bundleId, 'documents', docSnapshot.id, 'files'),
        orderBy('order', 'asc'),
      ),
    );
    const filesPromises = filesSnapshot.docs.map(async (fileSnapshot) => {
      const file = { ...fileSnapshot.data(), toc: [], file_id: fileSnapshot.id };
      return file;
    });

    const files = await Promise.all(filesPromises);

    return {
      ...document,
      files,
      document_id: docSnapshot.id,
    };
  });

  const [documents, annexes] = await Promise.all([
    Promise.all(documentsPromises),
    Promise.all(annexesSnapshot.docs.map((doc) => doc.data())),
  ]);

  // Combine all data
  const bundle = {
    ...bundleData,
    documents,
    annexes,
    bundle_id: bundleId,
  };

  return bundleSchema.parse(bundle);
};

export const useBundle = (bundleId: string | undefined) => {
  return useQuery({
    queryKey: ['bundle', bundleId],
    queryFn: () => getBundle(bundleId),
    enabled: !!bundleId,
  });
};
