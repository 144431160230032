import { Lang, UserData } from '@qura/shared-types';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { documentGroupCountSchema } from '@qura/shared-types';
import { useQuery } from '@tanstack/react-query';
import { useUserData } from '@qura/auth';

export type DatabaseTreeNode = {
  id: string;
  label: Partial<Record<Lang, string>>;
  order: number;
  children: DatabaseTreeNode[];
  parent: DatabaseTreeNode | null;
  count: number;
  tags: string[];
};

export async function getDatabaseInfo(userData: UserData | null) {
  if (!userData) {
    return [];
  }

  const dbInfoRef = collection(db, 'db_info');

  const q = query(
    dbInfoRef,
    where('access_groups', 'array-contains-any', userData.user_readable.data_roles),
  );

  const snapshot = await getDocs(q);

  const docs = snapshot.docs.map((doc) => documentGroupCountSchema.parse(doc.data()));

  const nodes = new Map<string, DatabaseTreeNode>();

  for (const doc of docs) {
    let lastNode: DatabaseTreeNode | null = null;

    for (const path of doc.path) {
      const node = nodes.get(path.id);

      if (!node) {
        const newNode: DatabaseTreeNode = {
          id: path.id,
          label: path.label,
          order: path.order,
          children: [],
          parent: lastNode,
          count: 0,
          tags: [],
        };

        nodes.set(path.id, newNode);

        if (lastNode) {
          lastNode.children.push(newNode);
        }
      }

      lastNode = nodes.get(path.id) ?? null;
    }

    if (lastNode) {
      lastNode.count = doc.count;
      lastNode.tags = [doc.tag];
    }
  }

  const alignParentToChildren = (node: DatabaseTreeNode) => {
    if (node.children.length > 0) {
      const res = node.children.map(alignParentToChildren);
      node.count = res.reduce((acc, n) => acc + n.count, 0);
      node.tags = res.map((n) => n.tags).flat();
    }

    return node;
  };

  const rootNodes = Array.from(nodes.values()).filter((node) => node.parent === null);

  for (const node of rootNodes) {
    alignParentToChildren(node);
  }

  return rootNodes;
}

export const useDatabaseInfo = () => {
  const userData = useUserData();

  return useQuery({
    queryKey: ['database_info'],
    queryFn: () => getDatabaseInfo(null),
    enabled: !!userData,
  });
};
