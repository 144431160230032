import { ArrowUpRightIcon, CheckIcon, MinusIcon, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import {
  MetadataBundleRelation,
  MetadataContainer,
  MetadataDocumentRelation,
  MetadataList,
} from '@qura/shared-types';
import {
  cn,
  selectTranslation,
  TooltipContent,
  TooltipTrigger,
  Tooltip,
  TooltipProvider,
} from '@qura/ui';
import { useBundleWithBundleId } from '../../services/firebase/actions/useBundleWithId';
import { useDocumentWithDocumentId } from '../../services/firebase/actions/useDocumentWithId';

type MetadataCardProps = {
  name?: string;
  metadata: Record<string, MetadataContainer>;
  icon?: React.ReactNode;
};

type MetadataDictProps = {
  metadata: Record<string, MetadataContainer>;
};

type MetadataValueProps = {
  label?: MetadataContainer['label'];
  entry: MetadataContainer['entry'];
};

const Label = ({ label }: { label?: string }) => {
  return <div className="text-sm truncate">{label}</div>;
};

const EntryContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'rounded-lg shadow-sm bg-q-gray-60 p-2 items-center flex border border-q-white-10 w-fit max-w-full',
        className,
      )}>
      {children}
    </div>
  );
};

const EntryContainerWithLabel = ({
  label,
  children,
  className,
}: {
  label?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className="flex flex-col gap-1">
      {label && <Label label={label} />}
      <EntryContainer className={className}>{children}</EntryContainer>
    </div>
  );
};

const RelationBundle = ({ entry }: { entry: MetadataBundleRelation }) => {
  const bundle = useBundleWithBundleId(entry.bundle_id);
  return (
    <span className="truncate text-sm">
      {bundle
        ? selectTranslation(bundle.data?.names)
        : selectTranslation(entry.fallback_label) || <MinusIcon className="w-4 h-4" />}
    </span>
  );
};

const RelationDocument = ({ entry }: { entry: MetadataDocumentRelation }) => {
  const document = useDocumentWithDocumentId(entry.document_id);
  return (
    <span className="truncate text-sm">
      {document.data
        ? selectTranslation(document.data?.names)
        : selectTranslation(entry.fallback_label) || <MinusIcon className="w-4 h-4" />}
    </span>
  );
};

const ListEntry = ({ entry, label }: { entry: MetadataList; label?: Record<string, string> }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const displayItems = isExpanded ? entry.items : entry.items.slice(0, 3);
  const hasMore = entry.items.length > 3;

  return (
    <div className="flex flex-col gap-1">
      {label && <Label label={selectTranslation(label)} />}
      <div className="flex flex-col gap-1">
        {displayItems.map((item, i) => (
          <MetadataValue key={i} entry={item} />
        ))}
      </div>
      {hasMore && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="pl-2 text-xs text-gray-500 hover:text-gray-700 text-left italic">
          {isExpanded ? 'Show less' : `Show ${entry.items.length - 3} more`}
        </button>
      )}
    </div>
  );
};

const MetadataValue = ({ entry, label = {} }: MetadataValueProps) => {
  // Relations
  if (entry.metadata_type === 'bundle_relation') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)}>
        <RelationBundle entry={entry} />
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'document_relation') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)}>
        <RelationDocument entry={entry} />
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'file_relation') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)}>
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <span className="truncate text-sm">{selectTranslation(entry.fallback_label)}</span>
            </TooltipTrigger>
            <TooltipContent side="bottom">{selectTranslation(entry.fallback_label)}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'label_relation') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)}>
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <span className="truncate text-sm">{selectTranslation(entry.label)}</span>
            </TooltipTrigger>
            <TooltipContent side="bottom">{selectTranslation(entry.label)}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'identifier') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)} className="h-8">
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <span className="truncate text-sm w-fit">
                {entry.prefix && selectTranslation(entry.prefix)}
                {entry.value}
                {entry.suffix && selectTranslation(entry.suffix)}
              </span>
            </TooltipTrigger>
            <TooltipContent side="bottom">
              {entry.prefix && selectTranslation(entry.prefix)}
              {entry.value}
              {entry.suffix && selectTranslation(entry.suffix)}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'text_value') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)} className="h-8">
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <span className="truncate text-sm block">{selectTranslation(entry.value)}</span>
            </TooltipTrigger>
            <TooltipContent side="bottom">{selectTranslation(entry.value)}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'long_text') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)}>
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <span className="text-sm line-clamp-3 hover:line-clamp-none">
                {selectTranslation(entry.value)}
              </span>
            </TooltipTrigger>
            <TooltipContent side="bottom">{selectTranslation(entry.value)}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'number') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)} className="h-8">
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <span className="truncate text-sm w-fit">{entry.value}</span>
            </TooltipTrigger>
            <TooltipContent side="bottom">{entry.value}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'boolean') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)} className="h-8 w-8">
        {entry.value ? <CheckIcon className="w-4 h-4" /> : <XIcon className="w-4 h-4" />}
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'date') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)} className="h-8">
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <span className="truncate text-sm">
                {entry.year}
                {entry.month && entry.year && '-'}
                {entry.month?.toString().padStart(2, '0')}
                {entry.day && entry.month && '-'}
                {entry.day?.toString().padStart(2, '0')}
              </span>
            </TooltipTrigger>
            <TooltipContent side="bottom">
              {entry.year}
              {entry.month && entry.year && '-'}
              {entry.month?.toString().padStart(2, '0')}
              {entry.day && entry.month && '-'}
              {entry.day?.toString().padStart(2, '0')}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'link') {
    return (
      <EntryContainerWithLabel
        label={selectTranslation(label)}
        className="h-8 hover:bg-q-gray-40 hover:outline-q-black-10 hover:outline hover:border">
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <a
                href={selectTranslation(entry.url)}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm flex items-center w-fit">
                <span className="truncate text-sm">{selectTranslation(entry.caption)}</span>
                <div className="flex-grow" />
                <ArrowUpRightIcon className="w-4 h-4" />
              </a>
            </TooltipTrigger>
            <TooltipContent side="bottom">{selectTranslation(entry.caption)}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'status') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)} className="h-8">
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger className="w-full">
              <span className="truncate text-sm">{selectTranslation(entry.status)}</span>
            </TooltipTrigger>
            <TooltipContent side="bottom">{selectTranslation(entry.status)}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'null') {
    return (
      <EntryContainerWithLabel label={selectTranslation(label)}>
        <MinusIcon className="w-4 h-4" />
      </EntryContainerWithLabel>
    );
  } else if (entry.metadata_type === 'list') {
    return <ListEntry entry={entry} label={label} />;
  }
};

const MetadataDict = ({ metadata }: MetadataDictProps) => {
  const metadataContainers = useMemo(() => {
    return Object.values(metadata)
      .filter((m) => !m.hidden)
      .sort((a, b) => a.order - b.order);
  }, [metadata]);

  if (!metadataContainers.length) {
    return null;
  }

  return (
    <div className="">
      <div className="flex flex-col gap-4 self-stretch">
        {metadataContainers.map((field, index) => (
          <MetadataValue key={index} entry={field.entry} label={field.label} />
        ))}
      </div>
    </div>
  );
};

export const MetadataCard = ({ name, metadata, icon }: MetadataCardProps) => {
  const hasData =
    metadata &&
    Object.keys(metadata)
      .map((key) => metadata[key])
      .some((value) => value !== null && value !== undefined && !value.hidden);

  return (
    <>
      <div className="flex w-full flex-col gap-4 p-4 bg-gray-50 rounded-lg">
        {(icon || name) && (
          <div className="flex flex-row items-center border-b border-gray-200">
            {icon && <div className="w-6 h-6">{icon}</div>}
            {name && <div className="text-md font-normal pb-2 line-clamp-2">{name}</div>}
          </div>
        )}
        {hasData ? (
          <MetadataDict metadata={metadata} />
        ) : (
          <div className="text-sm text-gray-500 italic">No section data</div>
        )}
      </div>
    </>
  );
};
