import * as Sentry from '@sentry/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BundleViewerPage } from './pages/BundleViewerPage/BundleViewerPage';
import { NewSearchPage } from './pages/NewSearchPage/NewSearchPage';
import { SearchResultPage } from './pages/SearchResultPage/SearchResultPage';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes = () => {
  return (
    <SentryRoutes>
      <Route index path="/" element={<NewSearchPage />} />
      <Route path="/search/:searchId?" element={<SearchResultPage />} />
      <Route path="/bundle/:bundleId?" element={<BundleViewerPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );
};
