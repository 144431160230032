import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { inBundleSearchDocumentSchema, inBundleSearchOutputSchema } from '@qura/shared-types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getToken } from '@qura/auth';
import {
  IN_BUNDLE_SEARCH_COLLECTION,
  BACKEND_API_URLS,
  env
} from '../../../constants';



const dispatchInBundleSearch = async (params: {
  searchId?: string;
  bundleId: string;
  query: string;
}) => {
  const token = (await getToken()) || '';
  const response = await axios.post(
    `${BACKEND_API_URLS[env.IN_BUNDLE_SEARCH_BACKEND]}/search/in-bundle`,
    {
      query: params.query,
      bundle_pk: params.bundleId,
      in_bundle_search_id: params.searchId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return inBundleSearchOutputSchema.parse(response.data);
};

export const usePostInBundleSearch = () => {
  return useMutation({
    mutationFn: dispatchInBundleSearch,
  });
};

export const getInBundleSearchDoc = async (inBundleSearchId: string | undefined) => {
  if (!inBundleSearchId) return null;

  const bundleDoc = await getDoc(doc(db, IN_BUNDLE_SEARCH_COLLECTION, inBundleSearchId));

  if (!bundleDoc.exists()) return null;

  const data = {
    ...bundleDoc.data(),
    hits: bundleDoc.data().hits.map((hit: { block_hits: Array<{ highlight: string }> }) => ({
      ...hit,
      block_hits: hit.block_hits.slice(0, 5).map((blockHit: { highlight: string }) => ({
        ...blockHit,
        highlight: blockHit.highlight,
      })),
    })),
  };

  return inBundleSearchDocumentSchema.parse(data);
};

export const useInBundleSearchDoc = (inBundleSearchId: string | undefined) => {
  return useQuery({
    queryKey: ['inBundleSearchDoc', inBundleSearchId],
    queryFn: () => getInBundleSearchDoc(inBundleSearchId),
    enabled: !!inBundleSearchId,
  });
};

export const useInBundleSearch = (
  searchId: string | undefined,
  query: string,
  bundleId?: string,
  onComplete?: () => void,
) => {
  const [inBundleSearchId, setInBundleSearchId] = useState<string | undefined>(searchId);
  const {
    mutate: postInBundleSearch,
    isPending: isPostingInBundleSearch,
    isError: isErrorPostingInBundleSearch,
  } = usePostInBundleSearch();
  const {
    data: inBundleSearchDoc,
    isLoading: isLoadingInBundleSearchDoc,
    isError: isErrorInBundleSearchDoc,
    refetch,
  } = useInBundleSearchDoc(inBundleSearchId);

  const dispatch = (newQuery: string, searchId?: string) => {
    if (!bundleId) return;
    postInBundleSearch(
      {
        query: newQuery,
        bundleId: bundleId,
        searchId: searchId,
      },
      {
        onSuccess: (data) => {
          setInBundleSearchId(data.in_bundle_search_id);
        },
      },
    );
  };

  useEffect(() => {
    if (!query || !bundleId) return;

    if (!isLoadingInBundleSearchDoc && !inBundleSearchDoc) {
      dispatch(query, inBundleSearchId);
    }
  }, [isLoadingInBundleSearchDoc]);

  useEffect(() => {
    if (!inBundleSearchId) return;
    const unsubscribe = onSnapshot(
      doc(db, IN_BUNDLE_SEARCH_COLLECTION, inBundleSearchId),
      (doc) => {
        if (doc.exists()) {
          refetch();
        }
      },
    );
    return () => unsubscribe();
  }, [inBundleSearchId, refetch]);

  useEffect(() => {
    if (onComplete && inBundleSearchDoc?.processing_state === 'completed') {
      onComplete();
    }
  }, [inBundleSearchDoc]);

  return {
    inBundleSearchDoc,
    isLoading: isLoadingInBundleSearchDoc || isPostingInBundleSearch,
    isError: isErrorInBundleSearchDoc || isErrorPostingInBundleSearch,
    dispatch,
  };
};
