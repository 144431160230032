import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { bundleSchema } from '@qura/shared-types';
import { useQuery } from '@tanstack/react-query';
import { BUNDLE_COLLECTION } from '../../../constants';

export const getBundleWithBundleId = async (bundleId: string | undefined) => {
  if (!bundleId) return null;

  const bundleQuery = query(collection(db, BUNDLE_COLLECTION), where('bundle_id', '==', bundleId));

  const bundleSnapshot = await getDocs(bundleQuery);

  if (bundleSnapshot.empty) return null;

  if (bundleSnapshot.docs.length > 1) {
    console.warn(`Found multiple bundles with bundle_id: ${bundleId}`);
  }

  if (bundleSnapshot.docs.length === 0) return null;

  const bundleDoc = bundleSnapshot.docs[0];
  return bundleSchema.parse(bundleDoc.data());
};

export const useBundleWithBundleId = (bundleId: string | undefined) => {
  return useQuery({
    queryKey: ['bundle', bundleId],
    queryFn: () => getBundleWithBundleId(bundleId),
    enabled: !!bundleId,
  });
};
