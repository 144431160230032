import { ArrowUpRightIcon } from 'lucide-react';

export const ExampleQuery = ({ query, onClick }: { query: string; onClick: () => void }) => {
  return (
    <button
      className="flex gap-2 items-center bg-q-gray-20 hover:bg-q-gray-10 transition-colors rounded-full p-2 px-4"
      onClick={onClick}>
      <span className="text-sm font-medium text-q-black-10 truncate">{query}</span>
      <ArrowUpRightIcon className="w-5 h-5 flex-shrink-0" />
    </button>
  );
};
