import { parseAsString, useQueryState } from 'nuqs';
import { useNavigate } from 'react-router-dom';
import { TextLogo } from '@qura/ui';
import { Plus } from 'lucide-react';
import { Layout } from '../../components/Layout/Layout';
import { useExampleQueries } from '../../services/firebase/actions/useExampleQueries';
import { useFilters } from '../../services/firebase/actions/useFilters';
import { FilterTag } from '../../components/FilterTag';
import { ExampleQuery } from '../../components/ExampleQuery';
import { SearchBar } from '../../components/SearchBar';

export const NewSearchPage = () => {
  const [query, setQuery] = useQueryState('query', parseAsString);
  const navigate = useNavigate();
  const { data: exampleQueries } = useExampleQueries(3);
  const { data: filters } = useFilters(5);

  const runSearch = (query: string) => {
    navigate(`/search?query=${query}`);
  };

  return (
    <Layout hideSearchBar>
      <div className="flex flex-1 items-center justify-center  pr-4">
        <div className="w-full max-w-4xl flex flex-col items-center gap-4">
          <div className="w-48 flex justify-center">
            <TextLogo size='md'/>
          </div>
          <div className="w-full">
            <SearchBar
              query={query || ''}
              setQuery={setQuery}
              runSearch={() => runSearch(query || '')}
              selectedFilters={[]}
            />

            <div className="flex flex-col gap-3">

              <div className="flex flex-col gap-3 mt-8">
                <p className="text-sm text-muted-foreground">
                  Ask Qura a question in natural language
                </p>
                <div className="flex flex-col gap-2 items-start">
                  {exampleQueries?.map((example) => (
                    <ExampleQuery
                      key={example.query}
                      query={example.query}
                      onClick={() => {
                        runSearch(example.query);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
