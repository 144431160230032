import { collectionGroup, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { documentSchema } from '@qura/shared-types';
import { useQuery } from '@tanstack/react-query';
import { DOCUMENT_COLLECTION } from '../../../constants';

export const getDocumentWithDocumentId = async (documentId: string | undefined) => {
  if (!documentId) return null;

  try {
    console.log('documentId', documentId);

    const documentQuery = query(
      collectionGroup(db, DOCUMENT_COLLECTION),
      where('document_id', '==', documentId),
    );

    const documentSnapshot = await getDocs(documentQuery);

    if (documentSnapshot.empty) return null;

    if (documentSnapshot.docs.length > 1) {
      console.warn(`Found multiple bundles with document_id: ${documentId}`);
    }

    if (documentSnapshot.docs.length === 0) return null;

    const documentDoc = documentSnapshot.docs[0];
    return documentSchema.parse(documentDoc.data());
  } catch (error) {
    console.error('Error getting document with documentId', error);
    return null;
  }
};

export const useDocumentWithDocumentId = (documentId: string | undefined) => {
  return useQuery({
    queryKey: ['document', documentId],
    queryFn: () => getDocumentWithDocumentId(documentId),
    enabled: !!documentId,
  });
};
