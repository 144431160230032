import { AuthWrapper, useUserData } from '@qura/auth';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'core-js';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { auth, db } from './services/firebase/firebaseConfig';
import { queryClient } from './services/react-query/queryClient';
import { env } from './constants';
import { TranslationsProvider } from '@qura/translations';
import { NuqsAdapter } from 'nuqs/adapters/react-router/v6';
import { parseAsString, useQueryState } from 'nuqs';
import { useEffect } from 'react';

function AppWithAuth() {
  const user = useUserData()
  const [lang, setLang] = useQueryState('lang', parseAsString);

  useEffect(() => {
    if (!user) return
    if (lang !== null) return

    setLang(lang ?? user.user_editable.language)
  }, [user, lang])

  return (
    <AppRoutes />
  )
}

function AppWithNuqs() {
  const [lang] = useQueryState('lang', parseAsString);
  const locale = lang === 'sv' ? 'sv' : 'en';

  return (
    <TranslationsProvider locale={locale}>
      <QueryClientProvider client={queryClient}>
        <AuthWrapper db={db} auth={auth} disableAuth={env.DISABLE_AUTH} locale={locale}>
          <AppWithAuth />
        </AuthWrapper>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider >
    </TranslationsProvider>
  )
}

function App() {

  return (
    <BrowserRouter>
      <NuqsAdapter>
        <AppWithNuqs />
      </NuqsAdapter>
    </BrowserRouter>
  );
}

export default App;
