import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getToken } from '@qura/auth';
import { crossBundleSearchDocumentSchema, crossBundleSearchOutputSchema } from '@qura/shared-types';
import { CROSS_BUNDLE_SEARCH_COLLECTION, BACKEND_API_URLS, env } from '../../../constants';

const dispatchCrossBundleSearch = async (params: { query: string }) => {
  const token = (await getToken()) || '';
  console.log('dispatching cross bundle search', params.query);
  const response = await axios.post(
    `${BACKEND_API_URLS[env.CROSS_BUNDLE_SEARCH_BACKEND]}/search/cross-bundle`,
    {
      query: params.query,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return crossBundleSearchOutputSchema.parse(response.data);
};

export const usePostCrossBundleSearch = () => {
  return useMutation({
    mutationFn: dispatchCrossBundleSearch,
    onSuccess: () => {},
  });
};

export const getCrossBundleSearchDoc = async (crossBundleSearchId: string | undefined) => {
  if (!crossBundleSearchId) return null;

  const bundleDoc = await getDoc(doc(db, CROSS_BUNDLE_SEARCH_COLLECTION, crossBundleSearchId));

  if (!bundleDoc.exists()) return null;

  const data = { ...bundleDoc.data(), hits: bundleDoc.data()?.hits.slice(0, 20) };
  return crossBundleSearchDocumentSchema.parse(data);
};

export const useCrossBundleSearchDoc = (crossBundleSearchId: string | undefined) => {
  return useQuery({
    queryKey: ['crossBundleSearchDoc', crossBundleSearchId],
    queryFn: () => getCrossBundleSearchDoc(crossBundleSearchId),
    enabled: !!crossBundleSearchId,
  });
};

export const useCrossBundleSearch = (searchId: string | undefined, query: string) => {
  const [crossBundleSearchId, setCrossBundleSearchId] = useState<string | undefined>(searchId);
  const {
    mutate: postCrossBundleSearch,
    isPending: isPostingCrossBundleSearch,
    isError: isErrorPostingCrossBundleSearch,
  } = usePostCrossBundleSearch();
  const {
    data: crossBundleSearchDoc,
    isLoading: isLoadingCrossBundleSearchDoc,
    isError: isErrorCrossBundleSearchDoc,
    refetch,
  } = useCrossBundleSearchDoc(crossBundleSearchId);

  const dispatch = (newQuery: string) => {
    postCrossBundleSearch(
      {
        query: newQuery,
      },
      {
        onSuccess: (data) => {
          setCrossBundleSearchId(data.cross_bundle_search_id);
        },
      },
    );
  };

  useEffect(() => {
    if (!query) return;

    if (!isLoadingCrossBundleSearchDoc && !crossBundleSearchDoc) {
      dispatch(query);
    }
  }, [isLoadingCrossBundleSearchDoc]);

  useEffect(() => {
    if (!crossBundleSearchId) return;
    const unsubscribe = onSnapshot(
      doc(db, CROSS_BUNDLE_SEARCH_COLLECTION, crossBundleSearchId),
      (doc) => {
        if (doc.exists()) {
          refetch();
        }
      },
    );
    return () => unsubscribe();
  }, [crossBundleSearchId, refetch]);

  return {
    crossBundleSearchDoc,
    isLoading: isLoadingCrossBundleSearchDoc || isPostingCrossBundleSearch,
    isError: isErrorCrossBundleSearchDoc || isErrorPostingCrossBundleSearch,
    dispatch,
  };
};
