import { Highlight } from '@qura/shared-types';

export const HighlightPreview = ({
  highlight,
  onClick,
}: {
  highlight: Highlight;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  return (
    <div
      className="border-4 border-gray-200 w-24 h-14 text-gray-300 bg-white flex items-center justify-center rounded-lg hover:bg-gray-100 cursor-pointer transition-colors duration-200 ease-in-out"
      onClick={onClick}>
      {highlight.highlight_format === 'pdf' && (
        <div className="text-xs flex flex-col items-center">
          <div>Page {highlight.position[0].page_index}</div>
          <div>Y: {highlight.position[0].bounding_boxes[0].y0.toFixed(2)}</div>
        </div>
      )}
    </div>
  );
};
