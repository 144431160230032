import { getDocs, collection, query, where, orderBy, limit, startAfter, QueryDocumentSnapshot, setDoc } from "firebase/firestore"
import { db } from '../firebaseConfig';

import { crossBundleSearchDocumentSchema } from "@qura/shared-types";
import { useInfiniteQuery } from "@tanstack/react-query";


export const getCrossBundleSearchHistory = async (userId: string, lastDoc: QueryDocumentSnapshot | null) => {
    const searchesRef = collection(db, 'cross_bundle_searches');

    let q = query(
        searchesRef,
        where('user_id', '==', userId),
        orderBy('created_at', 'desc'),
        limit(20),
    );

    if (lastDoc) {
        q = query(q, startAfter(lastDoc));
    }

    const searchesSnapshot = await getDocs(q);

    if (searchesSnapshot.empty) {
        return { searches: [], pageParam: null };
    }

    const lastVisible = searchesSnapshot.docs[searchesSnapshot.docs.length - 1];

    const searches = searchesSnapshot.docs.map((doc) => crossBundleSearchDocumentSchema.parse(doc.data()));

    return { searches, pageParam: lastVisible };
}

export const visitBundleInCrossBundleSearch = async (crossBundleSearchId: string, bundlePk: string) => {
    const searchRef = query(collection(db, 'cross_bundle_searches'), where('cross_bundle_search_id', '==', crossBundleSearchId));
    const searchSnapshot = await getDocs(searchRef);

    if (searchSnapshot.empty) {
        throw new Error(`Cross bundle search ${crossBundleSearchId} not found`);
    }

    const searchData = crossBundleSearchDocumentSchema.parse(searchSnapshot.docs[0].data());

    if (!searchData.visited_bundles.includes(bundlePk)) {
        searchData.visited_bundles.push(bundlePk);
        await setDoc(searchSnapshot.docs[0].ref, searchData);
    }
}


export const useCrossBundleSearchHistoryInfiniteScroll = (userId: string | null) => {
    return useInfiniteQuery({
        initialPageParam: null as QueryDocumentSnapshot | null,
        queryKey: ['cross_bundle_search_history', userId],
        queryFn: ({ pageParam }) => getCrossBundleSearchHistory(userId!, pageParam),
        getNextPageParam: (lastPage) => lastPage.pageParam,
        enabled: userId !== null
    });
};