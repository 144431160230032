import { useState, useEffect, useRef } from 'react';
import { Input } from '@qura/ui';
import { BundleFilePosition } from '../pages/BundleViewerPage/BundleViewerPage';

export const PageInputForm = ({
  pageNumber,
  totalPages,
  scrollToFilePosition,
}: {
  pageNumber: number;
  totalPages: number;
  scrollToFilePosition: (position: BundleFilePosition) => void;
}) => {
  const [inputValue, setInputValue] = useState(pageNumber.toString());
  const debounceTimeout = useRef<NodeJS.Timeout>();

  // Update input when pageNumber prop changes
  useEffect(() => {
    setInputValue(pageNumber.toString());
  }, [pageNumber]);

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const navigateToPage = (value: string) => {
    const newPage = parseInt(value, 10);

    if (isNaN(newPage)) {
      setInputValue(pageNumber.toString());
      return;
    }

    if (newPage < 1) {
      setInputValue('1');
    }

    if (newPage > totalPages) {
      setInputValue(totalPages.toString());
    }

    scrollToFilePosition({
      position_type: 'offset',
      page_index: newPage - 1,
      offset: 0,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow empty input while typing
    if (value === '') {
      setInputValue('');
      return;
    }

    // Only allow numbers
    if (!/^\d+$/.test(value)) {
      return;
    }

    setInputValue(value);

    // Debounce navigation while typing
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      navigateToPage(value);
    }, 500); // Reduced delay for better responsiveness
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    navigateToPage(inputValue);
  };

  return (
    <div className="flex w-24 p-2 rounded-md bg-q-gray-40">
      <form onSubmit={handleSubmit} className="flex items-center gap-1">
        <Input
          value={inputValue}
          onChange={handleChange}
          onBlur={handleSubmit}
          className="w-9 h-6 text-center text-sm px-0 bg-q-gray-30"
          type="text"
          pattern="\d*"
          aria-label="Page number"
        />
        <span className="text-sm text-gray-600">/ {totalPages}</span>
      </form>
    </div>
  );
};
