import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../services/react-query/queryClient';
import { tocNodeSchema } from '@qura/shared-types';
import { BUNDLE_COLLECTION } from '../../../constants';

export const getTocNodes = async (
  bundleId: string | undefined,
  documentId: string | undefined,
  fileId: string | undefined,
  parent_index: number | null,
) => {
  if (!bundleId || !documentId || !fileId) return null;

  const tocSnapshot = await getDocs(
    query(
      collection(db, BUNDLE_COLLECTION, bundleId, 'documents', documentId, 'files', fileId, 'toc'),
      where('parent_index', '==', parent_index),
      orderBy('node_index', 'asc'),
    ),
  );

  const toc = tocSnapshot.docs.map((doc) => doc.data());
  return tocNodeSchema.array().parse(toc);
};

export const useTocNodes = (
  bundleId: string | undefined,
  documentId: string | undefined,
  fileId: string | undefined,
  parent_index: number | null,
) => {
  return useQuery({
    queryKey: ['tocNodes', bundleId, documentId, fileId, parent_index],
    queryFn: () => getTocNodes(bundleId, documentId, fileId, parent_index),
    enabled: !!bundleId && !!documentId && !!fileId,
  });
};

export const fetchTocNodes = async (
  bundleId: string | undefined,
  documentId: string | undefined,
  fileId: string | undefined,
  parent_index: number | null,
) => {
  return queryClient.fetchQuery({
    queryKey: ['tocNodes', bundleId, documentId, fileId, parent_index],
    queryFn: () => getTocNodes(bundleId, documentId, fileId, parent_index),
  });
};

export const getTocNodeFromReferenceId = async (
  bundleId: string | undefined,
  documentId: string | undefined,
  fileId: string | undefined,
  referenceId: string | undefined,
) => {
  if (!bundleId || !documentId || !fileId || !referenceId) return null;

  const tocNodes = await getDocs(
    query(
      collection(db, BUNDLE_COLLECTION, bundleId, 'documents', documentId, 'files', fileId, 'toc'),
      where('file_position.reference_ids', 'array-contains', referenceId),
      orderBy('node_index', 'asc'),
      limit(1),
    ),
  );

  const nodes = tocNodes.docs.map((doc) => doc.data());
  if (nodes.length === 0) return null;

  return tocNodeSchema.parse(nodes[0]);
};

export const useTocNodeFromReferenceId = (
  bundleId: string | undefined,
  documentId: string | undefined,
  fileId: string | undefined,
  referenceId: string | undefined,
) => {
  return useQuery({
    queryKey: ['tocNodeFromReferenceId', bundleId, documentId, fileId, referenceId],
    queryFn: () => getTocNodeFromReferenceId(bundleId, documentId, fileId, referenceId),
  });
};

export const fetchTocNodeFromReferenceId = async (
  bundleId: string | undefined,
  documentId: string | undefined,
  fileId: string | undefined,
  referenceId: string | undefined,
) => {
  return queryClient.fetchQuery({
    queryKey: ['tocNodeFromReferenceId', bundleId, documentId, fileId, referenceId],
    queryFn: () => getTocNodeFromReferenceId(bundleId, documentId, fileId, referenceId),
  });
};
