import { collection, getDocs, query, limit, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useQuery } from '@tanstack/react-query';
import { searchFilterSchema } from '@qura/shared-types';
import { SEARCH_FILTER_COLLECTION } from '../../../constants';

export const getFilters = async (count: number) => {
  const filtersSnapshot = await getDocs(
    query(collection(db, SEARCH_FILTER_COLLECTION), where('active', '==', true), limit(count))
  );

  const filters = filtersSnapshot.docs.map((doc) => ({ ...doc.data(), filterId: doc.id }));
  return searchFilterSchema.array().parse(filters);
};

export const useFilters = (count: number) => {
  return useQuery({
    queryKey: ['filters', count],
    queryFn: () => getFilters(count),
  });
};
