import { useTranslations } from "@qura/translations";
import { Dialog, DialogContent, DialogTrigger, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@qura/ui";
import { CircleHelpIcon } from "lucide-react";
import { useState } from "react";

const mailto = 'team@qura.law';

export const OnboardingDialog = () => {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogContent className="max-w-[820px] p-0 border-none bg-transparent shadow-none">
        <div className="flex gap-7 flex-col mt-7 max-w-full">
          <div className="gradient-box flex relative flex-col border-solid rounded-large shadow-modal-light w-[820px] max-w-full justify-center bg-white px-12 overflow-clip rounded-2xl">
            <div className={`flex gap-7 relative flex-col items-stretch text-center py-[100px] max-w-[520px] self-center justify-center`}>
              <div className="flex justify-center">
                <p className="text-light py-1.5 px-3 text-xs rounded-full bg-qura-neutral-ghost">
                  {t.onboardingPage.welcomeMessage}
                </p>
              </div>
              <h1 className="font-inter font-normal text-[32px] text-qura-neutral-jet-medium">
                {t.onboardingPage.title}
              </h1>
              <p className="text-qura-neutral-balanced max-w-[430px] self-center">
                <span className="text-qura-neutral-jet">{t.onboardingPage.learnHowToUseQura}</span>{' '}
                {t.onboardingPage.description}
              </p>
              <div className="flex gap-4 justify-center mt-[32px]">
                <a href={t.onboardingPage.quickGuideUrl} target="_blank" rel="noopener noreferrer">
                  <button
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className={`px-7 group rounded-full p-[3px] bg-qura-neutral-jet shadow-auth-submit `}>
                    <p className="text-white py-[6px] ">{t.onboardingPage.viewQuickGuide}</p>
                  </button>
                </a>
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className={`px-7 group rounded-full p-[3px] bg-white shadow-auth-submit `}>
                  <p className="text-black rounded-full py-[6px]">{t.onboardingPage.showMeLater}</p>
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="font-inter text-xs font-medium flex items-center justify-center bg-black/50 px-4 py-2 rounded-full">
              <p className="pr-1 text-white">{t.onboardingPage.haveMoreQuestions}</p>
              <a className="text-white font-light underline" href={`mailto:${mailto}`}>
                {t.onboardingPage.contactTeam}
              </a>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogTrigger>
        <div className="group" >
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center justify-center w-8 h-8 group-hover:bg-q-white-10 rounded-md bg-q-black-50">
                  <CircleHelpIcon className='w-5 h-5 text-white group-hover:text-black' />
                </div>
              </TooltipTrigger>
              <TooltipContent side="right">
                Help
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </DialogTrigger>
    </Dialog>
  );
};
